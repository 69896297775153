import * as yup from "yup";
import { sum } from "lodash";
import { VariabileImportoPrevistoId } from "./hardCodedIds";

export const creaAttivitaValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome dell'attività è un campo obbligatorio")
    .required("Il nome dell'attività è un campo obbligatorio"),
  oreEffettive: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("stato", {
      is: "completato",
      then: (schema) =>
        schema.typeError("Campo obbligatorio").required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  idOperatore: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("stato", {
      is: "completato",
      then: (schema) =>
        schema.typeError("Campo obbligatorio").required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  dataEffettiva: yup.string().when("stato", {
    is: "completato",
    then: (schema) =>
      schema.typeError("Campo obbligatorio").required("Campo obbligatorio"),
    otherwise: (schema) => schema.nullable().optional(),
  }),

  dataScadenzaProgetto: yup.string().when(["dataScadenzaPerentoria"], {
    is: (d1) => d1,
    then: (schema) =>
      schema
        .nullable()
        .optional()
        .test(
          "before",
          "Inserisci un valore precedente alla data di scadenza perentoria",
          function (value) {
            const dataScadenzaPerentoria =
              this.options.parent.dataScadenzaPerentoria;
            return !value || value <= dataScadenzaPerentoria;
          }
        ),

    otherwise: (schema) => schema.nullable().optional(),
  }),

  documenti: yup
    .array()
    .ensure()
    .of(
      yup.object().shape({
        fileIdFile: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .nullable(true)
          .optional()
          .test("completato", "Carica il documento", function (val) {
            if (this.from[1].value.stato === "completato") {
              return !!val;
            } else {
              return true;
            }
          }),
        stato: yup
          .string()
          .test(
            "completato",
            "Il documento deve essere caricato",
            function (val) {
              if (this.from[1].value.stato === "completato") {
                return val === "caricato";
              } else {
                return true;
              }
            }
          ),
      })
    ),

  variabili: yup
    .array()
    .ensure()
    .of(
      yup.object().shape({
        valore: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .nullable(true)
          .optional()
          .test("completato", "Inserisci il valore", function (val) {
            if (this.from[1].value.stato === "completato") {
              return typeof val === "number";
            } else {
              return true;
            }
          }),
      })
    ),
});

export const editAttivitaValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome dell'attività è un campo obbligatorio")
    .required("Il nome dell'attività è un campo obbligatorio"),
  oreEffettive: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("stato", {
      is: "completato",
      then: (schema) =>
        schema.typeError("Campo obbligatorio").required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  idOperatore: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("stato", {
      is: "completato",
      then: (schema) =>
        schema.typeError("Campo obbligatorio").required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  dataEffettiva: yup
    .string()
    .test(
      "data-futura",
      "La data effettiva non può essere nel futuro",
      function (value, context) {
        return (
          !value ||
          require("moment")(value).isBefore(require("moment")().endOf("d"))
        );
      }
    )
    .when("stato", {
      is: "completato",
      then: (schema) =>
        schema.typeError("Campo obbligatorio").required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  dataScadenzaProgetto: yup.string().when(["dataScadenzaPerentoria"], {
    is: (d1) => d1,
    then: (schema) =>
      schema
        .nullable()
        .optional()
        .test(
          "before",
          "Inserisci un valore precedente alla data di scadenza perentoria",
          function (value) {
            const dataScadenzaPerentoria =
              this.options.parent.dataScadenzaPerentoria;
            return !value || value <= dataScadenzaPerentoria;
          }
        ),

    otherwise: (schema) => schema.nullable().optional(),
  }),

  documenti: yup
    .array()
    .ensure()
    .of(
      yup.object().shape({
        fileIdFile: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .nullable(true)
          .optional()
          .test("completato", "Carica il documento", function (val) {
            const documento = this.from[0].value;
            const attivita = this.from[1].value;
            const isFileCaricato = !!val;
            if (attivita.stato === "completato") {
              return documento.opzionale || isFileCaricato;
            } else {
              return true;
            }
          }),
        stato: yup
          .string()
          .test(
            "completato",
            "Il documento deve essere caricato",
            function (val) {
              const documento = this.from[0].value;
              const attivita = this.from[1].value;
              if (attivita.stato === "completato" && !documento.opzionale) {
                return val === "caricato";
              } else {
                return true;
              }
            }
          ),
      })
    ),

  variabili: yup
    .array()
    .ensure()
    .of(
      yup.object().shape({
        valore: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .nullable(true)
          .optional()
          .test("completato", "Inserisci il valore", function (val) {
            if (this.from[1].value.stato === "completato") {
              return typeof val === "number";
            } else {
              return true;
            }
          }),
      })
    ),
  pagamento: yup
    .mixed()
    .when(["dataEffettiva", "mlMilestonePagamento"], {
      is: (dataEffettiva, mlMilestonePagamento) =>
        dataEffettiva &&
        mlMilestonePagamento?.milestonePagamento?.hasMarcheDaBollo,
      then: () =>
        yup
          .object()
          .shape({
            numeroMarcheDaBollo: yup
              .number()
              .typeError("Campo obbligatorio")
              .required("Campo obbligatorio"),
            valoreMarcaDaBollo: yup
              .number()
              .typeError("Campo obbligatorio")
              .required("Campo obbligatorio"),
          })
          .nullable(),
    })
    .nullable(),
});

export const saveAttivitaDataPrevistaValidator = yup.object().shape({
  dataPrevista: yup
    .string()
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
});

export const createClienteValidator = yup.object().shape({
  ragioneSociale: yup
    .string()
    .typeError("Ragione sociale non valida")
    .required("Ragione sociale non valida"),
  tipoAzienda: yup
    .string()
    .typeError("Tipo azienda non valido")
    .required("Tipo azienda non valido"),
  partitaIva: yup
    .string()
    .typeError("Partita Iva non valida")
    .required("Partita Iva non valida")
    .matches(
      /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/,
      "La Partita IVA inserita non è valida"
    ),

  codiceUnivocoSDI: yup
    .string()
    .nullable()
    .matches(/^$|^.{7}$/, "Codice di 7 caratteri"),

  cf: yup
    .string()
    .typeError("Codice fiscale non valido")
    .required("Codice fiscale non valido")
    .matches(
      /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})|[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/,
      "Il codice fiscale inserito non è valido"
    ),
});

export const createContattoValidator = yup.object().shape({
  stato: yup
    .string()
    .typeError("Stato non valido")
    .required("Stato non valido"),
  titolo: yup
    .string()
    .typeError("Titolo non valido")
    .required("Titolo non valido"),
  nome: yup.string().typeError("Nome non valido").required("Nome non valido"),
  cognome: yup
    .string()
    .typeError("Cognome non valido")
    .required("Cognome non valido"),
});

export const createUtenteValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome è un campo obbligatorio")
    .required("Il nome è un campo obbligatorio"),
  cognome: yup
    .string()
    .typeError("Il cognome è un campo obbligatorio")
    .required("Il cognome è un campo obbligatorio"),
  email: yup
    .string()
    .typeError("L'indirizzo email è un campo obbligatorio")
    .email("Indirizzo email non valido")
    .required("L'indirizzo email è un campo obbligatorio"),
  password: yup
    .string()
    .typeError("La password è un campo obbligatorio")
    .min(8, "La password deve essere lunga almeno 8 caratteri")
    .required("La password è un campo obbligatorio")
    .matches(/[0-9]/, "La password deve contenere almeno un numero")
    .matches(/[A-Z]/, "La password deve contenere almeno una lettera maiuscola")
    .matches(
      /[a-z]/,
      "La password deve contenere almeno una lettera minuscola"
    ),
});

export const createAmbitoDiApplicazioneValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
  codiceSottoconto: yup
    .string()
    .nullable()
    .matches(/^$|^.{2}$/, "Codice di 2 cifre"),
});

export const editAmbitoDiApplicazioneValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
  codiceSottoconto: yup
    .string()
    .nullable()
    .matches(/^$|^.{2}$/, "Codice di 2 cifre"),
});

export const createEnteValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const editEnteValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const createNomeAgevolazioneValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const editNomeAgevolazioneValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const createNomeDocumentoValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const editNomeDocumentoValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const createNomeMilestoneValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
  codiceSottoconto: yup
    .string()
    .nullable()
    .matches(/^$|^.{2}$/, "Codice di 2 cifre"),
});

export const editNomeMilestoneValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
  codiceSottoconto: yup
    .string()
    .nullable()
    .matches(/^$|^.{2}$/, "Codice di 2 cifre"),
});

export const createNomeVariabileValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const editNomeVariabileValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const createRegimeValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const editRegimeValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const createTipologiaProceduraValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const editTipologiaProceduraValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const createTipoPagamentoValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const editTipoPagamentoValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Nome obbligatorio")
    .required("Nome obbligatorio"),
});

export const editClienteValidator = yup.object().shape({
  ragioneSociale: yup
    .string()
    .typeError("Ragione sociale non valida")
    .required("Ragione sociale non valida"),
  tipoAzienda: yup
    .string()
    .typeError("Tipo azienda non valido")
    .required("Tipo azienda non valido"),
  stato: yup
    .string()
    .typeError("Stato non valido")
    .required("Stato non valido"),
  partitaIva: yup
    .string()
    .typeError("Partita Iva non valida")
    .required("Partita Iva non valida")
    .matches(
      /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/,
      "La Partita IVA inserita non è valida"
    ),
  codiceUnivocoSDI: yup
    .string()
    .nullable()
    .matches(/^$|^.{7}$/, "Codice di 7 caratteri"),

  cf: yup
    .string()
    .typeError("Codice fiscale non valido")
    .required("Codice fiscale non valido")
    .matches(
      /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})|[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/,
      "Il codice fiscale inserito non è valido"
    ),

  referenti: yup
    .array()
    .ensure()
    .of(
      yup.object().shape({
        idContatto: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .typeError("Seleziona un contatto")
          .required("Seleziona un contatto"),
        ruolo: yup
          .string()
          .typeError("Inserisci un ruolo")
          .required("Inserisci un ruolo"),
        email: yup
          .string()
          .transform((value) => value || null)
          .nullable()
          .optional()
          .matches(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
            "Il valore inserito non è valido"
          ),

        telefono: yup
          .string()
          .transform((value) => value || null)
          .nullable()
          .optional()
          .matches(
            /^(\+|00?)?[1-9][0-9 \-\(\)\.]{7,32}$/,
            "Il valore inserito non è valido"
          ),

        cellulare: yup
          .string()
          .transform((value) => value || null)
          .nullable()
          .optional()
          .matches(
            /^(\+|00?)?[1-9][0-9 \-\(\)\.]{7,32}$/,
            "Il valore inserito non è valido"
          ),
      })
    ),

  cap: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .optional()
    .matches(/^[0-9]{5}$/, "Il CAP inserito non è valido"),
  capFatturazione: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .optional()
    .matches(/^[0-9]{5}$/, "Il CAP inserito non è valido"),
  capSedeOperativa: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .optional()
    .matches(/^[0-9]{5}$/, "Il CAP inserito non è valido"),
  telefono: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .optional()
    .matches(
      /^(\+|00?)?[1-9][0-9 \-\(\)\.]{7,32}$/,
      "Il valore inserito non è valido"
    ),

  telefono2: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .optional()
    .matches(
      /^(\+|00?)?[1-9][0-9 \-\(\)\.]{7,32}$/,
      "Il valore inserito non è valido"
    ),

  fax: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .optional()
    .matches(
      /^(\+|00?)?[1-9][0-9 \-\(\)\.]{7,32}$/,
      "Il valore inserito non è valido"
    ),

  email: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .optional()
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
      "Il valore inserito non è valido"
    ),

  pec: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .optional()
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
      "Il valore inserito non è valido"
    ),

  banche: yup
    .array()
    .ensure()
    .of(
      yup.object().shape({
        iban: yup
          .string()
          .transform((value) => value || null)
          .nullable()
          .optional()
          .matches(
            /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/,
            "Il valore inserito non è valido"
          ),
      })
    ),
});

export const editContattoValidator = yup.object().shape({
  stato: yup
    .string()
    .typeError("Stato non valido")
    .required("Stato non valido"),
  titolo: yup
    .string()
    .typeError("Titolo non valido")
    .required("Titolo non valido"),
  nome: yup.string().typeError("Nome non valido").required("Nome non valido"),
  cognome: yup
    .string()
    .typeError("Cognome non valido")
    .required("Cognome non valido"),
  contattiAziendali: yup
    .array()
    .ensure()
    .of(
      yup.object().shape({
        idAzienda: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .typeError("Seleziona un'azienda")
          .required("Seleziona un'azienda"),
        ruolo: yup
          .string()
          .typeError("Inserisci un ruolo")
          .required("Inserisci un ruolo"),
        email: yup
          .string()
          .transform((value) => value || null)
          .nullable()
          .optional()
          .matches(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
            "Il valore inserito non è valido"
          ),

        telefono: yup
          .string()
          .transform((value) => value || null)
          .nullable()
          .optional()
          .matches(
            /^(\+|00?)?[1-9][0-9 \-\(\)\.]{7,32}$/,
            "Il valore inserito non è valido"
          ),

        cellulare: yup
          .string()
          .transform((value) => value || null)
          .nullable()
          .optional()
          .matches(
            /^(\+|00?)?[1-9][0-9 \-\(\)\.]{7,32}$/,
            "Il valore inserito non è valido"
          ),
      })
    ),

  email: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .optional()
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
      "Il valore inserito non è valido"
    ),

  cellulare: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .optional()
    .matches(
      /^(\+|00?)?[1-9][0-9 \-\(\)\.]{7,32}$/,
      "Il valore inserito non è valido"
    ),

  telefono: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .optional()
    .matches(
      /^(\+|00?)?[1-9][0-9 \-\(\)\.]{7,32}$/,
      "Il valore inserito non è valido"
    ),
});

export const editMyUtenteValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome è un campo obbligatorio")
    .required("Il nome è un campo obbligatorio"),
  cognome: yup
    .string()
    .typeError("Il cognome è un campo obbligatorio")
    .required("Il cognome è un campo obbligatorio"),
  email: yup
    .string()
    .typeError("L'indirizzo email è un campo obbligatorio")
    .email("Indirizzo email non valido")
    .required("L'indirizzo email è un campo obbligatorio"),
  newPassword: yup
    .string()
    .min(8, "La password deve essere lunga almeno 8 caratteri")
    .matches(/[0-9]/, "La password deve contenere almeno un numero")
    .matches(/[A-Z]/, "La password deve contenere almeno una lettera maiuscola")
    .matches(
      /[a-z]/,
      "La password deve contenere almeno una lettera minuscola"
    ),

  confirmNewPassword: yup
    .string()
    .test(
      "passwords-match",
      "La password non corrisponde a quella inserita",
      function (value, context) {
        return context.parent.newPassword === value;
      }
    ),
});

export const editUtenteValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome è un campo obbligatorio")
    .required("Il nome è un campo obbligatorio"),
  cognome: yup
    .string()
    .typeError("Il cognome è un campo obbligatorio")
    .required("Il cognome è un campo obbligatorio"),
  email: yup
    .string()
    .typeError("L'indirizzo email è un campo obbligatorio")
    .email("Indirizzo email non valido")
    .required("L'indirizzo email è un campo obbligatorio"),
  password: yup
    .string()
    .min(8, "La password deve essere lunga almeno 8 caratteri"),
});

export const editFatturaValidator = yup.object().shape({});

export const createFormatAttivitaValidator = yup.object().shape({
  idFormatProcedura: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
  nome: yup
    .string()
    .typeError("Il nome dell'attività è un campo obbligatorio")
    .required("Il nome dell'attività è un campo obbligatorio"),
  orePreviste: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
  dataScadenzaPerentoriaDelta: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("dataScadenzaPerentoriaType", {
      is: "delta",
      then: (schema) => schema.required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  dsPerentoriaIdFormatAttivita: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("dataScadenzaPerentoriaType", {
      is: "delta",
      then: (schema) => schema.required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  dataScadenzaProgettoDelta: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("dataScadenzaProgettoType", {
      is: "delta",
      then: (schema) => schema.required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  dsProgettoIdFormatAttivita: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("dataScadenzaProgettoType", {
      is: "delta",
      then: (schema) => schema.required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  dataScadenzaProgetto: yup.string().when(
    [
      "dataScadenzaPerentoriaType",
      "dataScadenzaProgettoType",
      "dataScadenzaPerentoria",
    ],

    {
      is: (t1, t2, d1) =>
        (t1 || "simpleDate") === "simpleDate" &&
        (t2 || "simpleDate") === "simpleDate" &&
        d1,
      then: (schema) =>
        schema
          .nullable()
          .optional()
          .test(
            "before",
            "Inserisci un valore precedente alla data di scadenza perentoria",
            function (value) {
              const dataScadenzaPerentoria =
                this.options.parent.dataScadenzaPerentoria;
              return !value || value <= dataScadenzaPerentoria;
            }
          ),

      otherwise: (schema) => schema.nullable().optional(),
    }
  ),

  formatDocumenti: yup
    .array()
    .ensure()
    .of(
      yup.object().shape({
        nome: yup
          .string()
          .typeError("Campo obbligatorio")
          .required("Campo obbligatorio"),
        tipo: yup
          .string()
          .typeError("Campo obbligatorio")
          .required("Campo obbligatorio"),
      })
    ),

  formatVariabili: yup
    .array()
    .ensure()
    .of(
      yup.object().shape({
        idVariabile: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .typeError("Campo obbligatorio")
          .required("Campo obbligatorio"),
      })
    ),
});

export const editFormatAttivitaValidator = yup.object().shape({
  idFormatProcedura: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
  nome: yup
    .string()
    .typeError("Il nome dell'attività è un campo obbligatorio")
    .required("Il nome dell'attività è un campo obbligatorio"),
  orePreviste: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
  dataScadenzaPerentoriaDelta: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("dataScadenzaPerentoriaType", {
      is: "delta",
      then: (schema) => schema.required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  dsPerentoriaIdFormatAttivita: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("dataScadenzaPerentoriaType", {
      is: "delta",
      then: (schema) => schema.required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  dataScadenzaProgettoDelta: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("dataScadenzaProgettoType", {
      is: "delta",
      then: (schema) => schema.required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  dsProgettoIdFormatAttivita: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .when("dataScadenzaProgettoType", {
      is: "delta",
      then: (schema) => schema.required("Campo obbligatorio"),
      otherwise: (schema) => schema.nullable().optional(),
    }),

  dataScadenzaProgetto: yup.string().when(
    [
      "dataScadenzaPerentoriaType",
      "dataScadenzaProgettoType",
      "dataScadenzaPerentoria",
    ],

    {
      is: (t1, t2, d1) =>
        (t1 || "simpleDate") === "simpleDate" &&
        (t2 || "simpleDate") === "simpleDate" &&
        d1,
      then: (schema) =>
        schema
          .nullable()
          .optional()
          .test(
            "before",
            "Inserisci un valore precedente alla data di scadenza perentoria",
            function (value) {
              const dataScadenzaPerentoria =
                this.options.parent.dataScadenzaPerentoria;
              return !value || value <= dataScadenzaPerentoria;
            }
          ),

      otherwise: (schema) => schema.nullable().optional(),
    }
  ),

  formatDocumenti: yup
    .array()
    .ensure()
    .of(
      yup.object().shape({
        nome: yup
          .string()
          .typeError("Campo obbligatorio")
          .required("Campo obbligatorio"),
        tipo: yup
          .string()
          .typeError("Campo obbligatorio")
          .required("Campo obbligatorio"),
      })
    ),

  formatVariabili: yup
    .array()
    .ensure()
    .of(
      yup.object().shape({
        idVariabile: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .typeError("Campo obbligatorio")
          .required("Campo obbligatorio"),
      })
    ),
});

export const editFormatProceduraValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome del format procedura commesse è un campo obbligatorio")
    .required("Il nome del format procedura commesse è un campo obbligatorio"),
});

const formatStrumentoFSLValidator = yup.object().shape({
  idLinea: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Seleziona una linea")
    .required("Seleziona una linea"),

  minInvestimentoPrevisto: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .nullable(true)
    .optional()
    .min(0, "Valore non valido"),

  maxInvestimentoPrevisto: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .nullable(true)
    .optional()
    .when("minInvestimentoPrevisto", {
      is: (minInvestimentoPrevisto) =>
        typeof minInvestimentoPrevisto === "number",
      then: (schema) =>
        schema.min(
          yup.ref("minInvestimentoPrevisto"),
          "Inserisci un valore maggiore dell'importo minimo"
        ),
    }),

  pluriennaleMax: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .optional()
    .max(10, "Massimo 10 annu"),

  fslMilestones: yup
    .array()
    .ensure()
    .min(1, "Seleziona almeno una milestone")
    .of(
      yup.object().shape({
        idMilestonePagamento: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .typeError("Seleziona una linea")
          .required("Seleziona una linea"),
        tipoImporto: yup
          .string()
          .typeError("Campo obbligatorio")
          .required("Campo obbligatorio"),
        min: yup
          .mixed()
          .test("min number", "Campo obbligatorio", function (val) {
            if (
              this.from[0].value.tipoImporto === "valore" ||
              this.from[0].value.tipoImporto === "percentuale"
            ) {
              return typeof val === "number";
            } else {
              return true;
            }
          })
          .test("min >= 0", "Inserisci un valore positivo", function (val) {
            if (
              this.from[0].value.tipoImporto === "valore" ||
              this.from[0].value.tipoImporto === "percentuale"
            ) {
              return val >= 0;
            } else {
              return true;
            }
          }),

        listino: yup
          .mixed()
          .test("listino number", "Campo obbligatorio", function (val) {
            if (
              this.from[0].value.tipoImporto === "valore" ||
              this.from[0].value.tipoImporto === "percentuale"
            ) {
              return typeof val === "number";
            } else {
              return true;
            }
          })
          .test(
            "listino >= min",
            "Inserisci un valore maggiore dell'importo minimo",
            function (val) {
              if (
                this.from[0].value.tipoImporto === "valore" ||
                this.from[0].value.tipoImporto === "percentuale"
              ) {
                return val >= this.from[0].value.min;
              } else {
                return true;
              }
            }
          ),

        scaglioni: yup
          .array()
          .ensure()
          .of(
            yup.object().shape({
              finoA: yup
                .mixed()
                .test(
                  "percentuale-scaglioni finoA number",
                  "Campo obbligatorio",
                  function (val) {
                    if (
                      this.from[1].value.tipoImporto ===
                        "percentuale-scaglioni" &&
                      this.options.index !==
                        this.from[1].value.scaglioni.length - 1
                    ) {
                      return typeof val === "number";
                    } else {
                      return true;
                    }
                  }
                )
                .test(
                  "percentuale-scaglioni finoA >= 0",
                  "Inserisci un valore positivo",
                  function (val) {
                    if (
                      this.from[1].value.tipoImporto ===
                        "percentuale-scaglioni" &&
                      this.options.index !==
                        this.from[1].value.scaglioni.length - 1
                    ) {
                      return val >= 0;
                    } else {
                      return true;
                    }
                  }
                )
                .test(
                  "percentuale-scaglioni finoA > precedente",
                  "Inserisci un valore maggiore del precedente",
                  function (val) {
                    if (
                      this.from[1].value.tipoImporto ===
                        "percentuale-scaglioni" &&
                      this.options.index !==
                        this.from[1].value.scaglioni.length - 1 &&
                      this.options.index !== 0
                    ) {
                      const _min =
                        this.from[1].value.scaglioni[this.options.index - 1]
                          .finoA;
                      return val > _min;
                    } else {
                      return true;
                    }
                  }
                ),

              min: yup
                .mixed()
                .test(
                  "percentuale-scaglioni min number",
                  "Campo obbligatorio",
                  function (val) {
                    if (
                      this.from[1].value.tipoImporto === "percentuale-scaglioni"
                    ) {
                      return typeof val === "number";
                    } else {
                      return true;
                    }
                  }
                )
                .test(
                  "percentuale-scaglioni >= 0",
                  "Inserisci un valore positivo",
                  function (val) {
                    if (
                      this.from[1].value.tipoImporto === "percentuale-scaglioni"
                    ) {
                      return val >= 0;
                    } else {
                      return true;
                    }
                  }
                ),

              listino: yup
                .mixed()
                .test(
                  "percentuale-scaglioni listino number",
                  "Campo obbligatorio",
                  function (val) {
                    if (
                      this.from[1].value.tipoImporto === "percentuale-scaglioni"
                    ) {
                      return typeof val === "number";
                    } else {
                      return true;
                    }
                  }
                )
                .test(
                  "percentuale-scaglioni listino >= min",
                  "Inserisci un valore maggiore dell'importo minimo",
                  function (val) {
                    if (
                      this.from[1].value.tipoImporto === "percentuale-scaglioni"
                    ) {
                      return val >= this.from[0].value.min;
                    } else {
                      return true;
                    }
                  }
                ),
            })
          ),
      })
    ),
});

export const createFormatStrumentoValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome del format strumento è un campo obbligatorio")
    .required("Il nome del format strumento è un campo obbligatorio"),
  fsl: yup
    .array()
    .ensure()
    .min(1, "Seleziona almeno una linea")
    .of(formatStrumentoFSLValidator),
});

export const editFormatStrumentoValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome del format strumento è un campo obbligatorio")
    .required("Il nome del format strumento è un campo obbligatorio"),
  fsl: yup
    .array()
    .ensure()
    .min(1, "Seleziona almeno una linea")
    .of(formatStrumentoFSLValidator),
});

export const createEventoAgendaValidator = yup.object().shape({
  idUtente: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
});

export const editEventoAgendaValidator = yup.object().shape({
  idUtente: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
});

export const createInterazioniClienteValidator = yup.object().shape({
  idUtente: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
  idAzienda: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
});

export const editInterazioniClienteValidator = yup.object().shape({
  idUtente: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
  idAzienda: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
});

export const createInterazioniContattoValidator = yup.object().shape({
  idUtente: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
  idContatto: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
});

export const editInterazioniContattoValidator = yup.object().shape({
  idUtente: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
  idContatto: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
});

export const createLeggeValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome della legge è un campo obbligatorio")
    .required("Il nome della legge è un campo obbligatorio"),
  stato: yup
    .string()
    .typeError("Specificare lo stato della legge")
    .required("Specificare lo stato della legge"),
  anno: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare l'anno della legge")
    .required("Specificare l'anno della legge"),
  idEnte: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare l'ente")
    .required("Specificare l'ente"),
  idAmbitoDiApplicazione: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare l'ambito di applicazione")
    .required("Specificare l'ambito di applicazione"),
});

export const editLeggeValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome della legge è un campo obbligatorio")
    .required("Il nome della legge è un campo obbligatorio"),
  stato: yup
    .string()
    .typeError("Specificare lo stato della legge")
    .required("Specificare lo stato della legge"),
  anno: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare l'anno della legge")
    .required("Specificare l'anno della legge"),
  idEnte: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare l'ente")
    .required("Specificare l'ente"),
  idAmbitoDiApplicazione: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare l'ambito di applicazione")
    .required("Specificare l'ambito di applicazione"),
});

export const createLineaValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome della linea è un campo obbligatorio")
    .required("Il nome della linea è un campo obbligatorio"),
  stato: yup
    .string()
    .typeError("Specificare lo stato della linea")
    .required("Specificare lo stato della linea"),
  anno: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare l'anno della linea")
    .required("Specificare l'anno della linea"),
  idRegime: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare il regime")
    .required("Specificare il regime"),
  idTipologiaProcedura: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare la tipologia di procedura")
    .required("Specificare la tipologia di procedura"),
  agevolazioni: yup
    .array()
    .ensure()
    .min(1, "Seleziona almeno una agevolazione")
    .of(
      yup.object().shape({
        idNomeAgevolazione: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .typeError("Nome obbligatorio")
          .required("Nome obbligatorio"),
        variabili: yup
          .array()
          .ensure()
          .min(1, "Seleziona almeno un importo")
          .of(
            yup.object().shape({
              idNomeVariabile: yup
                .number()
                .transform((currentValue, originalValue) => {
                  return originalValue === "" || originalValue == null
                    ? undefined
                    : currentValue;
                })
                .typeError("Nome obbligatorio")
                .required("Nome obbligatorio"),
            })
          ),
      })
    ),

  milestonePagamenti: yup
    .array()
    .ensure()
    .min(1, "Seleziona almeno una milestone di pagamento")
    .of(
      yup.object().shape({
        idNomeMilestone: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .typeError("Nome obbligatorio")
          .required("Nome obbligatorio"),
        idVariabile: yup.mixed().when(["allaFirma"], {
          is: (allaFirma) => {
            return !allaFirma;
          },
          then: (schema) => {
            return schema.required("Seleziona agevolazione e variabile");
          },
          otherwise: (schema) => schema.nullable().optional(),
        }),
        hasMarcheDaBollo: yup.boolean().nullable(),
      })
    ),
});

export const editLineaValidator = yup.object().shape({
  nome: yup
    .string()
    .typeError("Il nome della linea è un campo obbligatorio")
    .required("Il nome della linea è un campo obbligatorio"),
  stato: yup
    .string()
    .typeError("Specificare lo stato della linea")
    .required("Specificare lo stato della linea"),
  anno: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare l'anno della linea")
    .required("Specificare l'anno della linea"),
  idRegime: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare il regime")
    .required("Specificare il regime"),
  idTipologiaProcedura: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare la tipologia di procedura")
    .required("Specificare la tipologia di procedura"),
  agevolazioni: yup
    .array()
    .ensure()
    .min(1, "Seleziona almeno una agevolazione")
    .of(
      yup.object().shape({
        idNomeAgevolazione: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .typeError("Nome obbligatorio")
          .required("Nome obbligatorio"),
        variabili: yup
          .array()
          .ensure()
          .min(1, "Seleziona almeno un importo")
          .of(
            yup.object().shape({
              idNomeVariabile: yup
                .number()
                .transform((currentValue, originalValue) => {
                  return originalValue === "" || originalValue == null
                    ? undefined
                    : currentValue;
                })
                .typeError("Nome obbligatorio")
                .required("Nome obbligatorio"),
            })
          ),
      })
    ),

  milestonePagamenti: yup
    .array()
    .ensure()
    .min(1, "Seleziona almeno una milestone di pagamento")
    .of(
      yup.object().shape({
        idNomeMilestone: yup
          .number()
          .transform((currentValue, originalValue) => {
            return originalValue === "" || originalValue == null
              ? undefined
              : currentValue;
          })
          .typeError("Nome obbligatorio")
          .required("Nome obbligatorio"),
        idVariabile: yup.mixed().when(["allaFirma"], {
          is: (allaFirma) => {
            return !allaFirma;
          },
          then: (schema) => {
            return schema.required("Seleziona agevolazione e variabile");
          },
          otherwise: (schema) => schema.nullable().optional(),
        }),
        hasMarcheDaBollo: yup.boolean().nullable(),
      })
    ),
});

export const estendiLineaValidator = yup.object().shape({
  anno: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specificare l'anno della linea")
    .required("Specificare l'anno della linea"),
});

export const loginValidator = yup.object().shape({});

export const createMandatoValidator = yup.object().shape({
  idFormatStrumento: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Scegli il format strumento")
    .required("Scegli il format strumento"),
  idClientePrincipale: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Specifica il cliente principale")
    .required("Specifica il cliente principale"),
  stato: yup
    .string()
    .typeError("Stato mandato non valido")
    .required("Stato mandato non valido"),
  idCommerciale: yup
    .string()
    .typeError("Seleziona un commerciale")
    .required("Seleziona un commerciale"),
  _mandatoLineaCreation: yup
    .mixed()
    .transform((currentValue, originalValue) => {
      return currentValue || {};
    })
    .when("formatStrumentoDb", function (formatStrumentoDb, schema) {
      const requiredShape = {};
      let atLeastOne = false;
      formatStrumentoDb.fsl.forEach((fsl) => {
        const linea = fsl.linea;
        if (linea.isPluriennale) {
          const maxPluriennale = fsl.pluriennaleMax || 10;
          atLeastOne = true;
          requiredShape[linea.id] = yup.object().shape({
            annoIniziale: yup
              .number()
              .min(linea.anno, "Il primo anno disponibile è: " + linea.anno)
              .typeError("Campo obbligatorio")
              .required("Campo obbligatorio"),
            numeroAnni: yup
              .number()
              .min(1, "Almeno un anno")
              .max(maxPluriennale, `Massimo ${maxPluriennale} anni`)
              .typeError("Campo obbligatorio")
              .required("Campo obbligatorio"),
          });
        }
      });

      if (atLeastOne) {
        return yup.object().shape(requiredShape);
      }

      return schema.nullable().optional();
    }),
});

export const editMandatoValidator = yup.object().shape({
  idFormatStrumento: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" || originalValue == null
        ? undefined
        : currentValue;
    })
    .typeError("Scegli il format strumento")
    .required("Scegli il format strumento"),
  nome: yup.string().typeError("Nome non valido").required("Nome non valido"),
  stato: yup
    .string()
    .typeError("Stato mandato non valido")
    .required("Stato mandato non valido"),
  idCommerciale: yup
    .string()
    .typeError("Seleziona un commerciale")
    .required("Seleziona un commerciale"),
  dataDiStipula: yup.string().when(["stato"], {
    is: (stato) => {
      return (
        stato === "firmata" ||
        stato === "completato" ||
        stato === "stand-by" ||
        stato === "esecutivo-non-firmato_CHANGED" ||
        stato === "firmata_CHANGED"
      );
    },
    then: (schema) => {
      return schema
        .nullable(true)
        .optional()
        .test(
          "data-futura",
          "La data di stipula non può essere nel futuro",
          function (value, context) {
            return (
              !value ||
              require("moment")(value).isBefore(require("moment")().endOf("d"))
            );
          }
        );
    },
    otherwise: (schema) => schema.nullable().optional(),
  }),

  mandatoClienti: yup
    .array()
    .when(["stato"], {
      is: (stato) => {
        return (
          stato === "esecutivo-non-firmato_CHANGED" ||
          stato === "firmata_CHANGED" ||
          stato === "da-autorizzare"
        );
      },
      then: (schema) => {
        return schema.ensure().of(
          yup.object().shape({
            idCliente: yup
              .number()
              .transform((currentValue, originalValue) => {
                return originalValue === "" || originalValue == null
                  ? undefined
                  : currentValue;
              })
              .typeError("Seleziona il cliente")
              .required("Seleziona il cliente"),
            idTipoPagamento: yup
              .number()
              .transform((currentValue, originalValue) => {
                return originalValue === "" || originalValue == null
                  ? undefined
                  : currentValue;
              })
              .typeError("Seleziona il tipo di pagamento")
              .required("Seleziona il tipo di pagamento"),
            idBanca: yup
              .number()
              .transform((currentValue, originalValue) => {
                return originalValue === "" || originalValue == null
                  ? undefined
                  : currentValue;
              })
              .typeError("Seleziona la banca")
              .required("Seleziona la banca"),
            percentuale: yup
              .number()
              .transform((currentValue, originalValue) => {
                return originalValue === "" || originalValue == null
                  ? undefined
                  : currentValue;
              })
              .typeError("Inserisci la percentuale")
              .min(0, "Valore non valido")
              .max(100, "Valore non valido")
              .required("Inserisci la percentuale"),
            mandatoClienteReferenti: yup
              .array()
              .ensure()
              .of(
                yup.object().shape({
                  idReferente: yup
                    .number()
                    .transform((currentValue, originalValue) => {
                      return originalValue === "" || originalValue == null
                        ? undefined
                        : currentValue;
                    })
                    .typeError("Seleziona il referente")
                    .required("Seleziona il referente"),
                  tipo: yup
                    .number()
                    .transform((currentValue, originalValue) => {
                      return originalValue === "" || originalValue == null
                        ? undefined
                        : currentValue;
                    })
                    .typeError("Seleziona il tipo di referente")
                    .required("Seleziona il tipo di referente"),
                })
              ),
          })
        );
      },
      otherwise: (schema) => schema.nullable().optional(),
    })
    .test(
      "unique-idCliente",
      "Lo stesso cliente non può essere selezionato più di una volta",
      (mandatoClienti) => {
        const idClienti = mandatoClienti
          .map((mandatoCliente) => mandatoCliente.idCliente)
          .filter((id) => !!id);
        const duplicated = new Set(idClienti).size < idClienti.length;
        return !duplicated;
      }
    )
    .test(
      "somma-percentuale",
      "La somma delle percentuali deve essere 100.",
      (mandatoClienti, { parent: { stato } }) => {
        if (
          stato !== "esecutivo-non-firmato_CHANGED" &&
          stato !== "firmata_CHANGED"
        ) {
          return true;
        }
        const percentualeSum = sum(
          mandatoClienti.map((mandatoCliente) => mandatoCliente.percentuale)
        );
        const closeEnoughToOneHundred = Math.abs(percentualeSum - 100) < 0.1;
        return closeEnoughToOneHundred;
      }
    ),

  mandatoLinee: yup
    .array()
    .when(["stato"], {
      is: (stato) => {
        return (
          stato === "esecutivo-non-firmato_CHANGED" ||
          stato === "firmata_CHANGED" ||
          stato === "da-autorizzare" ||
          stato === "esecutivo-non-firmato" ||
          stato === "firmata"
        );
      },
      then: (schema) => {
        return schema.ensure().of(
          yup.object().shape({
            mlMilestonesPagamenti: yup
              .array()
              .ensure()
              .of(
                yup.object().shape({
                  tipoImporto: yup
                    .string()
                    .typeError("Seleziona il tipo di importo")
                    .required("Seleziona il tipo di importo"),
                  valore: yup
                    .number()
                    .transform((currentValue, originalValue) => {
                      return originalValue === "" || originalValue == null
                        ? undefined
                        : currentValue;
                    })
                    .nullable(true)
                    .optional()
                    .when(["tipoImporto", "escludi"], {
                      is: (tipoImporto, escludi) =>
                        tipoImporto === "valore" && !escludi,
                      then: (schema) =>
                        schema
                          .required("Campo obbligatorio")
                          .test(
                            "valore >= min",
                            "Inserisci un valore maggiore dell'importo minimo",
                            function (val) {
                              const _minImportoApprovato =
                                this.from[0].value.minImportoApprovato;
                              const _min = this.from[0].value.min;
                              const min =
                                typeof _minImportoApprovato === "number"
                                  ? Math.min(_min, _minImportoApprovato)
                                  : _min;
                              return val >= min;
                            }
                          ),

                      otherwise: (schema) => schema.nullable().optional(),
                    }),

                  percentuale: yup
                    .number()
                    .transform((currentValue, originalValue) => {
                      return originalValue === "" || originalValue == null
                        ? undefined
                        : currentValue;
                    })
                    .when(["tipoImporto", "escludi"], {
                      is: (tipoImporto, escludi) =>
                        tipoImporto === "percentuale" && !escludi,
                      then: (schema) =>
                        schema
                          .required("Campo obbligatorio")
                          .test(
                            "percentuale >= min",
                            "Inserisci un valore maggiore dell'importo minimo",
                            function (val) {
                              const _minImportoApprovato =
                                this.from[0].value.minImportoApprovato;
                              const _min = this.from[0].value.min;
                              const min =
                                typeof _minImportoApprovato === "number"
                                  ? Math.min(_min, _minImportoApprovato)
                                  : _min;
                              return val >= min;
                            }
                          ),

                      otherwise: (schema) => schema.nullable().optional(),
                    }),

                  scaglioni: yup
                    .array()
                    .ensure()
                    .of(
                      yup.object().shape({
                        percentuale: yup
                          .mixed()
                          .test(
                            "percentuale-scaglioni number",
                            "Campo obbligatorio",
                            function (val) {
                              if (
                                this.from[1].value.tipoImporto ===
                                  "percentuale-scaglioni" &&
                                !this.from[1].value.escludi
                              ) {
                                return typeof val === "number";
                              } else {
                                return true;
                              }
                            }
                          )
                          .test(
                            "percentuale-scaglioni >= min",
                            "Inserisci un valore maggiore dell'importo minimo",
                            function (val) {
                              if (
                                this.from[1].value.tipoImporto ===
                                  "percentuale-scaglioni" &&
                                !this.from[1].value.escludi
                              ) {
                                const _minImportoApprovato =
                                  this.from[0].value.minImportoApprovato;
                                const _min = this.from[0].value.min;
                                const min =
                                  typeof _minImportoApprovato === "number"
                                    ? Math.min(_min, _minImportoApprovato)
                                    : _min;
                                return val >= min;
                              } else {
                                return true;
                              }
                            }
                          ),
                      })
                    ),
                })
              ),

            annoIniziale: yup.mixed().when(["linea", "isAnnualitaSuccessiva"], {
              is: (linea, isAnnualitaSuccessiva) =>
                linea.legge.isPluriennale && !isAnnualitaSuccessiva,
              then: yup
                .number()
                .typeError("Campo obbligatorio")
                .required("Campo obbligatorio"),
            }),

            numeroAnni: yup.mixed().when(["linea", "isAnnualitaSuccessiva"], {
              is: (linea, isAnnualitaSuccessiva) =>
                linea.legge.isPluriennale && !isAnnualitaSuccessiva,
              then: yup
                .number()
                .min(1, "Almeno un anno")
                .max(10, "Massimo 10 anni")
                .typeError("Campo obbligatorio")
                .required("Campo obbligatorio"),
            }),
          })
        );
      },
      otherwise: (schema) => schema.nullable().optional(),
    })
    .when(["stato"], {
      is: (stato) =>
        stato === "esecutivo-non-firmato_CHANGED" ||
        stato === "firmata_CHANGED" ||
        stato === "da-autorizzare" ||
        stato === "esecutivo-non-firmato" ||
        stato === "firmata" ||
        stato === "in-negoziazione" ||
        stato === "AUTORIZZATO",
      then: (schema) =>
        schema.ensure().of(
          yup.object().shape({
            variabili: yup
              .array()
              .ensure()
              .of(
                yup.object().shape({
                  valore: yup
                    .number()
                    .transform((currentValue, originalValue) => {
                      return originalValue === "" || originalValue == null
                        ? undefined
                        : currentValue;
                    })
                    .when(["$stato", "variabile"], {
                      is: (stato, { idNomeVariabile }) =>
                        idNomeVariabile === VariabileImportoPrevistoId ||
                        stato === "esecutivo-non-firmato_CHANGED" ||
                        stato === "firmata_CHANGED" ||
                        stato === "esecutivo-non-firmato" ||
                        stato === "firmata" ||
                        stato === "AUTORIZZATO",
                      then: (schema) =>
                        schema
                          .typeError("Campo obbligatorio")
                          .required("Campo obbligatorio"),
                      otherwise: (schema) => schema.optional(),
                    }),
                  valoreInvestimento: yup
                    .number()
                    .transform((currentValue, originalValue) => {
                      return originalValue === "" || originalValue == null
                        ? undefined
                        : currentValue;
                    })
                    .when(["$stato", "variabile"], {
                      is: (stato, { idNomeVariabile }) =>
                        idNomeVariabile === VariabileImportoPrevistoId ||
                        stato === "esecutivo-non-firmato_CHANGED" ||
                        stato === "firmata_CHANGED" ||
                        stato === "esecutivo-non-firmato" ||
                        stato === "firmata" ||
                        stato === "AUTORIZZATO",
                      then: (schema) =>
                        schema
                          .typeError("Campo obbligatorio")
                          .required("Campo obbligatorio")
                          .test(
                            "minMaxValoreInvestimento",
                            "Il valore investimento non rispetta i requisiti di min/max",
                            function (valoreInvestimento) {
                              const idNomeVariabile =
                                this.from[0].value.variabile.nome.id;
                              if (
                                idNomeVariabile !== VariabileImportoPrevistoId
                              ) {
                                return true;
                              }
                              const mandatoLinea = this.from[1].value;
                              const {
                                minInvestimentoPrevisto,
                                maxInvestimentoPrevisto,
                              } = mandatoLinea;
                              return (
                                (!minInvestimentoPrevisto ||
                                  valoreInvestimento >=
                                    minInvestimentoPrevisto) &&
                                (!maxInvestimentoPrevisto ||
                                  valoreInvestimento <= maxInvestimentoPrevisto)
                              );
                            }
                          )
                          .required("Campo obbligatorio"),
                      otherwise: (schema) => schema.optional(),
                    }),
                })
              ),
          })
        ),
      otherwise: (schema) => schema.nullable().optional(),
    }),
});

export const createNewsValidator = yup.object().shape({
  titolo: yup
    .string()
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
});

export const editNewsValidator = yup.object().shape({
  titolo: yup
    .string()
    .typeError("Campo obbligatorio")
    .required("Campo obbligatorio"),
});

export const editPagamentoValidator = yup.object().shape({});

import React, { useEffect, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { useHistory, useParams, Link } from "react-router-dom";
import { useUser } from "app/hooks/useUser";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getMandato as getMandatoAction,
  createMandato as createMandatoAction,
  editMandato as editMandatoAction,
  deleteMandato as deleteMandatoAction,
  getFormatStrumento as getFormatStrumentoAction,
  getTipiPagamento as getTipiPagamentoAction,
  duplicateMandato as duplicateMandatoAction,
} from "app/actions";
import {
  extractURLSearchValueAsNumber,
  useEffectOnSearchValue,
} from "app/utils/extractURLSearchValue";
import proceduraHasVersioneSuccessiva from "app/utils/proceduraHasVersioneSuccessiva";
import { some } from "lodash";
import { useDialog } from "app/hooks/useDialog";
import BackButton from "app/components/common/BackButton";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { ColoredButton } from "app/elements/ColoredButton";
import LaunchIcon from "@material-ui/icons/Launch";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import StrumentoPicker from "app/components/common/StrumentoPicker";
import { StatoPicker } from "app/components/StatoPicker";
import MandatoStato from "app/constants/MandatoStato";
import { AsyncSelect } from "app/elements/AsyncSelect";
import MandatoStatoMandato from "app/constants/MandatoStatoMandato";
import MandatoStatoProduzione from "app/constants/MandatoStatoProduzione";
import DateInput from "app/components/common/DateInput";
import moment from "moment";
import ClientePicker from "app/components/common/ClientePicker";
import UserPicker from "app/components/common/UserPicker";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileBox from "app/components/common/FileBox";
import FileBoxSmallReadonly from "app/components/common/FileBoxSmallReadonly";
import ClienteInterazioni from "app/components/Clienti/ClienteInterazioni";
import { DataTable } from "app/elements/DataTable";
import LineaPicker from "app/components/common/LineaPicker";
import { NumberFormatCustom } from "app/elements/NumberFormatCustom";
import { DatePicker } from "@material-ui/pickers";
import AddIcon from "@material-ui/icons/Add";
import ClienteBancaPicker from "app/components/common/ClienteBancaPicker";
import MandatoClienteTerminePagamento from "app/constants/MandatoClienteTerminePagamento";
import DizionarioSelect from "app/components/common/DizionarioSelect";
import MandatoClienteExpandedRow from "app/components/Mandato/MandatoClienteExpandedRow";
import MandatoLineaMilestonesPagamenti from "app/components/Mandato/MandatoLineaMilestonesPagamenti";
import AccordionListaBeni from "app/components/Mandato/AccordionListaBeni";
import MandatoLineaPrevisioneBenefici from "app/components/Mandato/MandatoLineaPrevisioneBenefici";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AccordionDocumenti from "../Attivita/AccordionDocumenti";
import useCreaconsultingTitle from "../../hooks/useCreaconsultingTitle";
import { Alert } from "@material-ui/lab";
import "./Mandato.scss";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSubmitDataSource } from "../../hooks/DataSource/useSubmitDataSource";

export default function Mandato(props) {
  const hasPermission = useCheckPermission();
  const showToast = useToast();
  const history = useHistory();
  const user = useUser();

  const { id } = props.useIdFromProps ? props : useParams();
  const isNew = id === "new";

  const [editing, setEditing] = useState(isNew);
  const readOnly = !editing;
  const variant = readOnly ? "standard" : "outlined";

  const mandato = useDataSource({
    loadAction: getMandatoAction,
    createAction: createMandatoAction,
    editAction: editMandatoAction,
    itemId: id,
    autoLoad: !isNew,

    initialData: {
      stato: "in-preparazione",
      statoMandato: "offerta",
      statoProduzione: "inAttesa",
      idCommerciale: user?.id,
      idClientePrincipale: extractURLSearchValueAsNumber("clienteId"),
      idFormatStrumento: extractURLSearchValueAsNumber("formatStrumentoId"),
    },
    onCreateSuccess: (response) => {
      setEditing(false);

      showToast("Mandato creato con successo", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.replace(`/mandati/${response.data.id}?edit=true`);
      setEditing(true);
    },
    onCreateError: () => {
      showToast("Errore durante la creazione del mandato", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    onEditSuccess: (response) => {
      setEditing(false);

      showToast("Mandato modificato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      props.onEditSuccess?.(response.data);
    },
    onEditError: (e) => {
      showToast(e?.message || "Errore durante la modifica del mandato", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    deleteAction: deleteMandatoAction,
    onDeleteSuccess: () => {
      showToast("Linea eliminata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/mandati");
    },
    onDeleteError: (response) => {
      showToast(
        response?.message
          ? `Errore: ${response.message}`
          : "Errore durante l'eliminazione del mandato",
        {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
        }
      );
    },
  });

  useCreaconsultingTitle(`Mandato ${mandato.getValue("nome", "")}`);

  const readOnlyClienteOrImporti =
    readOnly ||
    [
      "firmata",
      "esecutivo-non-firmato",
      "completato",
      "stand-by",
      "stand-by-non-firmata",

      // aggiunte in data 29/05/23
      "da-autorizzare",
      "AUTORIZZATO",
      "persa",
    ].indexOf(mandato.getValue("stato")) !== -1;

  console.log("mandato", mandato.data);

  const hasMigrateToNewVersion =
    (mandato?.data?.procedure || []).filter((p) => {
      return proceduraHasVersioneSuccessiva(p);
    }).length > 0;

  const isMultibene = some(mandato?.data?.mandatoLinee, (ml) => {
    return ml.linea?.isMultibene;
  });

  const isMulticliente = some(mandato?.data?.mandatoLinee, (ml) => {
    return ml.linea?.isMulticliente;
  });

  const mandatoLinee = useDataSource({
    parent: mandato,
    pathInParent: ["mandatoLinee"],
    initialData: [],
  });

  const mandatoClienti = useDataSource({
    parent: mandato,
    pathInParent: ["mandatoClienti"],
    initialData: [],
  });

  const formatStrumento = useDataSource({
    initialData: {},
    itemId: mandato.getValue("idFormatStrumento"),
    loadAction: getFormatStrumentoAction,
    autoLoad: mandato.getValue("idFormatStrumento"),
  });

  const documenti = useDataSource({
    parent: mandato,
    pathInParent: ["documentiFaseNegoziazione"],
    initialData: [],
  });

  const lineaBelongsToStrumento = (idLinea) => {
    return (
      formatStrumento.getValue("fsl", []).findIndex((fsl) => {
        return idLinea === fsl.idLinea;
      }) >= 0
    );
  };

  const deleteDialog = useDialog();

  useEffectOnSearchValue(
    "edit",
    () => hasPermission("modifica_offerta_mandato") && setEditing(true)
  );
  useEffect(() => {
    if (props.edit) {
      if (hasPermission("modifica_offerta_mandato")) {
        setEditing(true);
      }
    }
  }, [props.edit]);

  const statoMandato = mandato.data?.statoMandato;
  const duplicateMandato = useSubmitDataSource({
    submitAction: duplicateMandatoAction,
    onSubmitSuccess: (response) => {
      showToast(
        `${statoMandato === "offerta" ? "Offerta" : "Mandato"} duplicata`,
        {
          color: "success",
          horizontal: "left",
          vertical: "bottom",
        }
      );

      history.push("/mandati/" + response.data.id);
    },
    onSubmitError: () => {
      showToast(
        `Errore durante la duplicazione ${
          statoMandato === "offerta" ? "dell'offerta" : "del mandato"
        }`,
        {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
        }
      );
    },
  });

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_offerta_mandato"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <>
      <h2
        className="page-title-header page-title-header-sticky"
        style={{
          marginTop: -20,
          marginBottom: 0,
        }}
      >
        <BackButton defaultBackLink="" />
        Mandato
      </h2>

      <Container disableGutters={true} maxWidth="xl">
        {mandato.loading ? (
          "Caricamento mandato in corso ..."
        ) : mandato.loadError ? (
          "Errore durante il caricamento del mandato"
        ) : (
          <>
            <div
              className="page-title-header-sticky-buttons-row"
              style={{
                display: "flex",
                marginBottom: "1em",
              }}
            >
              <Typography
                style={{
                  flex: 1,
                }}
                component="h2"
                gutterBottom={true}
                variant="h6"
              >
                {mandato.getValue("nome", "Nuovo mandato")}
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: 8,
                }}
              >
                {hasMigrateToNewVersion ? (
                  <StarIcon
                    style={{
                      marginTop: 4,
                      marginRight: 10,
                      color: "#ffd600",
                    }}
                  />
                ) : null}{" "}
                {props.isSideModal && (
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    disabled={false}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<LaunchIcon />}
                    variant="outlined"
                    to={`/mandati/${id}?edit=true`}
                    component={Link}
                  >
                    Apri
                  </ColoredButton>
                )}
                {readOnly ? (
                  <>
                    {hasPermission("duplica_offerta_mandato") && (
                      <ColoredButton
                        style={{
                          marginLeft: 8,
                        }}
                        onClick={() => {
                          duplicateMandato.submit(mandato.getValue("id"));
                        }}
                        startIcon={<FileCopyIcon />}
                        variant="outlined"
                      >
                        Duplica
                      </ColoredButton>
                    )}

                    {hasPermission("modifica_offerta_mandato") && (
                      <ColoredButton
                        style={{
                          marginLeft: 8,
                        }}
                        onClick={() => {
                          setEditing(!editing);
                        }}
                        startIcon={<EditIcon />}
                        variant="outlined"
                      >
                        Modifica
                      </ColoredButton>
                    )}
                  </>
                ) : (
                  <>
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      disabled={false}
                      onClick={() => {
                        if (isNew) {
                          history.push("/mandati");
                        } else {
                          mandato.cancel();
                          setEditing(false);
                        }
                      }}
                      color="secondary"
                      endIcon={null}
                      fullWidth={false}
                      size="small"
                      startIcon={<CancelIcon />}
                      variant="outlined"
                    >
                      {[
                        isNew
                          ? "Torna alla lista mandati"
                          : "Annulla le modifiche",
                      ]}
                    </ColoredButton>

                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      disabled={false}
                      onClick={() => {
                        mandato.commit();
                      }}
                      color="secondary"
                      endIcon={null}
                      fullWidth={false}
                      size="small"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                    >
                      {isNew ? "Crea" : "Salva"}
                    </ColoredButton>
                  </>
                )}{" "}
                {!props.isSideModal &&
                  hasPermission("elimina_offerta_mandato") && (
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      onClick={() => {
                        deleteDialog.open();
                      }}
                      startIcon={<DeleteIcon />}
                      variant="outlined"
                    >
                      Elimina
                    </ColoredButton>
                  )}
              </div>
            </div>

            <Card style={{ marginBottom: 16 }}>
              <CardContent>
                <Grid container={true} item={false} spacing={2}>
                  <Grid container={false} item={true} md={6} xs={12}>
                    <TextField
                      autoComplete=""
                      disabled={mandato.loading || mandato.editing}
                      error={mandato.getValidationErrors("nome").length > 0}
                      fullWidth={true}
                      helperText={mandato.getValidationErrors("nome")[0]}
                      label="Nome"
                      onBlur={() => {
                        mandato.runValidation("nome");
                      }}
                      onChange={(event) => {
                        const value = event.target.value;

                        mandato.changeValue(
                          "nome",

                          value
                        );
                      }}
                      placeholder="Autogenerato"
                      size="small"
                      value={mandato.getValue("nome", "")}
                      variant={variant}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid container={false} item={true} md={6} xs={12}>
                    <StrumentoPicker
                      source={mandato}
                      sourceKey="idFormatStrumento"
                      readOnly={!isNew}
                      variant={variant}
                      filterStato={(str) => {
                        return (
                          str.stato === "in-vendita" ||
                          str.id === mandato.getValue("idFormatStrumento")
                        );
                      }}
                    />
                  </Grid>

                  <Grid
                    style={{
                      paddingTop: 10,
                    }}
                    container={false}
                    item={true}
                    lg={6}
                    md={8}
                    sm={12}
                  >
                    <StatoPicker
                      label="Stato"
                      source={mandato}
                      stati={MandatoStato}
                      variant={variant}
                      disabled={!readOnly || isNew}
                      isChangePrevented={(oldStato, newStato) => {
                        if (
                          oldStato === "da-approvare" &&
                          newStato === "APPROVATO"
                        ) {
                          if (!hasPermission("approva_mandato")) {
                            return "Non hai il permesso per approvare il mandato.";
                          }
                        }
                        if (
                          oldStato === "da-autorizzare" &&
                          newStato === "AUTORIZZATO"
                        ) {
                          if (!hasPermission("autorizza_mandato")) {
                            return "Non hai il permesso per autorizzare il mandato.";
                          }
                        }
                        return null;
                      }}
                    />
                  </Grid>

                  <Grid
                    style={{
                      display: "none",
                    }}
                    container={false}
                    item={true}
                    md={6}
                    xs={12}
                  >
                    <FormControl
                      disabled={readOnly}
                      error={
                        mandato.getValidationErrors("statoMandato").length > 0
                      }
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel
                        error={
                          mandato.getValidationErrors("statoMandato").length > 0
                        }
                        variant={variant}
                      >
                        Stato mandato
                      </InputLabel>
                      <AsyncSelect
                        label="Stato mandato"
                        onChange={(event) => {
                          const value = event.target.value;

                          mandato.changeValue(
                            "statoMandato",

                            value || null
                          );

                          setTimeout(
                            () => {
                              mandato.runValidation("statoMandato");
                            },

                            0
                          );
                        }}
                        onClose={() => {
                          mandato.runValidation("statoMandato");
                        }}
                        value={mandato.getValue("statoMandato") || ""}
                        variant={variant}
                        sourceVariable="mandato"
                        sourceKey="statoMandato"
                        disabled={readOnly}
                      >
                        {MandatoStatoMandato.map((option) => {
                          return (
                            <MenuItem
                              key={option.value}
                              dense={false}
                              disableGutters={false}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </AsyncSelect>
                      {mandato.getValidationErrors("statoMandato")[0] ? (
                        <FormHelperText>
                          {mandato.getValidationErrors("statoMandato")[0]}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>

                  <Grid
                    style={{
                      display: "none",
                    }}
                    container={false}
                    item={true}
                    md={4}
                    xs={12}
                  >
                    <FormControl
                      disabled={readOnly}
                      error={
                        mandato.getValidationErrors("statoProduzione").length >
                        0
                      }
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel
                        error={
                          mandato.getValidationErrors("statoProduzione")
                            .length > 0
                        }
                        variant={variant}
                      >
                        Stato produzione
                      </InputLabel>
                      <AsyncSelect
                        label="Stato produzione"
                        onChange={(event) => {
                          const value = event.target.value;

                          mandato.changeValue(
                            "statoProduzione",

                            value || null
                          );

                          setTimeout(
                            () => {
                              mandato.runValidation("statoProduzione");
                            },

                            0
                          );
                        }}
                        onClose={() => {
                          mandato.runValidation("statoProduzione");
                        }}
                        value={mandato.getValue("statoProduzione") || ""}
                        variant={variant}
                        sourceVariable="mandato"
                        sourceKey="statoProduzione"
                        disabled={readOnly}
                      >
                        {MandatoStatoProduzione.map((option) => {
                          return (
                            <MenuItem
                              key={option.value}
                              dense={false}
                              disableGutters={false}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </AsyncSelect>
                      {mandato.getValidationErrors("statoProduzione")[0] ? (
                        <FormHelperText>
                          {mandato.getValidationErrors("statoProduzione")[0]}
                        </FormHelperText>
                      ) : null}{" "}
                    </FormControl>
                  </Grid>

                  <Grid container={false} item={true} md={6} xs={12}>
                    <DateInput
                      className=""
                      label="Data di stipula"
                      inputVariant={variant}
                      fullWidth={true}
                      size="small"
                      variant={variant}
                      disabled={
                        readOnly || mandato.getValue("stato") !== "firmata"
                      }
                      source={mandato}
                      sourceKey="dataDiStipula"
                      maxDate={moment().endOf("day")}
                    />
                  </Grid>

                  <Grid container={false} item={true} md={6} xs={12}>
                    <ClientePicker
                      source={mandato}
                      sourceKey="idClientePrincipale"
                      label="Cliente principale"
                      variant={variant}
                      readOnly={readOnly || !isNew}
                    />
                  </Grid>

                  <Grid container={false} item={true} md={6} xs={12}>
                    <UserPicker
                      source={mandato}
                      sourceKey="idCommerciale"
                      label="Commerciale"
                      readOnly={readOnly}
                      variant={variant}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {isNew ? (
              <>
                <LineeCreationTableCard
                  formatStrumentoSource={formatStrumento}
                  mandatoSource={mandato}
                  readOnly={readOnly}
                />
              </>
            ) : null}

            {!isNew ? (
              <>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Contratto
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container={true} item={false} spacing={2}>
                      <Grid container={false} item={true} md={6} xs={12}>
                        <FileBox
                          source={mandato}
                          sourceKey="contratto"
                          readOnly={readOnly}
                          title="Contratto"
                        />

                        <FileBoxSmallReadonly
                          fileObj={mandato.data?.formatStrumento?.contratto}
                          style={{ marginTop: 10 }}
                          title="Template contratto"
                        />
                      </Grid>

                      <Grid container={false} item={true} md={6} xs={12}>
                        <FileBox
                          source={mandato}
                          sourceKey="simulazione"
                          readOnly={readOnly}
                          title="Simulazione"
                        />

                        <FileBoxSmallReadonly
                          fileObj={
                            mandato.data?.formatStrumento?.templateSimulazione
                          }
                          style={{ marginTop: 10 }}
                          title="Template simulazione"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Contatti/Interazioni
                  </AccordionSummary>

                  <AccordionDetails>
                    <ClienteInterazioni
                      clienteId={mandato.getValue("idClientePrincipale")}
                      idMandato={mandato.getValue("id")}
                    />
                  </AccordionDetails>
                </Accordion>

                <AccordionDocumenti
                  title="Documenti raccolti in fase di negoziazione"
                  documenti={documenti}
                  readOnly={readOnly}
                  canAddDocumenti
                  canRemoveDocumenti
                  tipoNuovoDocumento="documentoFaseNegoziazioneOfferta"
                />

                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Linee
                  </AccordionSummary>

                  <AccordionDetails>
                    <DataTable
                      size="small"
                      source={mandatoLinee}
                      filterRow={(_, row) => !row.isAnnualitaSuccessiva}
                      columns={[
                        {
                          path: ["id"],
                          header: "Nome linea",
                          disableColumnFilter: false,
                          disableGlobalFilter: false,
                          sortable: true,
                          type: "text",
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return lineaBelongsToStrumento(
                              rowSource.getValue("idLinea")
                            ) ? (
                              rowSource.getValue(["linea", "nome"])
                            ) : (
                              <LineaPicker
                                idLinea={rowSource.getValue("idLinea")}
                                onChange={(linea) => {
                                  rowSource.changeValue(
                                    "linea",

                                    linea
                                  );

                                  rowSource.changeValue(
                                    "idLinea",

                                    linea?.id
                                  );

                                  return null;
                                }}
                                readOnly={readOnly}
                                filterLinea={(linea) => {
                                  return !lineaBelongsToStrumento(linea.id);
                                }}
                              />
                            );
                          },
                        },
                        {
                          path: ["id"],
                          header: "Plueriennale",
                          disableColumnFilter: false,
                          disableGlobalFilter: false,
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return rowSource.getValue([
                              "linea",
                              "isPluriennale",
                            ])
                              ? "Sì"
                              : "No";
                          },
                          type: "text",
                        },
                        {
                          path: ["id"],
                          header: "Anno iniziale",
                          disableColumnFilter: false,
                          disableGlobalFilter: false,
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return rowSource.getValue([
                              "linea",
                              "isPluriennale",
                            ]) ? (
                              <TextField
                                disabled={
                                  true // sempre disabilitato perche non si può cambiare il primo anno, solo aggiungere/togliere
                                  // rowSource.loading || rowSource.editing
                                }
                                error={
                                  rowSource.getValidationErrors("annoIniziale")
                                    .length > 0
                                }
                                fullWidth={true}
                                helperText={
                                  rowSource.getValidationErrors(
                                    "annoIniziale"
                                  )[0]
                                }
                                onBlur={() => {
                                  rowSource.runValidation("annoIniziale");
                                }}
                                onChange={(event) => {
                                  const value = event.target.value;

                                  rowSource.changeValue(
                                    "annoIniziale",

                                    value
                                  );
                                }}
                                size="small"
                                value={rowSource.getValue("annoIniziale", "")}
                                variant={variant}
                                InputProps={{
                                  readOnly: true, // sempre disabilitato perche non si può cambiare il primo anno, solo aggiungere/togliere
                                  // readOnly,
                                  inputComponent: NumberFormatCustom,
                                }}
                                inputProps={{
                                  thousandSeparator: false,
                                }}
                              />
                            ) : null;
                          },
                          type: "text",
                        },
                        {
                          path: ["id"],
                          header: "# anni",
                          disableColumnFilter: false,
                          disableGlobalFilter: false,
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return rowSource.getValue([
                              "linea",
                              "isPluriennale",
                            ]) ? (
                              <TextField
                                disabled={
                                  rowSource.loading || rowSource.editing
                                }
                                error={
                                  rowSource.getValidationErrors("numeroAnni")
                                    .length > 0
                                }
                                fullWidth
                                helperText={
                                  rowSource.getValidationErrors("numeroAnni")[0]
                                }
                                onBlur={() => {
                                  rowSource.runValidation("numeroAnni");
                                }}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  rowSource.changeValue("numeroAnni", value);
                                }}
                                size="small"
                                value={rowSource.getValue("numeroAnni", "")}
                                variant={variant}
                                InputProps={{
                                  readOnly,
                                  inputComponent: NumberFormatCustom,
                                }}
                                inputProps={{
                                  thousandSeparator: false,
                                }}
                              />
                            ) : null;
                          },
                          type: "text",
                        },
                        {
                          path: ["id"],
                          header: "Data pagamento anni successivi",
                          disableColumnFilter: false,
                          disableGlobalFilter: false,
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return rowSource.getValue([
                              "linea",
                              "isPluriennale",
                            ]) ? (
                              <DatePicker
                                onChange={(value) => {
                                  rowSource.changeValue(
                                    ["dataPagamentoAnniSuccessivi"],

                                    value.toISOString()
                                  );
                                }}
                                value={rowSource.getValue(
                                  ["dataPagamentoAnniSuccessivi"],
                                  ""
                                )}
                                disabled={
                                  rowSource.loading || rowSource.editing
                                }
                                inputVariant={variant}
                                onClose={() => {
                                  rowSource.runValidation([
                                    "dataPagamentoAnniSuccessivi",
                                  ]);
                                }}
                                TextFieldComponent={(inheritProps) => {
                                  return (
                                    <TextField
                                      {...inheritProps}
                                      fullWidth={true}
                                      size="small"
                                    />
                                  );
                                }}
                              />
                            ) : null;
                          },
                          type: "text",
                        },
                      ]}
                      bottomToolbar={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "0.75em",
                          }}
                        >
                          {editing ? (
                            <ColoredButton
                              style={{
                                marginRight: 8,
                                __: "TODO: aggiunta linea",
                                display: "none",
                              }}
                              disabled={false}
                              onClick={() => {
                                mandatoLinee.create();
                              }}
                              color="secondary"
                              endIcon={null}
                              fullWidth={false}
                              size="small"
                              startIcon={<AddIcon />}
                              variant="outlined"
                            >
                              Aggiungi linea
                            </ColoredButton>
                          ) : null}{" "}
                        </div>
                      }
                      pageSize={1000000}
                      messages={{
                        noRows: "Nessuna linea",
                      }}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Lista clienti
                  </AccordionSummary>

                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      {mandatoClienti.getValidationErrors([])?.length > 0 && (
                        <Alert severity="warning">
                          {mandatoClienti.getValidationErrors([])[0]}
                        </Alert>
                      )}

                      <DataTable
                        source={mandatoClienti}
                        columns={[
                          {
                            path: ["id"],
                            header: "Cliente",
                            renderCell: (value, row, rowIndex, rowSource) => {
                              return (
                                <ClientePicker
                                  source={rowSource}
                                  sourceKey="idCliente"
                                  label="Cliente"
                                  variant={variant}
                                  readOnly={
                                    readOnlyClienteOrImporti ||
                                    !isMulticliente ||
                                    row.idCliente ===
                                      mandato.getValue("idClientePrincipale")
                                  }
                                />
                              );
                            },
                            disableColumnFilter: false,
                            disableGlobalFilter: false,
                            sortable: true,
                            type: "text",
                          },
                          {
                            path: ["percentuale"],
                            header: "Percentuale",
                            renderCell: (value, row, rowIndex, rowSource) => {
                              return (
                                <TextField
                                  disabled={
                                    rowSource.loading || rowSource.editing
                                  }
                                  error={
                                    rowSource.getValidationErrors("percentuale")
                                      .length > 0
                                  }
                                  fullWidth={true}
                                  helperText={
                                    rowSource.getValidationErrors(
                                      "percentuale"
                                    )[0]
                                  }
                                  onBlur={() => {
                                    rowSource.runValidation("percentuale");
                                  }}
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    rowSource.changeValue("percentuale", value);
                                  }}
                                  size="small"
                                  value={rowSource.getValue("percentuale", "")}
                                  variant={variant}
                                  InputProps={{
                                    readOnly: readOnly,
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  inputProps={{
                                    min: 0,
                                    max: 100,
                                  }}
                                />
                              );
                            },
                            disableColumnFilter: true,
                            disableGlobalFilter: true,
                            sortable: true,
                            type: "number",
                          },
                          {
                            path: ["id"],
                            header: "Conto bancario",
                            renderCell: (value, row, rowIndex, rowSource) => {
                              return (
                                <ClienteBancaPicker
                                  idCliente={rowSource.getValue("idCliente")}
                                  source={rowSource}
                                  sourceKey="idBanca"
                                  variant={variant}
                                  readOnly={readOnlyClienteOrImporti}
                                />
                              );
                            },
                            disableColumnFilter: false,
                            disableGlobalFilter: false,
                            sortable: true,
                            type: "number",
                          },
                          {
                            path: ["terminiPagamento"],
                            header: "Termini pagamento",
                            renderCell: (value, row, rowIndex, rowSource) => {
                              return (
                                <FormControl
                                  disabled={readOnlyClienteOrImporti}
                                  error={
                                    rowSource.getValidationErrors(
                                      "terminiPagamento"
                                    ).length > 0
                                  }
                                  fullWidth={true}
                                  size="small"
                                >
                                  <InputLabel
                                    error={
                                      rowSource.getValidationErrors(
                                        "terminiPagamento"
                                      ).length > 0
                                    }
                                    variant={variant}
                                  >
                                    {}
                                  </InputLabel>
                                  <AsyncSelect
                                    onChange={(event) => {
                                      const value = event.target.value;
                                      rowSource.changeValue(
                                        "terminiPagamento",
                                        value || null
                                      );

                                      setTimeout(() => {
                                        rowSource.runValidation(
                                          "terminiPagamento"
                                        );
                                      }, 0);
                                    }}
                                    onClose={() => {
                                      rowSource.runValidation(
                                        "terminiPagamento"
                                      );
                                    }}
                                    value={
                                      rowSource.getValue("terminiPagamento") ||
                                      ""
                                    }
                                    variant={variant}
                                    sourceVariable="rowSource"
                                    sourceKey="terminiPagamento"
                                    disabled={readOnlyClienteOrImporti}
                                  >
                                    <MenuItem value="">
                                      <em></em>
                                    </MenuItem>
                                    {MandatoClienteTerminePagamento.map(
                                      (option) => {
                                        return (
                                          <MenuItem
                                            key={option.value}
                                            dense={false}
                                            disableGutters={false}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </AsyncSelect>
                                  {rowSource.getValidationErrors(
                                    "terminiPagamento"
                                  )[0] ? (
                                    <FormHelperText>
                                      {
                                        rowSource.getValidationErrors(
                                          "terminiPagamento"
                                        )[0]
                                      }
                                    </FormHelperText>
                                  ) : null}{" "}
                                </FormControl>
                              );
                            },
                            disableColumnFilter: false,
                            disableGlobalFilter: false,
                            sortable: true,
                            type: "text",
                          },
                          {
                            path: ["tipoPagamento"],
                            header: "Tipo pagamento",
                            renderCell: (value, row, rowIndex, rowSource) => {
                              return (
                                <DizionarioSelect
                                  source={rowSource}
                                  sourceKey="idTipoPagamento"
                                  readOnly={readOnlyClienteOrImporti}
                                  loadDizionarioAction={getTipiPagamentoAction}
                                />
                              );
                            },
                            disableColumnFilter: false,
                            disableGlobalFilter: false,
                            sortable: true,
                            type: "text",
                          },
                          {
                            path: ["id"],
                            header: "Azioni",
                            width: 100,
                            renderCell: (value, row, rowIndex, rowSource) => {
                              return (
                                <div>
                                  <IconButton
                                    disabled={
                                      readOnlyClienteOrImporti ||
                                      !isMulticliente ||
                                      (!row.__isNew &&
                                        row.idCliente ===
                                          mandato.getValue(
                                            "idClientePrincipale"
                                          ))
                                    }
                                    onClick={() => {
                                      rowSource.delete();
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>

                                  <IconButton
                                    disabled={!rowSource.getValue(["cliente"])}
                                    to={`/azienda/${
                                      rowSource.getValue(["cliente"])?.id
                                    }`}
                                    component={Link}
                                  >
                                    <LaunchIcon />
                                  </IconButton>
                                </div>
                              );
                            },
                            type: "text",
                          },
                        ]}
                        bottomToolbar={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              margin: "0.75em",
                            }}
                          >
                            {editing && isMulticliente ? (
                              <ColoredButton
                                style={{
                                  marginRight: 8,
                                }}
                                disabled={false}
                                onClick={() => {
                                  mandatoClienti.create();
                                }}
                                color="secondary"
                                endIcon={null}
                                fullWidth={false}
                                size="small"
                                startIcon={<AddIcon />}
                                variant="outlined"
                              >
                                Aggiungi cliente
                              </ColoredButton>
                            ) : null}{" "}
                          </div>
                        }
                        showFilterRow={false}
                        pageSize={1000000}
                        size="small"
                        expandableRows={true}
                        expandRowsOnClick={true}
                        isRowExpandable={() => {
                          return true;
                        }}
                        renderExpandedRow={(rowIndex, row, rowSource) => {
                          return (
                            <MandatoClienteExpandedRow
                              mandatoCliente={rowSource}
                              readOnly={readOnly}
                            />
                          );
                        }}
                        rowsDefaultExpanded={false}
                        messages={{
                          noRows: "Nessun cliente",
                        }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Milestone pagamenti
                  </AccordionSummary>

                  <AccordionDetails>
                    <DataTable
                      source={mandatoLinee}
                      columns={[
                        {
                          path: ["linea", "nome"],
                          header: "Nome linea",
                          disableColumnFilter: false,
                          disableGlobalFilter: false,
                          sortable: true,
                          type: "text",
                        },
                      ]}
                      topToolbar={null}
                      bottomToolbar={[]}
                      showFilterRow={false}
                      pageSize={1000000}
                      size="small"
                      expandableRows={true}
                      expandRowsOnClick={true}
                      isRowExpandable={() => {
                        return true;
                      }}
                      renderExpandedRow={(rowIndex, row, rowSource) => {
                        return (
                          <div
                            style={{
                              padding: ".5em .5em 2em 5em",
                              backgroundColor: "lightgray",
                            }}
                            className="MandatoLineaMilestonesPagamenti-container"
                          >
                            <MandatoLineaMilestonesPagamenti
                              idMandatoLinea={
                                rowSource.getValue("id") /* id mandato linea */
                              }
                              formatStrumento={formatStrumento}
                              variant={variant}
                              readOnly={readOnlyClienteOrImporti}
                              mandatoLineaSource={rowSource}
                              mandatoLineeSource={mandatoLinee}
                            />
                          </div>
                        );
                      }}
                      rowsDefaultExpanded={true}
                      messages={{
                        noRows: "Nessuna linea",
                      }}
                    />
                  </AccordionDetails>
                </Accordion>

                {isMultibene ? (
                  <AccordionListaBeni mandato={mandato} readOnly={readOnly} />
                ) : null}

                <PrevisioneBeneficiAccordion
                  mandato={mandato}
                  mandatoLinee={mandatoLinee}
                  formatStrumento={formatStrumento}
                  readOnly={readOnly}
                />

                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Lista commesse
                  </AccordionSummary>

                  <AccordionDetails>
                    <DataTable
                      source={mandato}
                      sourceKey="procedure"
                      columns={[
                        {
                          path: ["id"],
                          sortable: false,
                          disableColumnFilter: true,
                          header: " ",
                          renderCell: (value, row) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1em",
                                }}
                              >
                                <IconButton
                                  to={`/procedure/${value}`}
                                  component={Link}
                                >
                                  <LaunchIcon />
                                </IconButton>
                                {proceduraHasVersioneSuccessiva(row) ? (
                                  <StarIcon
                                    style={{
                                      marginTop: 0,
                                      marginRight: 10,
                                      color: "#ffd600",
                                    }}
                                  />
                                ) : null}{" "}
                              </div>
                            );
                          },
                          type: "text",
                        },
                        {
                          path: ["nome"],
                          header: "Nome",
                          disableColumnFilter: true,
                          disableGlobalFilter: true,
                          sortable: true,
                          type: "text",
                        },
                      ]}
                      topToolbar={null}
                      bottomToolbar={null}
                      showFilterRow={false}
                      pageSize={1000000}
                      size="small"
                      expandableRows={false}
                      messages={{
                        noRows: "Nessuna commessa",
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </>
            ) : null}
          </>
        )}{" "}
      </Container>

      <Dialog maxWidth="md" open={deleteDialog.isOpen}>
        <DialogTitle>Conferma di eliminazione</DialogTitle>

        <DialogContent>
          <Typography>
            {'Sei sicuro di voler eliminare il mandato "' +
              mandato.getValue("nome") +
              '"?'}{" "}
          </Typography>
        </DialogContent>

        <DialogActions>
          <ColoredButton
            onClick={() => {
              deleteDialog.close();
            }}
            color="secondary"
            variant="outlined"
          >
            Annulla
          </ColoredButton>

          <ColoredButton
            onClick={() => {
              mandato.delete();
            }}
            color="primary"
            variant="outlined"
          >
            Elimina
          </ColoredButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

function PrevisioneBeneficiAccordion({
  mandato,
  mandatoLinee,
  formatStrumento,
  readOnly,
}) {
  const variant = readOnly ? "standard" : "outlined";
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Previsione benefici
      </AccordionSummary>

      <AccordionDetails>
        <DataTable
          source={mandatoLinee}
          columns={[
            {
              path: ["linea", "nome"],
              header: "Nome linea",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
            },
          ]}
          topToolbar={null}
          bottomToolbar={[]}
          showFilterRow={false}
          pageSize={1000000}
          size="small"
          expandableRows={true}
          expandRowsOnClick={true}
          isRowExpandable={() => {
            return true;
          }}
          renderExpandedRow={(rowIndex, row, rowSource) => {
            return (
              <div
                style={{
                  padding: ".5em .5em 2em 5em",
                  backgroundColor: "lightgray",
                }}
              >
                <MandatoLineaPrevisioneBenefici
                  idMandatoLinea={
                    rowSource.getValue("id") /* id mandato linea */
                  }
                  formatStrumento={formatStrumento}
                  variant={variant}
                  readOnly={readOnly}
                  mandatoLineaSource={rowSource}
                  procedure={mandato.getValue("procedure")}
                  statoMandato={mandato.getValue("statoMandato")}
                />
              </div>
            );
          }}
          rowsDefaultExpanded={false}
          messages={{
            noRows: "Nessuna linea",
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}

function LineeCreationTableCard({
  mandatoSource,
  formatStrumentoSource,
  readOnly,
}) {
  const linee =
    formatStrumentoSource.data?.fsl
      ?.map((fsl) => {
        return fsl.linea;
      })
      ?.filter((x) => x) || [];

  if (!linee.length) {
    return null;
  }

  const getValue = (linea, key, defaultValue = null) => {
    const _mandatoLineaCreation =
      mandatoSource.data._mandatoLineaCreation || {};
    return _mandatoLineaCreation[linea.id]?.[key] ?? defaultValue;
  };

  const setValue = (linea, key, value) => {
    if (readOnly || typeof mandatoSource.data?.id === "number") {
      return;
    }

    const _mandatoLineaCreation =
      mandatoSource.data._mandatoLineaCreation || {};

    const newMandatoLineaCreation = {
      ..._mandatoLineaCreation,
      [linea.id]: {
        ...(_mandatoLineaCreation[linea.id] || {}),
        [key]: value,
      },
    };
    mandatoSource.changeValue("_mandatoLineaCreation", newMandatoLineaCreation);
  };

  const getValidationError = (linea, key) => {
    const k = `_mandatoLineaCreation.${linea.id}.${key}`;
    return mandatoSource.getValidationErrors([k])?.[0] || undefined;
  };

  const runValidation = (linea, key) => {
    const k = `_mandatoLineaCreation.${linea.id}.${key}`;
    mandatoSource.runValidation(k);
  };

  return (
    <Card style={{ marginTop: 10 }}>
      <CardHeader title="Linee" />
      <CardContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome linea</TableCell>
                <TableCell>Plueriennale</TableCell>
                <TableCell>Anno iniziale</TableCell>
                <TableCell>Numero di anni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {linee.map((linea, index) => {
                return (
                  <TableRow key={index} style={{ height: 55 }}>
                    <TableCell>{linea.nome}</TableCell>
                    <TableCell>{linea.isPluriennale ? "Sì" : "No"}</TableCell>
                    <TableCell>
                      {linea.isPluriennale ? (
                        <TextField
                          disabled={readOnly}
                          fullWidth={true}
                          error={!!getValidationError(linea, "annoIniziale")}
                          helperText={getValidationError(linea, "annoIniziale")}
                          onBlur={() => {
                            runValidation(linea, "annoIniziale");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;
                            setValue(linea, "annoIniziale", value);
                          }}
                          size="small"
                          value={getValue(linea, "annoIniziale", "")}
                          variant={"outlined"}
                          InputProps={{
                            readOnly: readOnly,
                            inputComponent: NumberFormatCustom,
                          }}
                          inputProps={{
                            thousandSeparator: false,
                          }}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {linea.isPluriennale ? (
                        <TextField
                          disabled={readOnly}
                          fullWidth
                          error={!!getValidationError(linea, "numeroAnni")}
                          helperText={getValidationError(linea, "numeroAnni")}
                          onBlur={() => {
                            runValidation(linea, "numeroAnni");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;
                            setValue(linea, "numeroAnni", value);
                          }}
                          size="small"
                          value={getValue(linea, "numeroAnni", "")}
                          variant={"outlined"}
                          InputProps={{
                            readOnly,
                            inputComponent: NumberFormatCustom,
                          }}
                          inputProps={{
                            thousandSeparator: false,
                          }}
                        />
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

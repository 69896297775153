import { CALL_API } from "../middleware/api";
import * as Validators from "common/validators.js";

// utils for table with server side pagination
function createTableApi(func) {
  return (queryObj, abortController) => {
    if (!queryObj || !queryObj.query) {
      abortController = queryObj;
      queryObj = null;
    }
    const query = (queryObj && queryObj.query) || "";

    return (dispatch, getState) => {
      return func(query, abortController, dispatch, getState);
    };
  };
}

export const ACTIVATE_UTENTE_REQUEST = "ACTIVATE_UTENTE_REQUEST";
export const ACTIVATE_UTENTE_SUCCESS = "ACTIVATE_UTENTE_SUCCESS";
export const ACTIVATE_UTENTE_FAILURE = "ACTIVATE_UTENTE_FAILURE";

export const activateUtente =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          ACTIVATE_UTENTE_REQUEST,
          ACTIVATE_UTENTE_SUCCESS,
          ACTIVATE_UTENTE_FAILURE,
        ],

        endpoint: "/api/utenti/" + id + "/attiva",
        method: "POST",
        abortController: abortController,
      },
    });
  };

export const ATOKA_GET_DATA_REQUEST = "ATOKA_GET_DATA_REQUEST";
export const ATOKA_GET_DATA_SUCCESS = "ATOKA_GET_DATA_SUCCESS";
export const ATOKA_GET_DATA_FAILURE = "ATOKA_GET_DATA_FAILURE";

export const atokaGetData =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          ATOKA_GET_DATA_REQUEST,
          ATOKA_GET_DATA_SUCCESS,
          ATOKA_GET_DATA_FAILURE,
        ],

        endpoint: "/api/atokaGetData",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

export const ATOKA_SEARCH_REQUEST = "ATOKA_SEARCH_REQUEST";
export const ATOKA_SEARCH_SUCCESS = "ATOKA_SEARCH_SUCCESS";
export const ATOKA_SEARCH_FAILURE = "ATOKA_SEARCH_FAILURE";

export const atokaSearch =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          ATOKA_SEARCH_REQUEST,
          ATOKA_SEARCH_SUCCESS,
          ATOKA_SEARCH_FAILURE,
        ],

        endpoint: "/api/atokaSearch",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

export const CREA_ATTIVITA_REQUEST = "CREA_ATTIVITA_REQUEST";
export const CREA_ATTIVITA_SUCCESS = "CREA_ATTIVITA_SUCCESS";
export const CREA_ATTIVITA_FAILURE = "CREA_ATTIVITA_FAILURE";

export const creaAttivita =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREA_ATTIVITA_REQUEST,
          CREA_ATTIVITA_SUCCESS,
          CREA_ATTIVITA_FAILURE,
        ],

        endpoint: "/api/attivita",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

creaAttivita.validator = Validators.creaAttivitaValidator;

export const DELETE_ATTIVITA_REQUEST = "DELETE_ATTIVITA_REQUEST";
export const DELETE_ATTIVITA_SUCCESS = "DELETE_ATTIVITA_SUCCESS";
export const DELETE_ATTIVITA_FAILURE = "DELETE_ATTIVITA_FAILURE";

export const deleteAttivita =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_ATTIVITA_REQUEST,
          DELETE_ATTIVITA_SUCCESS,
          DELETE_ATTIVITA_FAILURE,
        ],

        endpoint: "/api/attivita/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_ATTIVITA_REQUEST = "EDIT_ATTIVITA_REQUEST";
export const EDIT_ATTIVITA_SUCCESS = "EDIT_ATTIVITA_SUCCESS";
export const EDIT_ATTIVITA_FAILURE = "EDIT_ATTIVITA_FAILURE";

export const editAttivita =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_ATTIVITA_REQUEST,
          EDIT_ATTIVITA_SUCCESS,
          EDIT_ATTIVITA_FAILURE,
        ],

        endpoint: "/api/attivita/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editAttivita.validator = Validators.editAttivitaValidator;

export const GET_ATTIVITA_REQUEST = "GET_ATTIVITA_REQUEST";
export const GET_ATTIVITA_SUCCESS = "GET_ATTIVITA_SUCCESS";
export const GET_ATTIVITA_FAILURE = "GET_ATTIVITA_FAILURE";

export const getAttivita =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_ATTIVITA_REQUEST,
          GET_ATTIVITA_SUCCESS,
          GET_ATTIVITA_FAILURE,
        ],

        endpoint: "/api/attivita/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_ATTIVITA_FOR_KANBAN_ALL_REQUEST =
  "GET_ATTIVITA_FOR_KANBAN_ALL_REQUEST";
export const GET_ATTIVITA_FOR_KANBAN_ALL_SUCCESS =
  "GET_ATTIVITA_FOR_KANBAN_ALL_SUCCESS";
export const GET_ATTIVITA_FOR_KANBAN_ALL_FAILURE =
  "GET_ATTIVITA_FOR_KANBAN_ALL_FAILURE";

export const getAttivitaForKanbanAll =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_ATTIVITA_FOR_KANBAN_ALL_REQUEST,
          GET_ATTIVITA_FOR_KANBAN_ALL_SUCCESS,
          GET_ATTIVITA_FOR_KANBAN_ALL_FAILURE,
        ],

        endpoint: "/api/attivita_for_kanban/all",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_ATTIVITA_FOR_KANBAN_ME_REQUEST =
  "GET_ATTIVITA_FOR_KANBAN_ME_REQUEST";
export const GET_ATTIVITA_FOR_KANBAN_ME_SUCCESS =
  "GET_ATTIVITA_FOR_KANBAN_ME_SUCCESS";
export const GET_ATTIVITA_FOR_KANBAN_ME_FAILURE =
  "GET_ATTIVITA_FOR_KANBAN_ME_FAILURE";

export const getAttivitaForKanbanMe =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_ATTIVITA_FOR_KANBAN_ME_REQUEST,
          GET_ATTIVITA_FOR_KANBAN_ME_SUCCESS,
          GET_ATTIVITA_FOR_KANBAN_ME_FAILURE,
        ],

        endpoint: "/api/attivita_for_kanban/me",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_ATTIVITA_FOR_KANBAN_USER_REQUEST =
  "GET_ATTIVITA_FOR_KANBAN_USER_REQUEST";
export const GET_ATTIVITA_FOR_KANBAN_USER_SUCCESS =
  "GET_ATTIVITA_FOR_KANBAN_USER_SUCCESS";
export const GET_ATTIVITA_FOR_KANBAN_USER_FAILURE =
  "GET_ATTIVITA_FOR_KANBAN_USER_FAILURE";

export const getAttivitaForKanbanUser =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_ATTIVITA_FOR_KANBAN_USER_REQUEST,
          GET_ATTIVITA_FOR_KANBAN_USER_SUCCESS,
          GET_ATTIVITA_FOR_KANBAN_USER_FAILURE,
        ],

        endpoint: "/api/attivita_for_kanban/user/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_ATTIVITA_PROCEDURA_REQUEST = "GET_ATTIVITA_PROCEDURA_REQUEST";
export const GET_ATTIVITA_PROCEDURA_SUCCESS = "GET_ATTIVITA_PROCEDURA_SUCCESS";
export const GET_ATTIVITA_PROCEDURA_FAILURE = "GET_ATTIVITA_PROCEDURA_FAILURE";

export const getAttivitaProcedura =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_ATTIVITA_PROCEDURA_REQUEST,
          GET_ATTIVITA_PROCEDURA_SUCCESS,
          GET_ATTIVITA_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/procedure/" + id + "/attivita",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_ATTIVITA_WITH_SCADENZA_REQUEST =
  "GET_ATTIVITA_WITH_SCADENZA_REQUEST";
export const GET_ATTIVITA_WITH_SCADENZA_SUCCESS =
  "GET_ATTIVITA_WITH_SCADENZA_SUCCESS";
export const GET_ATTIVITA_WITH_SCADENZA_FAILURE =
  "GET_ATTIVITA_WITH_SCADENZA_FAILURE";

export const getAttivitaWithScadenza =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_ATTIVITA_WITH_SCADENZA_REQUEST,
          GET_ATTIVITA_WITH_SCADENZA_SUCCESS,
          GET_ATTIVITA_WITH_SCADENZA_FAILURE,
        ],

        endpoint: "/api/attivita_scadenza",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_CLIENTI_ATTIVITA_REQUEST = "GET_CLIENTI_ATTIVITA_REQUEST";
export const GET_CLIENTI_ATTIVITA_SUCCESS = "GET_CLIENTI_ATTIVITA_SUCCESS";
export const GET_CLIENTI_ATTIVITA_FAILURE = "GET_CLIENTI_ATTIVITA_FAILURE";

export const getClientiAttivita =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_CLIENTI_ATTIVITA_REQUEST,
          GET_CLIENTI_ATTIVITA_SUCCESS,
          GET_CLIENTI_ATTIVITA_FAILURE,
        ],

        endpoint: "/api/attivita/" + id + "/clienti",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const SAVE_ATTIVITA_DATA_PREVISTA_REQUEST =
  "SAVE_ATTIVITA_DATA_PREVISTA_REQUEST";
export const SAVE_ATTIVITA_DATA_PREVISTA_SUCCESS =
  "SAVE_ATTIVITA_DATA_PREVISTA_SUCCESS";
export const SAVE_ATTIVITA_DATA_PREVISTA_FAILURE =
  "SAVE_ATTIVITA_DATA_PREVISTA_FAILURE";

export const saveAttivitaDataPrevista =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          SAVE_ATTIVITA_DATA_PREVISTA_REQUEST,
          SAVE_ATTIVITA_DATA_PREVISTA_SUCCESS,
          SAVE_ATTIVITA_DATA_PREVISTA_FAILURE,
        ],

        endpoint: "/api/attivita/" + id + "/data_prevista",
        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

saveAttivitaDataPrevista.validator =
  Validators.saveAttivitaDataPrevistaValidator;

export const GET_AVAILABILITY_UTENTI_REQUEST =
  "GET_AVAILABILITY_UTENTI_REQUEST";
export const GET_AVAILABILITY_UTENTI_SUCCESS =
  "GET_AVAILABILITY_UTENTI_SUCCESS";
export const GET_AVAILABILITY_UTENTI_FAILURE =
  "GET_AVAILABILITY_UTENTI_FAILURE";

export const getAvailabilityUtenti =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_AVAILABILITY_UTENTI_REQUEST,
          GET_AVAILABILITY_UTENTI_SUCCESS,
          GET_AVAILABILITY_UTENTI_FAILURE,
        ],

        endpoint: "/api/availability/utenti",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const SAVE_AVAILABILITY_UTENTE_REQUEST =
  "SAVE_AVAILABILITY_UTENTE_REQUEST";
export const SAVE_AVAILABILITY_UTENTE_SUCCESS =
  "SAVE_AVAILABILITY_UTENTE_SUCCESS";
export const SAVE_AVAILABILITY_UTENTE_FAILURE =
  "SAVE_AVAILABILITY_UTENTE_FAILURE";

export const saveAvailabilityUtente =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          SAVE_AVAILABILITY_UTENTE_REQUEST,
          SAVE_AVAILABILITY_UTENTE_SUCCESS,
          SAVE_AVAILABILITY_UTENTE_FAILURE,
        ],

        endpoint: "/api/availability/utenti/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const CREATE_CLIENTE_REQUEST = "CREATE_CLIENTE_REQUEST";
export const CREATE_CLIENTE_SUCCESS = "CREATE_CLIENTE_SUCCESS";
export const CREATE_CLIENTE_FAILURE = "CREATE_CLIENTE_FAILURE";

export const createCliente =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_CLIENTE_REQUEST,
          CREATE_CLIENTE_SUCCESS,
          CREATE_CLIENTE_FAILURE,
        ],

        endpoint: "/api/clienti",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createCliente.validator = Validators.createClienteValidator;

export const CREATE_CONTATTO_REQUEST = "CREATE_CONTATTO_REQUEST";
export const CREATE_CONTATTO_SUCCESS = "CREATE_CONTATTO_SUCCESS";
export const CREATE_CONTATTO_FAILURE = "CREATE_CONTATTO_FAILURE";

export const createContatto =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_CONTATTO_REQUEST,
          CREATE_CONTATTO_SUCCESS,
          CREATE_CONTATTO_FAILURE,
        ],

        endpoint: "/api/contatti",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createContatto.validator = Validators.createContattoValidator;

export const CREATE_UTENTE_REQUEST = "CREATE_UTENTE_REQUEST";
export const CREATE_UTENTE_SUCCESS = "CREATE_UTENTE_SUCCESS";
export const CREATE_UTENTE_FAILURE = "CREATE_UTENTE_FAILURE";

export const createUtente =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_UTENTE_REQUEST,
          CREATE_UTENTE_SUCCESS,
          CREATE_UTENTE_FAILURE,
        ],

        endpoint: "/api/utenti",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createUtente.validator = Validators.createUtenteValidator;

export const DELETE_CLIENTE_REQUEST = "DELETE_CLIENTE_REQUEST";
export const DELETE_CLIENTE_SUCCESS = "DELETE_CLIENTE_SUCCESS";
export const DELETE_CLIENTE_FAILURE = "DELETE_CLIENTE_FAILURE";

export const deleteCliente =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_CLIENTE_REQUEST,
          DELETE_CLIENTE_SUCCESS,
          DELETE_CLIENTE_FAILURE,
        ],

        endpoint: "/api/clienti/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const DELETE_CONTATTO_REQUEST = "DELETE_CONTATTO_REQUEST";
export const DELETE_CONTATTO_SUCCESS = "DELETE_CONTATTO_SUCCESS";
export const DELETE_CONTATTO_FAILURE = "DELETE_CONTATTO_FAILURE";

export const deleteContatto =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_CONTATTO_REQUEST,
          DELETE_CONTATTO_SUCCESS,
          DELETE_CONTATTO_FAILURE,
        ],

        endpoint: "/api/contatti/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const DELETE_UTENTE_REQUEST = "DELETE_UTENTE_REQUEST";
export const DELETE_UTENTE_SUCCESS = "DELETE_UTENTE_SUCCESS";
export const DELETE_UTENTE_FAILURE = "DELETE_UTENTE_FAILURE";

export const deleteUtente =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_UTENTE_REQUEST,
          DELETE_UTENTE_SUCCESS,
          DELETE_UTENTE_FAILURE,
        ],

        endpoint: "/api/utenti/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const CREATE_AMBITO_DI_APPLICAZIONE_REQUEST =
  "CREATE_AMBITO_DI_APPLICAZIONE_REQUEST";
export const CREATE_AMBITO_DI_APPLICAZIONE_SUCCESS =
  "CREATE_AMBITO_DI_APPLICAZIONE_SUCCESS";
export const CREATE_AMBITO_DI_APPLICAZIONE_FAILURE =
  "CREATE_AMBITO_DI_APPLICAZIONE_FAILURE";

export const createAmbitoDiApplicazione =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_AMBITO_DI_APPLICAZIONE_REQUEST,
          CREATE_AMBITO_DI_APPLICAZIONE_SUCCESS,
          CREATE_AMBITO_DI_APPLICAZIONE_FAILURE,
        ],

        endpoint: "/api/ambitiDiApplicazione",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createAmbitoDiApplicazione.validator =
  Validators.createAmbitoDiApplicazioneValidator;

export const DELETE_AMBITO_DI_APPLICAZIONE_REQUEST =
  "DELETE_AMBITO_DI_APPLICAZIONE_REQUEST";
export const DELETE_AMBITO_DI_APPLICAZIONE_SUCCESS =
  "DELETE_AMBITO_DI_APPLICAZIONE_SUCCESS";
export const DELETE_AMBITO_DI_APPLICAZIONE_FAILURE =
  "DELETE_AMBITO_DI_APPLICAZIONE_FAILURE";

export const deleteAmbitoDiApplicazione =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_AMBITO_DI_APPLICAZIONE_REQUEST,
          DELETE_AMBITO_DI_APPLICAZIONE_SUCCESS,
          DELETE_AMBITO_DI_APPLICAZIONE_FAILURE,
        ],

        endpoint: "/api/ambitiDiApplicazione/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_AMBITO_DI_APPLICAZIONE_REQUEST =
  "EDIT_AMBITO_DI_APPLICAZIONE_REQUEST";
export const EDIT_AMBITO_DI_APPLICAZIONE_SUCCESS =
  "EDIT_AMBITO_DI_APPLICAZIONE_SUCCESS";
export const EDIT_AMBITO_DI_APPLICAZIONE_FAILURE =
  "EDIT_AMBITO_DI_APPLICAZIONE_FAILURE";

export const editAmbitoDiApplicazione =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_AMBITO_DI_APPLICAZIONE_REQUEST,
          EDIT_AMBITO_DI_APPLICAZIONE_SUCCESS,
          EDIT_AMBITO_DI_APPLICAZIONE_FAILURE,
        ],

        endpoint: "/api/ambitiDiApplicazione/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editAmbitoDiApplicazione.validator =
  Validators.editAmbitoDiApplicazioneValidator;

export const GET_AMBITI_DI_APPLICAZIONE_REQUEST =
  "GET_AMBITI_DI_APPLICAZIONE_REQUEST";
export const GET_AMBITI_DI_APPLICAZIONE_SUCCESS =
  "GET_AMBITI_DI_APPLICAZIONE_SUCCESS";
export const GET_AMBITI_DI_APPLICAZIONE_FAILURE =
  "GET_AMBITI_DI_APPLICAZIONE_FAILURE";

export const getAmbitiDiApplicazione =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_AMBITI_DI_APPLICAZIONE_REQUEST,
          GET_AMBITI_DI_APPLICAZIONE_SUCCESS,
          GET_AMBITI_DI_APPLICAZIONE_FAILURE,
        ],

        endpoint: "/api/ambitiDiApplicazione",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_ENTE_REQUEST = "CREATE_ENTE_REQUEST";
export const CREATE_ENTE_SUCCESS = "CREATE_ENTE_SUCCESS";
export const CREATE_ENTE_FAILURE = "CREATE_ENTE_FAILURE";

export const createEnte =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [CREATE_ENTE_REQUEST, CREATE_ENTE_SUCCESS, CREATE_ENTE_FAILURE],
        endpoint: "/api/enti",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createEnte.validator = Validators.createEnteValidator;

export const DELETE_ENTE_REQUEST = "DELETE_ENTE_REQUEST";
export const DELETE_ENTE_SUCCESS = "DELETE_ENTE_SUCCESS";
export const DELETE_ENTE_FAILURE = "DELETE_ENTE_FAILURE";

export const deleteEnte =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [DELETE_ENTE_REQUEST, DELETE_ENTE_SUCCESS, DELETE_ENTE_FAILURE],
        endpoint: "/api/enti/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_ENTE_REQUEST = "EDIT_ENTE_REQUEST";
export const EDIT_ENTE_SUCCESS = "EDIT_ENTE_SUCCESS";
export const EDIT_ENTE_FAILURE = "EDIT_ENTE_FAILURE";

export const editEnte =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [EDIT_ENTE_REQUEST, EDIT_ENTE_SUCCESS, EDIT_ENTE_FAILURE],
        endpoint: "/api/enti/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editEnte.validator = Validators.editEnteValidator;

export const GET_ENTI_REQUEST = "GET_ENTI_REQUEST";
export const GET_ENTI_SUCCESS = "GET_ENTI_SUCCESS";
export const GET_ENTI_FAILURE = "GET_ENTI_FAILURE";

export const getEnti =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_ENTI_REQUEST, GET_ENTI_SUCCESS, GET_ENTI_FAILURE],
        endpoint: "/api/enti",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_NOME_AGEVOLAZIONE_REQUEST =
  "CREATE_NOME_AGEVOLAZIONE_REQUEST";
export const CREATE_NOME_AGEVOLAZIONE_SUCCESS =
  "CREATE_NOME_AGEVOLAZIONE_SUCCESS";
export const CREATE_NOME_AGEVOLAZIONE_FAILURE =
  "CREATE_NOME_AGEVOLAZIONE_FAILURE";

export const createNomeAgevolazione =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_NOME_AGEVOLAZIONE_REQUEST,
          CREATE_NOME_AGEVOLAZIONE_SUCCESS,
          CREATE_NOME_AGEVOLAZIONE_FAILURE,
        ],

        endpoint: "/api/nomiAgevolazione",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createNomeAgevolazione.validator = Validators.createNomeAgevolazioneValidator;

export const DELETE_NOME_AGEVOLAZIONE_REQUEST =
  "DELETE_NOME_AGEVOLAZIONE_REQUEST";
export const DELETE_NOME_AGEVOLAZIONE_SUCCESS =
  "DELETE_NOME_AGEVOLAZIONE_SUCCESS";
export const DELETE_NOME_AGEVOLAZIONE_FAILURE =
  "DELETE_NOME_AGEVOLAZIONE_FAILURE";

export const deleteNomeAgevolazione =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_NOME_AGEVOLAZIONE_REQUEST,
          DELETE_NOME_AGEVOLAZIONE_SUCCESS,
          DELETE_NOME_AGEVOLAZIONE_FAILURE,
        ],

        endpoint: "/api/nomiAgevolazione/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_NOME_AGEVOLAZIONE_REQUEST = "EDIT_NOME_AGEVOLAZIONE_REQUEST";
export const EDIT_NOME_AGEVOLAZIONE_SUCCESS = "EDIT_NOME_AGEVOLAZIONE_SUCCESS";
export const EDIT_NOME_AGEVOLAZIONE_FAILURE = "EDIT_NOME_AGEVOLAZIONE_FAILURE";

export const editNomeAgevolazione =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_NOME_AGEVOLAZIONE_REQUEST,
          EDIT_NOME_AGEVOLAZIONE_SUCCESS,
          EDIT_NOME_AGEVOLAZIONE_FAILURE,
        ],

        endpoint: "/api/nomiAgevolazioni/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editNomeAgevolazione.validator = Validators.editNomeAgevolazioneValidator;

export const GET_NOMI_AGEVOLAZIONE_REQUEST = "GET_NOMI_AGEVOLAZIONE_REQUEST";
export const GET_NOMI_AGEVOLAZIONE_SUCCESS = "GET_NOMI_AGEVOLAZIONE_SUCCESS";
export const GET_NOMI_AGEVOLAZIONE_FAILURE = "GET_NOMI_AGEVOLAZIONE_FAILURE";

export const getNomiAgevolazione =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_NOMI_AGEVOLAZIONE_REQUEST,
          GET_NOMI_AGEVOLAZIONE_SUCCESS,
          GET_NOMI_AGEVOLAZIONE_FAILURE,
        ],

        endpoint: "/api/nomiAgevolazione",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_NOME_DOCUMENTO_REQUEST = "CREATE_NOME_DOCUMENTO_REQUEST";
export const CREATE_NOME_DOCUMENTO_SUCCESS = "CREATE_NOME_DOCUMENTO_SUCCESS";
export const CREATE_NOME_DOCUMENTO_FAILURE = "CREATE_NOME_DOCUMENTO_FAILURE";

export const createNomeDocumento =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_NOME_DOCUMENTO_REQUEST,
          CREATE_NOME_DOCUMENTO_SUCCESS,
          CREATE_NOME_DOCUMENTO_FAILURE,
        ],

        endpoint: "/api/nomiDocumento",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createNomeDocumento.validator = Validators.createNomeDocumentoValidator;

export const DELETE_NOME_DOCUMENTO_REQUEST = "DELETE_NOME_DOCUMENTO_REQUEST";
export const DELETE_NOME_DOCUMENTO_SUCCESS = "DELETE_NOME_DOCUMENTO_SUCCESS";
export const DELETE_NOME_DOCUMENTO_FAILURE = "DELETE_NOME_DOCUMENTO_FAILURE";

export const deleteNomeDocumento =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_NOME_DOCUMENTO_REQUEST,
          DELETE_NOME_DOCUMENTO_SUCCESS,
          DELETE_NOME_DOCUMENTO_FAILURE,
        ],

        endpoint: "/api/nomiDocumento/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_NOME_DOCUMENTO_REQUEST = "EDIT_NOME_DOCUMENTO_REQUEST";
export const EDIT_NOME_DOCUMENTO_SUCCESS = "EDIT_NOME_DOCUMENTO_SUCCESS";
export const EDIT_NOME_DOCUMENTO_FAILURE = "EDIT_NOME_DOCUMENTO_FAILURE";

export const editNomeDocumento =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_NOME_DOCUMENTO_REQUEST,
          EDIT_NOME_DOCUMENTO_SUCCESS,
          EDIT_NOME_DOCUMENTO_FAILURE,
        ],

        endpoint: "/api/nomiDocumento/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editNomeDocumento.validator = Validators.editNomeDocumentoValidator;

export const GET_NOMI_DOCUMENTO_REQUEST = "GET_NOMI_DOCUMENTO_REQUEST";
export const GET_NOMI_DOCUMENTO_SUCCESS = "GET_NOMI_DOCUMENTO_SUCCESS";
export const GET_NOMI_DOCUMENTO_FAILURE = "GET_NOMI_DOCUMENTO_FAILURE";

export const getNomiDocumento =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_NOMI_DOCUMENTO_REQUEST,
          GET_NOMI_DOCUMENTO_SUCCESS,
          GET_NOMI_DOCUMENTO_FAILURE,
        ],

        endpoint: "/api/nomiDocumento",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_NOME_MILESTONE_REQUEST = "CREATE_NOME_MILESTONE_REQUEST";
export const CREATE_NOME_MILESTONE_SUCCESS = "CREATE_NOME_MILESTONE_SUCCESS";
export const CREATE_NOME_MILESTONE_FAILURE = "CREATE_NOME_MILESTONE_FAILURE";

export const createNomeMilestone =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_NOME_MILESTONE_REQUEST,
          CREATE_NOME_MILESTONE_SUCCESS,
          CREATE_NOME_MILESTONE_FAILURE,
        ],

        endpoint: "/api/nomiMilestone",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createNomeMilestone.validator = Validators.createNomeMilestoneValidator;

export const DELETE_NOME_MILESTONE_REQUEST = "DELETE_NOME_MILESTONE_REQUEST";
export const DELETE_NOME_MILESTONE_SUCCESS = "DELETE_NOME_MILESTONE_SUCCESS";
export const DELETE_NOME_MILESTONE_FAILURE = "DELETE_NOME_MILESTONE_FAILURE";

export const deleteNomeMilestone =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_NOME_MILESTONE_REQUEST,
          DELETE_NOME_MILESTONE_SUCCESS,
          DELETE_NOME_MILESTONE_FAILURE,
        ],

        endpoint: "/api/nomiMilestone/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_NOME_MILESTONE_REQUEST = "EDIT_NOME_MILESTONE_REQUEST";
export const EDIT_NOME_MILESTONE_SUCCESS = "EDIT_NOME_MILESTONE_SUCCESS";
export const EDIT_NOME_MILESTONE_FAILURE = "EDIT_NOME_MILESTONE_FAILURE";

export const editNomeMilestone =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_NOME_MILESTONE_REQUEST,
          EDIT_NOME_MILESTONE_SUCCESS,
          EDIT_NOME_MILESTONE_FAILURE,
        ],

        endpoint: "/api/nomiMilestone/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editNomeMilestone.validator = Validators.editNomeMilestoneValidator;

export const GET_NOMI_MILESTONE_REQUEST = "GET_NOMI_MILESTONE_REQUEST";
export const GET_NOMI_MILESTONE_SUCCESS = "GET_NOMI_MILESTONE_SUCCESS";
export const GET_NOMI_MILESTONE_FAILURE = "GET_NOMI_MILESTONE_FAILURE";

export const getNomiMilestone =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_NOMI_MILESTONE_REQUEST,
          GET_NOMI_MILESTONE_SUCCESS,
          GET_NOMI_MILESTONE_FAILURE,
        ],

        endpoint: "/api/nomiMilestone",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_NOME_VARIABILE_REQUEST = "CREATE_NOME_VARIABILE_REQUEST";
export const CREATE_NOME_VARIABILE_SUCCESS = "CREATE_NOME_VARIABILE_SUCCESS";
export const CREATE_NOME_VARIABILE_FAILURE = "CREATE_NOME_VARIABILE_FAILURE";

export const createNomeVariabile =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_NOME_VARIABILE_REQUEST,
          CREATE_NOME_VARIABILE_SUCCESS,
          CREATE_NOME_VARIABILE_FAILURE,
        ],

        endpoint: "/api/nomiVariabile",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createNomeVariabile.validator = Validators.createNomeVariabileValidator;

export const DELETE_NOME_VARIABILE_REQUEST = "DELETE_NOME_VARIABILE_REQUEST";
export const DELETE_NOME_VARIABILE_SUCCESS = "DELETE_NOME_VARIABILE_SUCCESS";
export const DELETE_NOME_VARIABILE_FAILURE = "DELETE_NOME_VARIABILE_FAILURE";

export const deleteNomeVariabile =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_NOME_VARIABILE_REQUEST,
          DELETE_NOME_VARIABILE_SUCCESS,
          DELETE_NOME_VARIABILE_FAILURE,
        ],

        endpoint: "/api/nomeVariabili/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_NOME_VARIABILE_REQUEST = "EDIT_NOME_VARIABILE_REQUEST";
export const EDIT_NOME_VARIABILE_SUCCESS = "EDIT_NOME_VARIABILE_SUCCESS";
export const EDIT_NOME_VARIABILE_FAILURE = "EDIT_NOME_VARIABILE_FAILURE";

export const editNomeVariabile =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_NOME_VARIABILE_REQUEST,
          EDIT_NOME_VARIABILE_SUCCESS,
          EDIT_NOME_VARIABILE_FAILURE,
        ],

        endpoint: "/api/nomiVariabile/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editNomeVariabile.validator = Validators.editNomeVariabileValidator;

export const GET_NOMI_VARIABILE_REQUEST = "GET_NOMI_VARIABILE_REQUEST";
export const GET_NOMI_VARIABILE_SUCCESS = "GET_NOMI_VARIABILE_SUCCESS";
export const GET_NOMI_VARIABILE_FAILURE = "GET_NOMI_VARIABILE_FAILURE";

export const getNomiVariabile =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_NOMI_VARIABILE_REQUEST,
          GET_NOMI_VARIABILE_SUCCESS,
          GET_NOMI_VARIABILE_FAILURE,
        ],

        endpoint: "/api/nomiVariabile",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_REGIME_REQUEST = "CREATE_REGIME_REQUEST";
export const CREATE_REGIME_SUCCESS = "CREATE_REGIME_SUCCESS";
export const CREATE_REGIME_FAILURE = "CREATE_REGIME_FAILURE";

export const createRegime =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_REGIME_REQUEST,
          CREATE_REGIME_SUCCESS,
          CREATE_REGIME_FAILURE,
        ],

        endpoint: "/api/regimi",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createRegime.validator = Validators.createRegimeValidator;

export const DELETE_REGIME_REQUEST = "DELETE_REGIME_REQUEST";
export const DELETE_REGIME_SUCCESS = "DELETE_REGIME_SUCCESS";
export const DELETE_REGIME_FAILURE = "DELETE_REGIME_FAILURE";

export const deleteRegime =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_REGIME_REQUEST,
          DELETE_REGIME_SUCCESS,
          DELETE_REGIME_FAILURE,
        ],

        endpoint: "/api/regimi/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_REGIME_REQUEST = "EDIT_REGIME_REQUEST";
export const EDIT_REGIME_SUCCESS = "EDIT_REGIME_SUCCESS";
export const EDIT_REGIME_FAILURE = "EDIT_REGIME_FAILURE";

export const editRegime =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [EDIT_REGIME_REQUEST, EDIT_REGIME_SUCCESS, EDIT_REGIME_FAILURE],
        endpoint: "/api/regimi/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editRegime.validator = Validators.editRegimeValidator;

export const GET_REGIMI_REQUEST = "GET_REGIMI_REQUEST";
export const GET_REGIMI_SUCCESS = "GET_REGIMI_SUCCESS";
export const GET_REGIMI_FAILURE = "GET_REGIMI_FAILURE";

export const getRegimi =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_REGIMI_REQUEST, GET_REGIMI_SUCCESS, GET_REGIMI_FAILURE],
        endpoint: "/api/regimi",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_TIPOLOGIA_PROCEDURA_REQUEST =
  "CREATE_TIPOLOGIA_PROCEDURA_REQUEST";
export const CREATE_TIPOLOGIA_PROCEDURA_SUCCESS =
  "CREATE_TIPOLOGIA_PROCEDURA_SUCCESS";
export const CREATE_TIPOLOGIA_PROCEDURA_FAILURE =
  "CREATE_TIPOLOGIA_PROCEDURA_FAILURE";

export const createTipologiaProcedura =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_TIPOLOGIA_PROCEDURA_REQUEST,
          CREATE_TIPOLOGIA_PROCEDURA_SUCCESS,
          CREATE_TIPOLOGIA_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/tipologieProcedura",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createTipologiaProcedura.validator =
  Validators.createTipologiaProceduraValidator;

export const DELETE_TIPOLOGIA_PROCEDURA_REQUEST =
  "DELETE_TIPOLOGIA_PROCEDURA_REQUEST";
export const DELETE_TIPOLOGIA_PROCEDURA_SUCCESS =
  "DELETE_TIPOLOGIA_PROCEDURA_SUCCESS";
export const DELETE_TIPOLOGIA_PROCEDURA_FAILURE =
  "DELETE_TIPOLOGIA_PROCEDURA_FAILURE";

export const deleteTipologiaProcedura =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_TIPOLOGIA_PROCEDURA_REQUEST,
          DELETE_TIPOLOGIA_PROCEDURA_SUCCESS,
          DELETE_TIPOLOGIA_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/tipologieProcedura/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_TIPOLOGIA_PROCEDURA_REQUEST =
  "EDIT_TIPOLOGIA_PROCEDURA_REQUEST";
export const EDIT_TIPOLOGIA_PROCEDURA_SUCCESS =
  "EDIT_TIPOLOGIA_PROCEDURA_SUCCESS";
export const EDIT_TIPOLOGIA_PROCEDURA_FAILURE =
  "EDIT_TIPOLOGIA_PROCEDURA_FAILURE";

export const editTipologiaProcedura =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_TIPOLOGIA_PROCEDURA_REQUEST,
          EDIT_TIPOLOGIA_PROCEDURA_SUCCESS,
          EDIT_TIPOLOGIA_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/tipologieProcedura/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editTipologiaProcedura.validator = Validators.editTipologiaProceduraValidator;

export const GET_TIPOLOGIE_PROCEDURA_REQUEST =
  "GET_TIPOLOGIE_PROCEDURA_REQUEST";
export const GET_TIPOLOGIE_PROCEDURA_SUCCESS =
  "GET_TIPOLOGIE_PROCEDURA_SUCCESS";
export const GET_TIPOLOGIE_PROCEDURA_FAILURE =
  "GET_TIPOLOGIE_PROCEDURA_FAILURE";

export const getTipologieProcedura =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_TIPOLOGIE_PROCEDURA_REQUEST,
          GET_TIPOLOGIE_PROCEDURA_SUCCESS,
          GET_TIPOLOGIE_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/tipologieProcedura",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_TIPO_PAGAMENTO_REQUEST = "CREATE_TIPO_PAGAMENTO_REQUEST";
export const CREATE_TIPO_PAGAMENTO_SUCCESS = "CREATE_TIPO_PAGAMENTO_SUCCESS";
export const CREATE_TIPO_PAGAMENTO_FAILURE = "CREATE_TIPO_PAGAMENTO_FAILURE";

export const createTipoPagamento =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_TIPO_PAGAMENTO_REQUEST,
          CREATE_TIPO_PAGAMENTO_SUCCESS,
          CREATE_TIPO_PAGAMENTO_FAILURE,
        ],

        endpoint: "/api/tipoPagamento",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createTipoPagamento.validator = Validators.createTipoPagamentoValidator;

export const DELETE_TIPO_PAGAMENTO_REQUEST = "DELETE_TIPO_PAGAMENTO_REQUEST";
export const DELETE_TIPO_PAGAMENTO_SUCCESS = "DELETE_TIPO_PAGAMENTO_SUCCESS";
export const DELETE_TIPO_PAGAMENTO_FAILURE = "DELETE_TIPO_PAGAMENTO_FAILURE";

export const deleteTipoPagamento =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_TIPO_PAGAMENTO_REQUEST,
          DELETE_TIPO_PAGAMENTO_SUCCESS,
          DELETE_TIPO_PAGAMENTO_FAILURE,
        ],

        endpoint: "/api/tipoPagamento/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_TIPO_PAGAMENTO_REQUEST = "EDIT_TIPO_PAGAMENTO_REQUEST";
export const EDIT_TIPO_PAGAMENTO_SUCCESS = "EDIT_TIPO_PAGAMENTO_SUCCESS";
export const EDIT_TIPO_PAGAMENTO_FAILURE = "EDIT_TIPO_PAGAMENTO_FAILURE";

export const editTipoPagamento =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_TIPO_PAGAMENTO_REQUEST,
          EDIT_TIPO_PAGAMENTO_SUCCESS,
          EDIT_TIPO_PAGAMENTO_FAILURE,
        ],

        endpoint: "/api/tipoPagamento/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editTipoPagamento.validator = Validators.editTipoPagamentoValidator;

export const GET_TIPI_PAGAMENTO_REQUEST = "GET_TIPI_PAGAMENTO_REQUEST";
export const GET_TIPI_PAGAMENTO_SUCCESS = "GET_TIPI_PAGAMENTO_SUCCESS";
export const GET_TIPI_PAGAMENTO_FAILURE = "GET_TIPI_PAGAMENTO_FAILURE";

export const getTipiPagamento =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_TIPI_PAGAMENTO_REQUEST,
          GET_TIPI_PAGAMENTO_SUCCESS,
          GET_TIPI_PAGAMENTO_FAILURE,
        ],

        endpoint: "/api/tipiPagamento",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const API_FILE_JS_PLACEHOLDER_FAKE_345_EEFF_357_E_74_AA_7_B_29_FC_17761_BC_9995_REQUEST =
  "API_FILE_JS_PLACEHOLDER_FAKE_345_EEFF_357_E_74_AA_7_B_29_FC_17761_BC_9995_REQUEST";
export const API_FILE_JS_PLACEHOLDER_FAKE_345_EEFF_357_E_74_AA_7_B_29_FC_17761_BC_9995_SUCCESS =
  "API_FILE_JS_PLACEHOLDER_FAKE_345_EEFF_357_E_74_AA_7_B_29_FC_17761_BC_9995_SUCCESS";
export const API_FILE_JS_PLACEHOLDER_FAKE_345_EEFF_357_E_74_AA_7_B_29_FC_17761_BC_9995_FAILURE =
  "API_FILE_JS_PLACEHOLDER_FAKE_345_EEFF_357_E_74_AA_7_B_29_FC_17761_BC_9995_FAILURE";

export const apiFileJsPlaceholderFake345eeff357e74aa7b29fc17761bc9995 =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          API_FILE_JS_PLACEHOLDER_FAKE_345_EEFF_357_E_74_AA_7_B_29_FC_17761_BC_9995_REQUEST,
          API_FILE_JS_PLACEHOLDER_FAKE_345_EEFF_357_E_74_AA_7_B_29_FC_17761_BC_9995_SUCCESS,
          API_FILE_JS_PLACEHOLDER_FAKE_345_EEFF_357_E_74_AA_7_B_29_FC_17761_BC_9995_FAILURE,
        ],

        endpoint:
          "/api/api_file_js_placeholder/345eeff357e74aa7b29fc17761bc9995",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_DIZIONARIO_SOFT_REQUEST = "GET_DIZIONARIO_SOFT_REQUEST";
export const GET_DIZIONARIO_SOFT_SUCCESS = "GET_DIZIONARIO_SOFT_SUCCESS";
export const GET_DIZIONARIO_SOFT_FAILURE = "GET_DIZIONARIO_SOFT_FAILURE";

export const getDizionarioSoft =
  (dizionarioSoft, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_DIZIONARIO_SOFT_REQUEST,
          GET_DIZIONARIO_SOFT_SUCCESS,
          GET_DIZIONARIO_SOFT_FAILURE,
        ],

        endpoint: "/api/dizionarioSoft/" + dizionarioSoft,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const API_FILE_JS_PLACEHOLDER_FAKEF_31_B_65793_CF_649229_D_025968_DB_7_DB_2_D_8_REQUEST =
  "API_FILE_JS_PLACEHOLDER_FAKEF_31_B_65793_CF_649229_D_025968_DB_7_DB_2_D_8_REQUEST";
export const API_FILE_JS_PLACEHOLDER_FAKEF_31_B_65793_CF_649229_D_025968_DB_7_DB_2_D_8_SUCCESS =
  "API_FILE_JS_PLACEHOLDER_FAKEF_31_B_65793_CF_649229_D_025968_DB_7_DB_2_D_8_SUCCESS";
export const API_FILE_JS_PLACEHOLDER_FAKEF_31_B_65793_CF_649229_D_025968_DB_7_DB_2_D_8_FAILURE =
  "API_FILE_JS_PLACEHOLDER_FAKEF_31_B_65793_CF_649229_D_025968_DB_7_DB_2_D_8_FAILURE";

export const apiFileJsPlaceholderFakef31b65793cf649229d025968db7db2d8 =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          API_FILE_JS_PLACEHOLDER_FAKEF_31_B_65793_CF_649229_D_025968_DB_7_DB_2_D_8_REQUEST,
          API_FILE_JS_PLACEHOLDER_FAKEF_31_B_65793_CF_649229_D_025968_DB_7_DB_2_D_8_SUCCESS,
          API_FILE_JS_PLACEHOLDER_FAKEF_31_B_65793_CF_649229_D_025968_DB_7_DB_2_D_8_FAILURE,
        ],

        endpoint:
          "/api/api_file_js_placeholder/f31b65793cf649229d025968db7db2d8",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const EDIT_CLIENTE_REQUEST = "EDIT_CLIENTE_REQUEST";
export const EDIT_CLIENTE_SUCCESS = "EDIT_CLIENTE_SUCCESS";
export const EDIT_CLIENTE_FAILURE = "EDIT_CLIENTE_FAILURE";

export const editCliente =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_CLIENTE_REQUEST,
          EDIT_CLIENTE_SUCCESS,
          EDIT_CLIENTE_FAILURE,
        ],

        endpoint: "/api/clienti/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editCliente.validator = Validators.editClienteValidator;

export const EDIT_CONTATTO_REQUEST = "EDIT_CONTATTO_REQUEST";
export const EDIT_CONTATTO_SUCCESS = "EDIT_CONTATTO_SUCCESS";
export const EDIT_CONTATTO_FAILURE = "EDIT_CONTATTO_FAILURE";

export const editContatto =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_CONTATTO_REQUEST,
          EDIT_CONTATTO_SUCCESS,
          EDIT_CONTATTO_FAILURE,
        ],

        endpoint: "/api/contatti/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editContatto.validator = Validators.editContattoValidator;

export const EDIT_MULTI_CLIENTI_REQUEST = "EDIT_MULTI_CLIENTI_REQUEST";
export const EDIT_MULTI_CLIENTI_SUCCESS = "EDIT_MULTI_CLIENTI_SUCCESS";
export const EDIT_MULTI_CLIENTI_FAILURE = "EDIT_MULTI_CLIENTI_FAILURE";

export const editMultiClienti =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_MULTI_CLIENTI_REQUEST,
          EDIT_MULTI_CLIENTI_SUCCESS,
          EDIT_MULTI_CLIENTI_FAILURE,
        ],

        endpoint: "/api/clienti",
        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const EDIT_MY_UTENTE_REQUEST = "EDIT_MY_UTENTE_REQUEST";
export const EDIT_MY_UTENTE_SUCCESS = "EDIT_MY_UTENTE_SUCCESS";
export const EDIT_MY_UTENTE_FAILURE = "EDIT_MY_UTENTE_FAILURE";

export const editMyUtente =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_MY_UTENTE_REQUEST,
          EDIT_MY_UTENTE_SUCCESS,
          EDIT_MY_UTENTE_FAILURE,
        ],

        endpoint: "/api/utenti/" + id,

        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

editMyUtente.validator = Validators.editMyUtenteValidator;

export const EDIT_UTENTE_REQUEST = "EDIT_UTENTE_REQUEST";
export const EDIT_UTENTE_SUCCESS = "EDIT_UTENTE_SUCCESS";
export const EDIT_UTENTE_FAILURE = "EDIT_UTENTE_FAILURE";

export const editUtente =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [EDIT_UTENTE_REQUEST, EDIT_UTENTE_SUCCESS, EDIT_UTENTE_FAILURE],
        endpoint: "/api/utenti/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editUtente.validator = Validators.editUtenteValidator;

export const CREATE_FATTURE_FROM_PAGAMENTI_REQUEST =
  "CREATE_FATTURE_FROM_PAGAMENTI_REQUEST";
export const CREATE_FATTURE_FROM_PAGAMENTI_SUCCESS =
  "CREATE_FATTURE_FROM_PAGAMENTI_SUCCESS";
export const CREATE_FATTURE_FROM_PAGAMENTI_FAILURE =
  "CREATE_FATTURE_FROM_PAGAMENTI_FAILURE";

export const createFattureFromPagamenti =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_FATTURE_FROM_PAGAMENTI_REQUEST,
          CREATE_FATTURE_FROM_PAGAMENTI_SUCCESS,
          CREATE_FATTURE_FROM_PAGAMENTI_FAILURE,
        ],

        endpoint: "/api/fatture/crea_con_pagamenti",
        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const DELETE_FATTURA_ANNULLATA_REQUEST =
  "DELETE_FATTURA_ANNULLATA_REQUEST";
export const DELETE_FATTURA_ANNULLATA_SUCCESS =
  "DELETE_FATTURA_ANNULLATA_SUCCESS";
export const DELETE_FATTURA_ANNULLATA_FAILURE =
  "DELETE_FATTURA_ANNULLATA_FAILURE";

export const deleteFatturaAnnullata =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_FATTURA_ANNULLATA_REQUEST,
          DELETE_FATTURA_ANNULLATA_SUCCESS,
          DELETE_FATTURA_ANNULLATA_FAILURE,
        ],

        endpoint: "/api/fattura/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_FATTURA_REQUEST = "EDIT_FATTURA_REQUEST";
export const EDIT_FATTURA_SUCCESS = "EDIT_FATTURA_SUCCESS";
export const EDIT_FATTURA_FAILURE = "EDIT_FATTURA_FAILURE";

export const editFattura =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_FATTURA_REQUEST,
          EDIT_FATTURA_SUCCESS,
          EDIT_FATTURA_FAILURE,
        ],

        endpoint: "/api/fattura/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editFattura.validator = Validators.editFatturaValidator;

export const GET_FATTURA_MAGO_REQUEST = "GET_FATTURA_MAGO_REQUEST";
export const GET_FATTURA_MAGO_SUCCESS = "GET_FATTURA_MAGO_SUCCESS";
export const GET_FATTURA_MAGO_FAILURE = "GET_FATTURA_MAGO_FAILURE";

export const getFatturaMago =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_FATTURA_MAGO_REQUEST,
          GET_FATTURA_MAGO_SUCCESS,
          GET_FATTURA_MAGO_FAILURE,
        ],

        endpoint: "/api/fattura/" + id + "/mago",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const INVIO_MASSIVO_FATTURE_REQUEST = "INVIO_MASSIVO_FATTURE_REQUEST";
export const INVIO_MASSIVO_FATTURE_SUCCESS = "INVIO_MASSIVO_FATTURE_SUCCESS";
export const INVIO_MASSIVO_FATTURE_FAILURE = "INVIO_MASSIVO_FATTURE_FAILURE";

export const invioMassivoFatture =
  (body, abortController = null) =>
  (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          INVIO_MASSIVO_FATTURE_REQUEST,
          INVIO_MASSIVO_FATTURE_SUCCESS,
          INVIO_MASSIVO_FATTURE_FAILURE,
        ],

        endpoint: "/api/fatture/invio_massivo",

        method: "POST",
        body,

        abortController: abortController,
      },
    });
  };

export const PAGAMENTO_MASSIVO_FATTURE_REQUEST =
  "PAGAMENTO_MASSIVO_FATTURE_REQUEST";
export const PAGAMENTO_MASSIVO_FATTURE_SUCCESS =
  "PAGAMENTO_MASSIVO_FATTURE_SUCCESS";
export const PAGAMENTO_MASSIVO_FATTURE_FAILURE =
  "PAGAMENTO_MASSIVO_FATTURE_FAILURE";

export const pagamentoMassivoFatture =
  (body, abortController = null) =>
  (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          PAGAMENTO_MASSIVO_FATTURE_REQUEST,
          PAGAMENTO_MASSIVO_FATTURE_SUCCESS,
          PAGAMENTO_MASSIVO_FATTURE_FAILURE,
        ],
        endpoint: "/api/fatture/pagamento_massivo",
        method: "POST",
        body,
        abortController: abortController,
      },
    });
  };

export const GET_FATTURE_REQUEST = "GET_FATTURE_REQUEST";
export const GET_FATTURE_SUCCESS = "GET_FATTURE_SUCCESS";
export const GET_FATTURE_FAILURE = "GET_FATTURE_FAILURE";

export const getFatture =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_FATTURE_REQUEST, GET_FATTURE_SUCCESS, GET_FATTURE_FAILURE],
        endpoint: "/api/fatture",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_FATTURE_DA_APPROVARE_REQUEST =
  "GET_FATTURE_DA_APPROVARE_REQUEST";
export const GET_FATTURE_DA_APPROVARE_SUCCESS =
  "GET_FATTURE_DA_APPROVARE_SUCCESS";
export const GET_FATTURE_DA_APPROVARE_FAILURE =
  "GET_FATTURE_DA_APPROVARE_FAILURE";

export const getFattureDaApprovare =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_FATTURE_DA_APPROVARE_REQUEST,
          GET_FATTURE_DA_APPROVARE_SUCCESS,
          GET_FATTURE_DA_APPROVARE_FAILURE,
        ],

        endpoint: "/api/fatture_da_approvare",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_FATTURE_PER_CLIENTE_REQUEST =
  "GET_FATTURE_PER_CLIENTE_REQUEST";
export const GET_FATTURE_PER_CLIENTE_SUCCESS =
  "GET_FATTURE_PER_CLIENTE_SUCCESS";
export const GET_FATTURE_PER_CLIENTE_FAILURE =
  "GET_FATTURE_PER_CLIENTE_FAILURE";

export const getFatturePerCliente =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_FATTURE_PER_CLIENTE_REQUEST,
          GET_FATTURE_PER_CLIENTE_SUCCESS,
          GET_FATTURE_PER_CLIENTE_FAILURE,
        ],

        endpoint: "/api/fatture_cliente/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const DOWNLOAD_FILE_REQUEST = "DOWNLOAD_FILE_REQUEST";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAILURE = "DOWNLOAD_FILE_FAILURE";

export const downloadFile =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DOWNLOAD_FILE_REQUEST,
          DOWNLOAD_FILE_SUCCESS,
          DOWNLOAD_FILE_FAILURE,
        ],

        endpoint: "/api/files/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const REPLACE_FILE_REQUEST = "REPLACE_FILE_REQUEST";
export const REPLACE_FILE_SUCCESS = "REPLACE_FILE_SUCCESS";
export const REPLACE_FILE_FAILURE = "REPLACE_FILE_FAILURE";

export const replaceFile =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          REPLACE_FILE_REQUEST,
          REPLACE_FILE_SUCCESS,
          REPLACE_FILE_FAILURE,
        ],

        endpoint: "/api/files/" + id,

        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const uploadFile =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE],
        endpoint: "/api/files",
        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const USE_OTHER_FILE_REQUEST = "USE_OTHER_FILE_REQUEST";
export const USE_OTHER_FILE_SUCCESS = "USE_OTHER_FILE_SUCCESS";
export const USE_OTHER_FILE_FAILURE = "USE_OTHER_FILE_FAILURE";

export const useOtherFile =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          USE_OTHER_FILE_REQUEST,
          USE_OTHER_FILE_SUCCESS,
          USE_OTHER_FILE_FAILURE,
        ],

        endpoint: "/api/use_file",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

export const CREATE_FORMAT_ATTIVITA_REQUEST = "CREATE_FORMAT_ATTIVITA_REQUEST";
export const CREATE_FORMAT_ATTIVITA_SUCCESS = "CREATE_FORMAT_ATTIVITA_SUCCESS";
export const CREATE_FORMAT_ATTIVITA_FAILURE = "CREATE_FORMAT_ATTIVITA_FAILURE";

export const createFormatAttivita =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_FORMAT_ATTIVITA_REQUEST,
          CREATE_FORMAT_ATTIVITA_SUCCESS,
          CREATE_FORMAT_ATTIVITA_FAILURE,
        ],

        endpoint: "/api/formatAttivita",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createFormatAttivita.validator = Validators.createFormatAttivitaValidator;

export const DELETE_FORMAT_ATTIVITA_REQUEST = "DELETE_FORMAT_ATTIVITA_REQUEST";
export const DELETE_FORMAT_ATTIVITA_SUCCESS = "DELETE_FORMAT_ATTIVITA_SUCCESS";
export const DELETE_FORMAT_ATTIVITA_FAILURE = "DELETE_FORMAT_ATTIVITA_FAILURE";

export const deleteFormatAttivita =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_FORMAT_ATTIVITA_REQUEST,
          DELETE_FORMAT_ATTIVITA_SUCCESS,
          DELETE_FORMAT_ATTIVITA_FAILURE,
        ],

        endpoint: "/api/formatAttivita/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_FORMAT_ATTIVITA_REQUEST = "EDIT_FORMAT_ATTIVITA_REQUEST";
export const EDIT_FORMAT_ATTIVITA_SUCCESS = "EDIT_FORMAT_ATTIVITA_SUCCESS";
export const EDIT_FORMAT_ATTIVITA_FAILURE = "EDIT_FORMAT_ATTIVITA_FAILURE";

export const editFormatAttivita =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_FORMAT_ATTIVITA_REQUEST,
          EDIT_FORMAT_ATTIVITA_SUCCESS,
          EDIT_FORMAT_ATTIVITA_FAILURE,
        ],

        endpoint: "/api/formatAttivita/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editFormatAttivita.validator = Validators.editFormatAttivitaValidator;

export const GET_FORMAT_ATTIVITA_PROCEDURA_REQUEST =
  "GET_FORMAT_ATTIVITA_PROCEDURA_REQUEST";
export const GET_FORMAT_ATTIVITA_PROCEDURA_SUCCESS =
  "GET_FORMAT_ATTIVITA_PROCEDURA_SUCCESS";
export const GET_FORMAT_ATTIVITA_PROCEDURA_FAILURE =
  "GET_FORMAT_ATTIVITA_PROCEDURA_FAILURE";

export const getFormatAttivitaProcedura =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_FORMAT_ATTIVITA_PROCEDURA_REQUEST,
          GET_FORMAT_ATTIVITA_PROCEDURA_SUCCESS,
          GET_FORMAT_ATTIVITA_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/formatProcedure/" + id + "/attivita",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const SAVE_FORMAT_ATTIVITA_ORDER_REQUEST =
  "SAVE_FORMAT_ATTIVITA_ORDER_REQUEST";
export const SAVE_FORMAT_ATTIVITA_ORDER_SUCCESS =
  "SAVE_FORMAT_ATTIVITA_ORDER_SUCCESS";
export const SAVE_FORMAT_ATTIVITA_ORDER_FAILURE =
  "SAVE_FORMAT_ATTIVITA_ORDER_FAILURE";

export const saveFormatAttivitaOrder =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          SAVE_FORMAT_ATTIVITA_ORDER_REQUEST,
          SAVE_FORMAT_ATTIVITA_ORDER_SUCCESS,
          SAVE_FORMAT_ATTIVITA_ORDER_FAILURE,
        ],

        endpoint: "/api/formatProcedure/" + id + "/attivita_order",
        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const CREATE_NUOVA_VERSIONE_FORMAT_PROCEDURA_REQUEST =
  "CREATE_NUOVA_VERSIONE_FORMAT_PROCEDURA_REQUEST";
export const CREATE_NUOVA_VERSIONE_FORMAT_PROCEDURA_SUCCESS =
  "CREATE_NUOVA_VERSIONE_FORMAT_PROCEDURA_SUCCESS";
export const CREATE_NUOVA_VERSIONE_FORMAT_PROCEDURA_FAILURE =
  "CREATE_NUOVA_VERSIONE_FORMAT_PROCEDURA_FAILURE";

export const createNuovaVersioneFormatProcedura =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_NUOVA_VERSIONE_FORMAT_PROCEDURA_REQUEST,
          CREATE_NUOVA_VERSIONE_FORMAT_PROCEDURA_SUCCESS,
          CREATE_NUOVA_VERSIONE_FORMAT_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/formatProcedure/" + id + "/creaNuovaVersione",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

export const DELETE_FORMAT_PROCEDURA_REQUEST =
  "DELETE_FORMAT_PROCEDURA_REQUEST";
export const DELETE_FORMAT_PROCEDURA_SUCCESS =
  "DELETE_FORMAT_PROCEDURA_SUCCESS";
export const DELETE_FORMAT_PROCEDURA_FAILURE =
  "DELETE_FORMAT_PROCEDURA_FAILURE";

export const deleteFormatProcedura =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_FORMAT_PROCEDURA_REQUEST,
          DELETE_FORMAT_PROCEDURA_SUCCESS,
          DELETE_FORMAT_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/formatProcedure/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_FORMAT_PROCEDURA_REQUEST = "EDIT_FORMAT_PROCEDURA_REQUEST";
export const EDIT_FORMAT_PROCEDURA_SUCCESS = "EDIT_FORMAT_PROCEDURA_SUCCESS";
export const EDIT_FORMAT_PROCEDURA_FAILURE = "EDIT_FORMAT_PROCEDURA_FAILURE";

export const editFormatProcedura =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_FORMAT_PROCEDURA_REQUEST,
          EDIT_FORMAT_PROCEDURA_SUCCESS,
          EDIT_FORMAT_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/formatProcedure/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editFormatProcedura.validator = Validators.editFormatProceduraValidator;

export const GET_FORMAT_PROCEDURA_REQUEST = "GET_FORMAT_PROCEDURA_REQUEST";
export const GET_FORMAT_PROCEDURA_SUCCESS = "GET_FORMAT_PROCEDURA_SUCCESS";
export const GET_FORMAT_PROCEDURA_FAILURE = "GET_FORMAT_PROCEDURA_FAILURE";

export const getFormatProcedura =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_FORMAT_PROCEDURA_REQUEST,
          GET_FORMAT_PROCEDURA_SUCCESS,
          GET_FORMAT_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/formatProcedure/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_FORMAT_PROCEDURE_REQUEST = "GET_FORMAT_PROCEDURE_REQUEST";
export const GET_FORMAT_PROCEDURE_SUCCESS = "GET_FORMAT_PROCEDURE_SUCCESS";
export const GET_FORMAT_PROCEDURE_FAILURE = "GET_FORMAT_PROCEDURE_FAILURE";

export const getFormatProcedure =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_FORMAT_PROCEDURE_REQUEST,
          GET_FORMAT_PROCEDURE_SUCCESS,
          GET_FORMAT_PROCEDURE_FAILURE,
        ],

        endpoint: "/api/formatProcedure",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_FORMAT_STRUMENTO_REQUEST =
  "CREATE_FORMAT_STRUMENTO_REQUEST";
export const CREATE_FORMAT_STRUMENTO_SUCCESS =
  "CREATE_FORMAT_STRUMENTO_SUCCESS";
export const CREATE_FORMAT_STRUMENTO_FAILURE =
  "CREATE_FORMAT_STRUMENTO_FAILURE";

export const createFormatStrumento =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_FORMAT_STRUMENTO_REQUEST,
          CREATE_FORMAT_STRUMENTO_SUCCESS,
          CREATE_FORMAT_STRUMENTO_FAILURE,
        ],

        endpoint: "/api/formatStrumento",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createFormatStrumento.validator = Validators.createFormatStrumentoValidator;

export const DELETE_FORMAT_STRUMENTO_REQUEST =
  "DELETE_FORMAT_STRUMENTO_REQUEST";
export const DELETE_FORMAT_STRUMENTO_SUCCESS =
  "DELETE_FORMAT_STRUMENTO_SUCCESS";
export const DELETE_FORMAT_STRUMENTO_FAILURE =
  "DELETE_FORMAT_STRUMENTO_FAILURE";

export const deleteFormatStrumento =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_FORMAT_STRUMENTO_REQUEST,
          DELETE_FORMAT_STRUMENTO_SUCCESS,
          DELETE_FORMAT_STRUMENTO_FAILURE,
        ],

        endpoint: "/api/formatStrumenti/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_FORMAT_STRUMENTO_REQUEST = "EDIT_FORMAT_STRUMENTO_REQUEST";
export const EDIT_FORMAT_STRUMENTO_SUCCESS = "EDIT_FORMAT_STRUMENTO_SUCCESS";
export const EDIT_FORMAT_STRUMENTO_FAILURE = "EDIT_FORMAT_STRUMENTO_FAILURE";

export const editFormatStrumento =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_FORMAT_STRUMENTO_REQUEST,
          EDIT_FORMAT_STRUMENTO_SUCCESS,
          EDIT_FORMAT_STRUMENTO_FAILURE,
        ],

        endpoint: "/api/formatStrumento/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editFormatStrumento.validator = Validators.editFormatStrumentoValidator;

export const DUPLICATE_FORMAT_STRUMENTO_REQUEST =
  "DUPLICATE_FORMAT_STRUMENTO_REQUEST";
export const DUPLICATE_FORMAT_STRUMENTO_SUCCESS =
  "DUPLICATE_FORMAT_STRUMENTO_SUCCESS";
export const DUPLICATE_FORMAT_STRUMENTO_FAILURE =
  "DUPLICATE_FORMAT_STRUMENTO_FAILURE";

export const duplicateFormatStrumento =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DUPLICATE_FORMAT_STRUMENTO_REQUEST,
          DUPLICATE_FORMAT_STRUMENTO_SUCCESS,
          DUPLICATE_FORMAT_STRUMENTO_FAILURE,
        ],

        endpoint: "/api/formatStrumento/" + id + "/duplica",
        method: "POST",
        abortController: abortController,
      },
    });
  };

export const GET_FORMAT_STRUMENTI_REQUEST = "GET_FORMAT_STRUMENTI_REQUEST";
export const GET_FORMAT_STRUMENTI_SUCCESS = "GET_FORMAT_STRUMENTI_SUCCESS";
export const GET_FORMAT_STRUMENTI_FAILURE = "GET_FORMAT_STRUMENTI_FAILURE";

export const getFormatStrumenti =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_FORMAT_STRUMENTI_REQUEST,
          GET_FORMAT_STRUMENTI_SUCCESS,
          GET_FORMAT_STRUMENTI_FAILURE,
        ],

        endpoint: "/api/formatStrumenti",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_FORMAT_STRUMENTO_REQUEST = "GET_FORMAT_STRUMENTO_REQUEST";
export const GET_FORMAT_STRUMENTO_SUCCESS = "GET_FORMAT_STRUMENTO_SUCCESS";
export const GET_FORMAT_STRUMENTO_FAILURE = "GET_FORMAT_STRUMENTO_FAILURE";

export const getFormatStrumento =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_FORMAT_STRUMENTO_REQUEST,
          GET_FORMAT_STRUMENTO_SUCCESS,
          GET_FORMAT_STRUMENTO_FAILURE,
        ],

        endpoint: "/api/formatStrumenti/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_CLIENTE_REQUEST = "GET_CLIENTE_REQUEST";
export const GET_CLIENTE_SUCCESS = "GET_CLIENTE_SUCCESS";
export const GET_CLIENTE_FAILURE = "GET_CLIENTE_FAILURE";

export const getCliente =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_CLIENTE_REQUEST, GET_CLIENTE_SUCCESS, GET_CLIENTE_FAILURE],
        endpoint: "/api/clienti/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_CLIENTI_REQUEST = "GET_CLIENTI_REQUEST";
export const GET_CLIENTI_SUCCESS = "GET_CLIENTI_SUCCESS";
export const GET_CLIENTI_FAILURE = "GET_CLIENTI_FAILURE";

export const getClienti = createTableApi((query, abortController, dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [GET_CLIENTI_REQUEST, GET_CLIENTI_SUCCESS, GET_CLIENTI_FAILURE],
      endpoint: "/api/clienti" + query,
      method: "GET",
      abortController: abortController,
    },
  });
});

export const GET_CLIENTI_CLIENTI_ATTIVI_REQUEST =
  "GET_CLIENTI_CLIENTI_ATTIVI_REQUEST";
export const GET_CLIENTI_CLIENTI_ATTIVI_SUCCESS =
  "GET_CLIENTI_CLIENTI_ATTIVI_SUCCESS";
export const GET_CLIENTI_CLIENTI_ATTIVI_FAILURE =
  "GET_CLIENTI_CLIENTI_ATTIVI_FAILURE";

export const getClientiClientiAttivi = createTableApi(
  (query, abortController, dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_CLIENTI_CLIENTI_ATTIVI_REQUEST,
          GET_CLIENTI_CLIENTI_ATTIVI_SUCCESS,
          GET_CLIENTI_CLIENTI_ATTIVI_FAILURE,
        ],

        endpoint: "/api/clienti_clienti" + query,
        method: "GET",
        abortController: abortController,
      },
    });
  }
);

export const GET_CLIENTI_CLIENTI_INATTIVI_REQUEST =
  "GET_CLIENTI_CLIENTI_INATTIVI_REQUEST";
export const GET_CLIENTI_CLIENTI_INATTIVI_SUCCESS =
  "GET_CLIENTI_CLIENTI_INATTIVI_SUCCESS";
export const GET_CLIENTI_CLIENTI_INATTIVI_FAILURE =
  "GET_CLIENTI_CLIENTI_INATTIVI_FAILURE";

export const getClientiClientiInattivi = createTableApi(
  (query, abortController, dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_CLIENTI_CLIENTI_INATTIVI_REQUEST,
          GET_CLIENTI_CLIENTI_INATTIVI_SUCCESS,
          GET_CLIENTI_CLIENTI_INATTIVI_FAILURE,
        ],

        endpoint: "/api/clienti_clienti_inattivi" + query,
        method: "GET",
        abortController: abortController,
      },
    });
  }
);

export const GET_CLIENTI_CONTATTI_REQUEST = "GET_CLIENTI_CONTATTI_REQUEST";
export const GET_CLIENTI_CONTATTI_SUCCESS = "GET_CLIENTI_CONTATTI_SUCCESS";
export const GET_CLIENTI_CONTATTI_FAILURE = "GET_CLIENTI_CONTATTI_FAILURE";

export const getClientiContatti = createTableApi(
  (query, abortController, dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_CLIENTI_CONTATTI_REQUEST,
          GET_CLIENTI_CONTATTI_SUCCESS,
          GET_CLIENTI_CONTATTI_FAILURE,
        ],

        endpoint: "/api/clienti_contatti" + query,
        method: "GET",
        abortController: abortController,
      },
    });
  }
);

export const GET_CONTATTI_REQUEST = "GET_CONTATTI_REQUEST";
export const GET_CONTATTI_SUCCESS = "GET_CONTATTI_SUCCESS";
export const GET_CONTATTI_FAILURE = "GET_CONTATTI_FAILURE";

export const getContatti =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_CONTATTI_REQUEST,
          GET_CONTATTI_SUCCESS,
          GET_CONTATTI_FAILURE,
        ],

        endpoint: "/api/contatti",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_CONTATTI_LEAD_REQUEST = "GET_CONTATTI_LEAD_REQUEST";
export const GET_CONTATTI_LEAD_SUCCESS = "GET_CONTATTI_LEAD_SUCCESS";
export const GET_CONTATTI_LEAD_FAILURE = "GET_CONTATTI_LEAD_FAILURE";

export const getContattiLead =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_CONTATTI_LEAD_REQUEST,
          GET_CONTATTI_LEAD_SUCCESS,
          GET_CONTATTI_LEAD_FAILURE,
        ],

        endpoint: "/api/contatti_lead",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_CONTATTO_REQUEST = "GET_CONTATTO_REQUEST";
export const GET_CONTATTO_SUCCESS = "GET_CONTATTO_SUCCESS";
export const GET_CONTATTO_FAILURE = "GET_CONTATTO_FAILURE";

export const getContatto =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_CONTATTO_REQUEST,
          GET_CONTATTO_SUCCESS,
          GET_CONTATTO_FAILURE,
        ],

        endpoint: "/api/contatti/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_MY_UTENTE_REQUEST = "GET_MY_UTENTE_REQUEST";
export const GET_MY_UTENTE_SUCCESS = "GET_MY_UTENTE_SUCCESS";
export const GET_MY_UTENTE_FAILURE = "GET_MY_UTENTE_FAILURE";

export const getMyUtente =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_MY_UTENTE_REQUEST,
          GET_MY_UTENTE_SUCCESS,
          GET_MY_UTENTE_FAILURE,
        ],

        endpoint: "/api/utenti/me",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_UTENTI_REQUEST = "GET_UTENTI_REQUEST";
export const GET_UTENTI_SUCCESS = "GET_UTENTI_SUCCESS";
export const GET_UTENTI_FAILURE = "GET_UTENTI_FAILURE";

export const getUtenti =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_UTENTI_REQUEST, GET_UTENTI_SUCCESS, GET_UTENTI_FAILURE],
        endpoint: "/api/utenti",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_VARIABILE_INFO_REQUEST = "GET_VARIABILE_INFO_REQUEST";
export const GET_VARIABILE_INFO_SUCCESS = "GET_VARIABILE_INFO_SUCCESS";
export const GET_VARIABILE_INFO_FAILURE = "GET_VARIABILE_INFO_FAILURE";

export const getVariabileInfo =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_VARIABILE_INFO_REQUEST,
          GET_VARIABILE_INFO_SUCCESS,
          GET_VARIABILE_INFO_FAILURE,
        ],

        endpoint: "/api/variabile/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_EVENTO_AGENDA_REQUEST = "CREATE_EVENTO_AGENDA_REQUEST";
export const CREATE_EVENTO_AGENDA_SUCCESS = "CREATE_EVENTO_AGENDA_SUCCESS";
export const CREATE_EVENTO_AGENDA_FAILURE = "CREATE_EVENTO_AGENDA_FAILURE";

export const createEventoAgenda =
  (body, abortController = null) =>
  (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_EVENTO_AGENDA_REQUEST,
          CREATE_EVENTO_AGENDA_SUCCESS,
          CREATE_EVENTO_AGENDA_FAILURE,
        ],

        endpoint: "/api/eventoAgenda",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createEventoAgenda.validator = Validators.createEventoAgendaValidator;

export const CREATE_INTERAZIONI_CLIENTE_REQUEST =
  "CREATE_INTERAZIONI_CLIENTE_REQUEST";
export const CREATE_INTERAZIONI_CLIENTE_SUCCESS =
  "CREATE_INTERAZIONI_CLIENTE_SUCCESS";
export const CREATE_INTERAZIONI_CLIENTE_FAILURE =
  "CREATE_INTERAZIONI_CLIENTE_FAILURE";

export const createInterazioniCliente =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_INTERAZIONI_CLIENTE_REQUEST,
          CREATE_INTERAZIONI_CLIENTE_SUCCESS,
          CREATE_INTERAZIONI_CLIENTE_FAILURE,
        ],

        endpoint: "/api/interazioniCliente",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createInterazioniCliente.validator =
  Validators.createInterazioniClienteValidator;

export const CREATE_INTERAZIONI_CONTATTO_REQUEST =
  "CREATE_INTERAZIONI_CONTATTO_REQUEST";
export const CREATE_INTERAZIONI_CONTATTO_SUCCESS =
  "CREATE_INTERAZIONI_CONTATTO_SUCCESS";
export const CREATE_INTERAZIONI_CONTATTO_FAILURE =
  "CREATE_INTERAZIONI_CONTATTO_FAILURE";

export const createInterazioniContatto =
  (body, abortController = null) =>
  (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_INTERAZIONI_CONTATTO_REQUEST,
          CREATE_INTERAZIONI_CONTATTO_SUCCESS,
          CREATE_INTERAZIONI_CONTATTO_FAILURE,
        ],

        endpoint: "/api/interazioniContatto",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createInterazioniContatto.validator =
  Validators.createInterazioniContattoValidator;

export const DELETE_EVENTO_AGENDA_REQUEST = "DELETE_EVENTO_AGENDA_REQUEST";
export const DELETE_EVENTO_AGENDA_SUCCESS = "DELETE_EVENTO_AGENDA_SUCCESS";
export const DELETE_EVENTO_AGENDA_FAILURE = "DELETE_EVENTO_AGENDA_FAILURE";

export const deleteEventoAgenda =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_EVENTO_AGENDA_REQUEST,
          DELETE_EVENTO_AGENDA_SUCCESS,
          DELETE_EVENTO_AGENDA_FAILURE,
        ],

        endpoint: "/api/eventoAgenda/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const DELETE_INTERAZIONI_CLIENTE_REQUEST =
  "DELETE_INTERAZIONI_CLIENTE_REQUEST";
export const DELETE_INTERAZIONI_CLIENTE_SUCCESS =
  "DELETE_INTERAZIONI_CLIENTE_SUCCESS";
export const DELETE_INTERAZIONI_CLIENTE_FAILURE =
  "DELETE_INTERAZIONI_CLIENTE_FAILURE";

export const deleteInterazioniCliente =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_INTERAZIONI_CLIENTE_REQUEST,
          DELETE_INTERAZIONI_CLIENTE_SUCCESS,
          DELETE_INTERAZIONI_CLIENTE_FAILURE,
        ],

        endpoint: "/api/interazioniCliente/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const DELETE_INTERAZIONI_CONTATTO_REQUEST =
  "DELETE_INTERAZIONI_CONTATTO_REQUEST";
export const DELETE_INTERAZIONI_CONTATTO_SUCCESS =
  "DELETE_INTERAZIONI_CONTATTO_SUCCESS";
export const DELETE_INTERAZIONI_CONTATTO_FAILURE =
  "DELETE_INTERAZIONI_CONTATTO_FAILURE";

export const deleteInterazioniContatto =
  (id, abortController = null) =>
  (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_INTERAZIONI_CONTATTO_REQUEST,
          DELETE_INTERAZIONI_CONTATTO_SUCCESS,
          DELETE_INTERAZIONI_CONTATTO_FAILURE,
        ],

        endpoint: "/api/interazioniContatto/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_EVENTO_AGENDA_REQUEST = "EDIT_EVENTO_AGENDA_REQUEST";
export const EDIT_EVENTO_AGENDA_SUCCESS = "EDIT_EVENTO_AGENDA_SUCCESS";
export const EDIT_EVENTO_AGENDA_FAILURE = "EDIT_EVENTO_AGENDA_FAILURE";

export const editEventoAgenda =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_EVENTO_AGENDA_REQUEST,
          EDIT_EVENTO_AGENDA_SUCCESS,
          EDIT_EVENTO_AGENDA_FAILURE,
        ],

        endpoint: "/api/eventoAgenda/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editEventoAgenda.validator = Validators.editEventoAgendaValidator;

export const EDIT_INTERAZIONI_CLIENTE_REQUEST =
  "EDIT_INTERAZIONI_CLIENTE_REQUEST";
export const EDIT_INTERAZIONI_CLIENTE_SUCCESS =
  "EDIT_INTERAZIONI_CLIENTE_SUCCESS";
export const EDIT_INTERAZIONI_CLIENTE_FAILURE =
  "EDIT_INTERAZIONI_CLIENTE_FAILURE";

export const editInterazioniCliente =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_INTERAZIONI_CLIENTE_REQUEST,
          EDIT_INTERAZIONI_CLIENTE_SUCCESS,
          EDIT_INTERAZIONI_CLIENTE_FAILURE,
        ],

        endpoint: "/api/interazioniCliente/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editInterazioniCliente.validator = Validators.editInterazioniClienteValidator;

export const EDIT_INTERAZIONI_CONTATTO_REQUEST =
  "EDIT_INTERAZIONI_CONTATTO_REQUEST";
export const EDIT_INTERAZIONI_CONTATTO_SUCCESS =
  "EDIT_INTERAZIONI_CONTATTO_SUCCESS";
export const EDIT_INTERAZIONI_CONTATTO_FAILURE =
  "EDIT_INTERAZIONI_CONTATTO_FAILURE";

export const editInterazioniContatto =
  (id, body, abortController = null) =>
  (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_INTERAZIONI_CONTATTO_REQUEST,
          EDIT_INTERAZIONI_CONTATTO_SUCCESS,
          EDIT_INTERAZIONI_CONTATTO_FAILURE,
        ],

        endpoint: "/api/interazioniContatto/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editInterazioniContatto.validator = Validators.editInterazioniContattoValidator;

export const GET_ALL_INTERAZIONI_WITH_SCADENZA_REQUEST =
  "GET_ALL_INTERAZIONI_WITH_SCADENZA_REQUEST";
export const GET_ALL_INTERAZIONI_WITH_SCADENZA_SUCCESS =
  "GET_ALL_INTERAZIONI_WITH_SCADENZA_SUCCESS";
export const GET_ALL_INTERAZIONI_WITH_SCADENZA_FAILURE =
  "GET_ALL_INTERAZIONI_WITH_SCADENZA_FAILURE";

export const getAllInterazioniWithScadenza =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_ALL_INTERAZIONI_WITH_SCADENZA_REQUEST,
          GET_ALL_INTERAZIONI_WITH_SCADENZA_SUCCESS,
          GET_ALL_INTERAZIONI_WITH_SCADENZA_FAILURE,
        ],

        endpoint: "/api/interazioniCliente",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_INTERAZIONI_CLIENTE_REQUEST =
  "GET_INTERAZIONI_CLIENTE_REQUEST";
export const GET_INTERAZIONI_CLIENTE_SUCCESS =
  "GET_INTERAZIONI_CLIENTE_SUCCESS";
export const GET_INTERAZIONI_CLIENTE_FAILURE =
  "GET_INTERAZIONI_CLIENTE_FAILURE";

export const getInterazioniCliente =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_INTERAZIONI_CLIENTE_REQUEST,
          GET_INTERAZIONI_CLIENTE_SUCCESS,
          GET_INTERAZIONI_CLIENTE_FAILURE,
        ],

        endpoint: "/api/cliente/" + id + "/interazioniCliente",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_EVENTI_AGENDA_REQUEST = "GET_EVENTI_AGENDA_REQUEST";
export const GET_EVENTI_AGENDA_SUCCESS = "GET_EVENTI_AGENDA_SUCCESS";
export const GET_EVENTI_AGENDA_FAILURE = "GET_EVENTI_AGENDA_FAILURE";

export const getEventiAgenda =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_EVENTI_AGENDA_REQUEST,
          GET_EVENTI_AGENDA_SUCCESS,
          GET_EVENTI_AGENDA_FAILURE,
        ],

        endpoint: "/api/eventiAgenda",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_EVENTI_AGENDA_BY_USER_REQUEST =
  "GET_EVENTI_AGENDA_BY_USER_REQUEST";
export const GET_EVENTI_AGENDA_BY_USER_SUCCESS =
  "GET_EVENTI_AGENDA_BY_USER_SUCCESS";
export const GET_EVENTI_AGENDA_BY_USER_FAILURE =
  "GET_EVENTI_AGENDA_BY_USER_FAILURE";

export const getEventiAgendaByUser =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_EVENTI_AGENDA_BY_USER_REQUEST,
          GET_EVENTI_AGENDA_BY_USER_SUCCESS,
          GET_EVENTI_AGENDA_BY_USER_FAILURE,
        ],
        endpoint: "/api/utenti/" + id + "/eventiAgenda",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_MIEI_EVENTI_AGENDA_REQUEST = "GET_MIEI_EVENTI_AGENDA_REQUEST";
export const GET_MIEI_EVENTI_AGENDA_SUCCESS = "GET_MIEI_EVENTI_AGENDA_SUCCESS";
export const GET_MIEI_EVENTI_AGENDA_FAILURE = "GET_MIEI_EVENTI_AGENDA_FAILURE";

export const getMieiEventiAgenda =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_MIEI_EVENTI_AGENDA_REQUEST,
          GET_MIEI_EVENTI_AGENDA_SUCCESS,
          GET_MIEI_EVENTI_AGENDA_FAILURE,
        ],

        endpoint: "/api/utente_me/eventiAgenda",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_INTERAZIONI_CONTATTO_REQUEST =
  "GET_INTERAZIONI_CONTATTO_REQUEST";
export const GET_INTERAZIONI_CONTATTO_SUCCESS =
  "GET_INTERAZIONI_CONTATTO_SUCCESS";
export const GET_INTERAZIONI_CONTATTO_FAILURE =
  "GET_INTERAZIONI_CONTATTO_FAILURE";

export const getInterazioniContatto =
  (id, abortController = null) =>
  (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_INTERAZIONI_CONTATTO_REQUEST,
          GET_INTERAZIONI_CONTATTO_SUCCESS,
          GET_INTERAZIONI_CONTATTO_FAILURE,
        ],

        endpoint: "/api/contatto/" + id + "/interazioniContatto",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_LEGGE_REQUEST = "CREATE_LEGGE_REQUEST";
export const CREATE_LEGGE_SUCCESS = "CREATE_LEGGE_SUCCESS";
export const CREATE_LEGGE_FAILURE = "CREATE_LEGGE_FAILURE";

export const createLegge =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_LEGGE_REQUEST,
          CREATE_LEGGE_SUCCESS,
          CREATE_LEGGE_FAILURE,
        ],

        endpoint: "/api/leggi",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createLegge.validator = Validators.createLeggeValidator;

export const DELETE_LEGGE_REQUEST = "DELETE_LEGGE_REQUEST";
export const DELETE_LEGGE_SUCCESS = "DELETE_LEGGE_SUCCESS";
export const DELETE_LEGGE_FAILURE = "DELETE_LEGGE_FAILURE";

export const deleteLegge =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_LEGGE_REQUEST,
          DELETE_LEGGE_SUCCESS,
          DELETE_LEGGE_FAILURE,
        ],

        endpoint: "/api/legge/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const DUPLICATE_LEGGE_REQUEST = "DUPLICATE_LEGGE_REQUEST";
export const DUPLICATE_LEGGE_SUCCESS = "DUPLICATE_LEGGE_SUCCESS";
export const DUPLICATE_LEGGE_FAILURE = "DUPLICATE_LEGGE_FAILURE";

export const duplicateLegge =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DUPLICATE_LEGGE_REQUEST,
          DUPLICATE_LEGGE_SUCCESS,
          DUPLICATE_LEGGE_FAILURE,
        ],

        endpoint: "/api/leggi/" + id + "/duplica",
        method: "POST",
        abortController: abortController,
      },
    });
  };

export const EDIT_LEGGE_REQUEST = "EDIT_LEGGE_REQUEST";
export const EDIT_LEGGE_SUCCESS = "EDIT_LEGGE_SUCCESS";
export const EDIT_LEGGE_FAILURE = "EDIT_LEGGE_FAILURE";

export const editLegge =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [EDIT_LEGGE_REQUEST, EDIT_LEGGE_SUCCESS, EDIT_LEGGE_FAILURE],
        endpoint: "/api/leggi/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editLegge.validator = Validators.editLeggeValidator;

export const GET_LEGGE_REQUEST = "GET_LEGGE_REQUEST";
export const GET_LEGGE_SUCCESS = "GET_LEGGE_SUCCESS";
export const GET_LEGGE_FAILURE = "GET_LEGGE_FAILURE";

export const getLegge =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_LEGGE_REQUEST, GET_LEGGE_SUCCESS, GET_LEGGE_FAILURE],
        endpoint: "/api/legge/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_LEGGI_REQUEST = "GET_LEGGI_REQUEST";
export const GET_LEGGI_SUCCESS = "GET_LEGGI_SUCCESS";
export const GET_LEGGI_FAILURE = "GET_LEGGI_FAILURE";

export const getLeggi =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_LEGGI_REQUEST, GET_LEGGI_SUCCESS, GET_LEGGI_FAILURE],
        endpoint: "/api/leggi",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_LINEA_REQUEST = "CREATE_LINEA_REQUEST";
export const CREATE_LINEA_SUCCESS = "CREATE_LINEA_SUCCESS";
export const CREATE_LINEA_FAILURE = "CREATE_LINEA_FAILURE";

export const createLinea =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_LINEA_REQUEST,
          CREATE_LINEA_SUCCESS,
          CREATE_LINEA_FAILURE,
        ],

        endpoint: "/api/linee",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createLinea.validator = Validators.createLineaValidator;

export const DELETE_LINEA_REQUEST = "DELETE_LINEA_REQUEST";
export const DELETE_LINEA_SUCCESS = "DELETE_LINEA_SUCCESS";
export const DELETE_LINEA_FAILURE = "DELETE_LINEA_FAILURE";

export const deleteLinea =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_LINEA_REQUEST,
          DELETE_LINEA_SUCCESS,
          DELETE_LINEA_FAILURE,
        ],

        endpoint: "/api/linea/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const DUPLICATE_LINEA_REQUEST = "DUPLICATE_LINEA_REQUEST";
export const DUPLICATE_LINEA_SUCCESS = "DUPLICATE_LINEA_SUCCESS";
export const DUPLICATE_LINEA_FAILURE = "DUPLICATE_LINEA_FAILURE";

export const duplicateLinea =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DUPLICATE_LINEA_REQUEST,
          DUPLICATE_LINEA_SUCCESS,
          DUPLICATE_LINEA_FAILURE,
        ],

        endpoint: "/api/linee/" + id + "/duplica",
        method: "POST",
        abortController: abortController,
      },
    });
  };

export const EDIT_LINEA_REQUEST = "EDIT_LINEA_REQUEST";
export const EDIT_LINEA_SUCCESS = "EDIT_LINEA_SUCCESS";
export const EDIT_LINEA_FAILURE = "EDIT_LINEA_FAILURE";

export const editLinea =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [EDIT_LINEA_REQUEST, EDIT_LINEA_SUCCESS, EDIT_LINEA_FAILURE],
        endpoint: "/api/linee/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editLinea.validator = Validators.editLineaValidator;

export const ESTENDI_LINEA_REQUEST = "ESTENDI_LINEA_REQUEST";
export const ESTENDI_LINEA_SUCCESS = "ESTENDI_LINEA_SUCCESS";
export const ESTENDI_LINEA_FAILURE = "ESTENDI_LINEA_FAILURE";

export const estendiLinea =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          ESTENDI_LINEA_REQUEST,
          ESTENDI_LINEA_SUCCESS,
          ESTENDI_LINEA_FAILURE,
        ],

        endpoint: "/api/linee/" + id + "/estendi",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

estendiLinea.validator = Validators.estendiLineaValidator;

export const GET_LINEA_REQUEST = "GET_LINEA_REQUEST";
export const GET_LINEA_SUCCESS = "GET_LINEA_SUCCESS";
export const GET_LINEA_FAILURE = "GET_LINEA_FAILURE";

export const getLinea =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_LINEA_REQUEST, GET_LINEA_SUCCESS, GET_LINEA_FAILURE],
        endpoint: "/api/linea/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_LINEE_REQUEST = "GET_LINEE_REQUEST";
export const GET_LINEE_SUCCESS = "GET_LINEE_SUCCESS";
export const GET_LINEE_FAILURE = "GET_LINEE_FAILURE";

export const getLinee =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_LINEE_REQUEST, GET_LINEE_SUCCESS, GET_LINEE_FAILURE],
        endpoint: "/api/linee",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_LINEE_WITH_SCADENZA_REQUEST =
  "GET_LINEE_WITH_SCADENZA_REQUEST";
export const GET_LINEE_WITH_SCADENZA_SUCCESS =
  "GET_LINEE_WITH_SCADENZA_SUCCESS";
export const GET_LINEE_WITH_SCADENZA_FAILURE =
  "GET_LINEE_WITH_SCADENZA_FAILURE";

export const getLineeWithScadenza =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_LINEE_WITH_SCADENZA_REQUEST,
          GET_LINEE_WITH_SCADENZA_SUCCESS,
          GET_LINEE_WITH_SCADENZA_FAILURE,
        ],

        endpoint: "/api/linee_scadenza",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const login =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
        endpoint: "/api/login",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

login.validator = Validators.loginValidator;

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const logout =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
        endpoint: "/api/logout",
        method: "POST",
        abortController: abortController,
      },
    });
  };

export const CREATE_MANDATO_REQUEST = "CREATE_MANDATO_REQUEST";
export const CREATE_MANDATO_SUCCESS = "CREATE_MANDATO_SUCCESS";
export const CREATE_MANDATO_FAILURE = "CREATE_MANDATO_FAILURE";

export const createMandato =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_MANDATO_REQUEST,
          CREATE_MANDATO_SUCCESS,
          CREATE_MANDATO_FAILURE,
        ],

        endpoint: "/api/mandati",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createMandato.validator = Validators.createMandatoValidator;

export const DELETE_MANDATO_REQUEST = "DELETE_MANDATO_REQUEST";
export const DELETE_MANDATO_SUCCESS = "DELETE_MANDATO_SUCCESS";
export const DELETE_MANDATO_FAILURE = "DELETE_MANDATO_FAILURE";

export const deleteMandato =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_MANDATO_REQUEST,
          DELETE_MANDATO_SUCCESS,
          DELETE_MANDATO_FAILURE,
        ],

        endpoint: "/api/mandati/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_MANDATO_REQUEST = "EDIT_MANDATO_REQUEST";
export const EDIT_MANDATO_SUCCESS = "EDIT_MANDATO_SUCCESS";
export const EDIT_MANDATO_FAILURE = "EDIT_MANDATO_FAILURE";

export const editMandato =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_MANDATO_REQUEST,
          EDIT_MANDATO_SUCCESS,
          EDIT_MANDATO_FAILURE,
        ],

        endpoint: "/api/mandati/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editMandato.validator = Validators.editMandatoValidator;

export const DUPLICATE_MANDATO_REQUEST = "DUPLICATE_MANDATO_REQUEST";
export const DUPLICATE_MANDATO_SUCCESS = "DUPLICATE_MANDATO_SUCCESS";
export const DUPLICATE_MANDATO_FAILURE = "DUPLICATE_MANDATO_FAILURE";

export const duplicateMandato =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DUPLICATE_MANDATO_REQUEST,
          DUPLICATE_MANDATO_SUCCESS,
          DUPLICATE_MANDATO_FAILURE,
        ],

        endpoint: "/api/mandati/" + id + "/duplica",
        method: "POST",
        abortController: abortController,
      },
    });
  };

export const GET_MANDATI_REQUEST = "GET_MANDATI_REQUEST";
export const GET_MANDATI_SUCCESS = "GET_MANDATI_SUCCESS";
export const GET_MANDATI_FAILURE = "GET_MANDATI_FAILURE";

export const getMandati =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_MANDATI_REQUEST, GET_MANDATI_SUCCESS, GET_MANDATI_FAILURE],
        endpoint: "/api/mandati",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_MANDATI_FOR_CLIENTE_REQUEST =
  "GET_MANDATI_FOR_CLIENTE_REQUEST";
export const GET_MANDATI_FOR_CLIENTE_SUCCESS =
  "GET_MANDATI_FOR_CLIENTE_SUCCESS";
export const GET_MANDATI_FOR_CLIENTE_FAILURE =
  "GET_MANDATI_FOR_CLIENTE_FAILURE";

export const getMandatiForCliente =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_MANDATI_FOR_CLIENTE_REQUEST,
          GET_MANDATI_FOR_CLIENTE_SUCCESS,
          GET_MANDATI_FOR_CLIENTE_FAILURE,
        ],

        endpoint: "/api/cliente/" + id + "/mandati",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_MANDATI_ONLY_ATTIVI_REQUEST =
  "GET_MANDATI_ONLY_ATTIVI_REQUEST";
export const GET_MANDATI_ONLY_ATTIVI_SUCCESS =
  "GET_MANDATI_ONLY_ATTIVI_SUCCESS";
export const GET_MANDATI_ONLY_ATTIVI_FAILURE =
  "GET_MANDATI_ONLY_ATTIVI_FAILURE";

export const getMandatiOnlyAttivi =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_MANDATI_ONLY_ATTIVI_REQUEST,
          GET_MANDATI_ONLY_ATTIVI_SUCCESS,
          GET_MANDATI_ONLY_ATTIVI_FAILURE,
        ],

        endpoint: "/api/mandati_attivi",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_MANDATI_ONLY_OPPORTUNITA_REQUEST =
  "GET_MANDATI_ONLY_OPPORTUNITA_REQUEST";
export const GET_MANDATI_ONLY_OPPORTUNITA_SUCCESS =
  "GET_MANDATI_ONLY_OPPORTUNITA_SUCCESS";
export const GET_MANDATI_ONLY_OPPORTUNITA_FAILURE =
  "GET_MANDATI_ONLY_OPPORTUNITA_FAILURE";

export const getMandatiOnlyOpportunita =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_MANDATI_ONLY_OPPORTUNITA_REQUEST,
          GET_MANDATI_ONLY_OPPORTUNITA_SUCCESS,
          GET_MANDATI_ONLY_OPPORTUNITA_FAILURE,
        ],

        endpoint: "/api/mandati_opportunita",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_MANDATI_ONLY_OPPORTUNITA_WITH_SCADENZA_REQUEST =
  "GET_MANDATI_ONLY_OPPORTUNITA_WITH_SCADENZA_REQUEST";
export const GET_MANDATI_ONLY_OPPORTUNITA_WITH_SCADENZA_SUCCESS =
  "GET_MANDATI_ONLY_OPPORTUNITA_WITH_SCADENZA_SUCCESS";
export const GET_MANDATI_ONLY_OPPORTUNITA_WITH_SCADENZA_FAILURE =
  "GET_MANDATI_ONLY_OPPORTUNITA_WITH_SCADENZA_FAILURE";

export const getMandatiOnlyOpportunitaWithScadenza =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_MANDATI_ONLY_OPPORTUNITA_WITH_SCADENZA_REQUEST,
          GET_MANDATI_ONLY_OPPORTUNITA_WITH_SCADENZA_SUCCESS,
          GET_MANDATI_ONLY_OPPORTUNITA_WITH_SCADENZA_FAILURE,
        ],

        endpoint: "/api/mandati_opportunita_scadenza",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_MANDATO_REQUEST = "GET_MANDATO_REQUEST";
export const GET_MANDATO_SUCCESS = "GET_MANDATO_SUCCESS";
export const GET_MANDATO_FAILURE = "GET_MANDATO_FAILURE";

export const getMandato =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_MANDATO_REQUEST, GET_MANDATO_SUCCESS, GET_MANDATO_FAILURE],
        endpoint: "/api/mandati/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_MANDATO_LINEA_MILESTONES_PAGAMENTI_REQUEST =
  "GET_MANDATO_LINEA_MILESTONES_PAGAMENTI_REQUEST";
export const GET_MANDATO_LINEA_MILESTONES_PAGAMENTI_SUCCESS =
  "GET_MANDATO_LINEA_MILESTONES_PAGAMENTI_SUCCESS";
export const GET_MANDATO_LINEA_MILESTONES_PAGAMENTI_FAILURE =
  "GET_MANDATO_LINEA_MILESTONES_PAGAMENTI_FAILURE";

export const getMandatoLineaMilestonesPagamenti =
  (idMandatoLinea, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_MANDATO_LINEA_MILESTONES_PAGAMENTI_REQUEST,
          GET_MANDATO_LINEA_MILESTONES_PAGAMENTI_SUCCESS,
          GET_MANDATO_LINEA_MILESTONES_PAGAMENTI_FAILURE,
        ],

        endpoint:
          "/api/mandati_linea/" + idMandatoLinea + "/milestonesPagamenti",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const CREATE_NEWS_REQUEST = "CREATE_NEWS_REQUEST";
export const CREATE_NEWS_SUCCESS = "CREATE_NEWS_SUCCESS";
export const CREATE_NEWS_FAILURE = "CREATE_NEWS_FAILURE";

export const createNews =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [CREATE_NEWS_REQUEST, CREATE_NEWS_SUCCESS, CREATE_NEWS_FAILURE],
        endpoint: "/api/news",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

createNews.validator = Validators.createNewsValidator;

export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_FAILURE = "DELETE_NEWS_FAILURE";

export const deleteNews =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [DELETE_NEWS_REQUEST, DELETE_NEWS_SUCCESS, DELETE_NEWS_FAILURE],
        endpoint: "/api/news/" + id,

        method: "DELETE",
        abortController: abortController,
      },
    });
  };

export const EDIT_NEWS_REQUEST = "EDIT_NEWS_REQUEST";
export const EDIT_NEWS_SUCCESS = "EDIT_NEWS_SUCCESS";
export const EDIT_NEWS_FAILURE = "EDIT_NEWS_FAILURE";

export const editNews =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [EDIT_NEWS_REQUEST, EDIT_NEWS_SUCCESS, EDIT_NEWS_FAILURE],
        endpoint: "/api/news/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editNews.validator = Validators.editNewsValidator;

export const GET_ALL_NEWS_REQUEST = "GET_ALL_NEWS_REQUEST";
export const GET_ALL_NEWS_SUCCESS = "GET_ALL_NEWS_SUCCESS";
export const GET_ALL_NEWS_FAILURE = "GET_ALL_NEWS_FAILURE";

export const getAllNews =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_ALL_NEWS_REQUEST,
          GET_ALL_NEWS_SUCCESS,
          GET_ALL_NEWS_FAILURE,
        ],

        endpoint: "/api/news_all",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_NEWS_PER_UTENTE_REQUEST = "GET_NEWS_PER_UTENTE_REQUEST";
export const GET_NEWS_PER_UTENTE_SUCCESS = "GET_NEWS_PER_UTENTE_SUCCESS";
export const GET_NEWS_PER_UTENTE_FAILURE = "GET_NEWS_PER_UTENTE_FAILURE";

export const getNewsPerUtente =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_NEWS_PER_UTENTE_REQUEST,
          GET_NEWS_PER_UTENTE_SUCCESS,
          GET_NEWS_PER_UTENTE_FAILURE,
        ],

        endpoint: "/api/news_per_utente",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const MARK_NEWS_AS_READ_REQUEST = "MARK_NEWS_AS_READ_REQUEST";
export const MARK_NEWS_AS_READ_SUCCESS = "MARK_NEWS_AS_READ_SUCCESS";
export const MARK_NEWS_AS_READ_FAILURE = "MARK_NEWS_AS_READ_FAILURE";

export const markNewsAsRead =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          MARK_NEWS_AS_READ_REQUEST,
          MARK_NEWS_AS_READ_SUCCESS,
          MARK_NEWS_AS_READ_FAILURE,
        ],

        endpoint: "/api/news/" + id + "/markAsRead",
        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const EDIT_PAGAMENTO_REQUEST = "EDIT_PAGAMENTO_REQUEST";
export const EDIT_PAGAMENTO_SUCCESS = "EDIT_PAGAMENTO_SUCCESS";
export const EDIT_PAGAMENTO_FAILURE = "EDIT_PAGAMENTO_FAILURE";

export const editPagamento =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_PAGAMENTO_REQUEST,
          EDIT_PAGAMENTO_SUCCESS,
          EDIT_PAGAMENTO_FAILURE,
        ],

        endpoint: "/api/pagamento/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

editPagamento.validator = Validators.editPagamentoValidator;

export const GET_PAGAMENTI_REQUEST = "GET_PAGAMENTI_REQUEST";
export const GET_PAGAMENTI_SUCCESS = "GET_PAGAMENTI_SUCCESS";
export const GET_PAGAMENTI_FAILURE = "GET_PAGAMENTI_FAILURE";

export const getPagamenti =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_PAGAMENTI_REQUEST,
          GET_PAGAMENTI_SUCCESS,
          GET_PAGAMENTI_FAILURE,
        ],

        endpoint: "/api/pagamenti",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_PAGAMENTI_DA_FATTURARE_REQUEST =
  "GET_PAGAMENTI_DA_FATTURARE_REQUEST";
export const GET_PAGAMENTI_DA_FATTURARE_SUCCESS =
  "GET_PAGAMENTI_DA_FATTURARE_SUCCESS";
export const GET_PAGAMENTI_DA_FATTURARE_FAILURE =
  "GET_PAGAMENTI_DA_FATTURARE_FAILURE";

export const getPagamentiDaFatturare =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_PAGAMENTI_DA_FATTURARE_REQUEST,
          GET_PAGAMENTI_DA_FATTURARE_SUCCESS,
          GET_PAGAMENTI_DA_FATTURARE_FAILURE,
        ],

        endpoint: "/api/pagamenti_da_fatturare",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const AGGIORNA_VERSIONE_PROCEDURA_REQUEST =
  "AGGIORNA_VERSIONE_PROCEDURA_REQUEST";
export const AGGIORNA_VERSIONE_PROCEDURA_SUCCESS =
  "AGGIORNA_VERSIONE_PROCEDURA_SUCCESS";
export const AGGIORNA_VERSIONE_PROCEDURA_FAILURE =
  "AGGIORNA_VERSIONE_PROCEDURA_FAILURE";

export const aggiornaVersioneProcedura =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          AGGIORNA_VERSIONE_PROCEDURA_REQUEST,
          AGGIORNA_VERSIONE_PROCEDURA_SUCCESS,
          AGGIORNA_VERSIONE_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/procedure/" + id + "/aggiornaVersione",
        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const EDIT_PROCEDURA_REQUEST = "EDIT_PROCEDURA_REQUEST";
export const EDIT_PROCEDURA_SUCCESS = "EDIT_PROCEDURA_SUCCESS";
export const EDIT_PROCEDURA_FAILURE = "EDIT_PROCEDURA_FAILURE";

export const editProcedura =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_PROCEDURA_REQUEST,
          EDIT_PROCEDURA_SUCCESS,
          EDIT_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/procedure/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const GET_CLIENTI_PROCEDURA_REQUEST = "GET_CLIENTI_PROCEDURA_REQUEST";
export const GET_CLIENTI_PROCEDURA_SUCCESS = "GET_CLIENTI_PROCEDURA_SUCCESS";
export const GET_CLIENTI_PROCEDURA_FAILURE = "GET_CLIENTI_PROCEDURA_FAILURE";

export const getClientiProcedura =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_CLIENTI_PROCEDURA_REQUEST,
          GET_CLIENTI_PROCEDURA_SUCCESS,
          GET_CLIENTI_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/procedure/" + id + "/clienti",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_PROCEDURA_REQUEST = "GET_PROCEDURA_REQUEST";
export const GET_PROCEDURA_SUCCESS = "GET_PROCEDURA_SUCCESS";
export const GET_PROCEDURA_FAILURE = "GET_PROCEDURA_FAILURE";

export const getProcedura =
  (id, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_PROCEDURA_REQUEST,
          GET_PROCEDURA_SUCCESS,
          GET_PROCEDURA_FAILURE,
        ],

        endpoint: "/api/procedure/" + id,

        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_PROCEDURE_REQUEST = "GET_PROCEDURE_REQUEST";
export const GET_PROCEDURE_SUCCESS = "GET_PROCEDURE_SUCCESS";
export const GET_PROCEDURE_FAILURE = "GET_PROCEDURE_FAILURE";

export const getProcedure =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_PROCEDURE_REQUEST,
          GET_PROCEDURE_SUCCESS,
          GET_PROCEDURE_FAILURE,
        ],

        endpoint: "/api/procedure",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const GET_PROCEDURE_NON_ASSEGNATE_REQUEST =
  "GET_PROCEDURE_NON_ASSEGNATE_REQUEST";
export const GET_PROCEDURE_NON_ASSEGNATE_SUCCESS =
  "GET_PROCEDURE_NON_ASSEGNATE_SUCCESS";
export const GET_PROCEDURE_NON_ASSEGNATE_FAILURE =
  "GET_PROCEDURE_NON_ASSEGNATE_FAILURE";

export const getProcedureNonAssegnate =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_PROCEDURE_NON_ASSEGNATE_REQUEST,
          GET_PROCEDURE_NON_ASSEGNATE_SUCCESS,
          GET_PROCEDURE_NON_ASSEGNATE_FAILURE,
        ],

        endpoint: "/api/procedureNonAssegnate",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const SAVE_INTERVALLO_PROMEMORIA_RICONTATTO_REQUEST =
  "SAVE_INTERVALLO_PROMEMORIA_RICONTATTO_REQUEST";
export const SAVE_INTERVALLO_PROMEMORIA_RICONTATTO_SUCCESS =
  "SAVE_INTERVALLO_PROMEMORIA_RICONTATTO_SUCCESS";
export const SAVE_INTERVALLO_PROMEMORIA_RICONTATTO_FAILURE =
  "SAVE_INTERVALLO_PROMEMORIA_RICONTATTO_FAILURE";

export const saveIntervalloPromemoriaRicontatto =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          SAVE_INTERVALLO_PROMEMORIA_RICONTATTO_REQUEST,
          SAVE_INTERVALLO_PROMEMORIA_RICONTATTO_SUCCESS,
          SAVE_INTERVALLO_PROMEMORIA_RICONTATTO_FAILURE,
        ],

        endpoint: "/api/promemoria-ricontatto/intervallo",
        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const GET_VOLUMI_CONTRATTI_REQUEST = "GET_VOLUMI_CONTRATTI_REQUEST";
export const GET_VOLUMI_CONTRATTI_SUCCESS = "GET_VOLUMI_CONTRATTI_SUCCESS";
export const GET_VOLUMI_CONTRATTI_FAILURE = "GET_VOLUMI_CONTRATTI_FAILURE";

export const getVolumiContratti =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_VOLUMI_CONTRATTI_REQUEST,
          GET_VOLUMI_CONTRATTI_SUCCESS,
          GET_VOLUMI_CONTRATTI_FAILURE,
        ],

        endpoint: "/api/volumiContratti",
        method: "POST",
        body: body,
        abortController: abortController,
      },
    });
  };

export const GET_analisiCommerciale_REQUEST = "GET_analisiCommerciale_REQUEST";
export const GET_analisiCommerciale_SUCCESS = "GET_analisiCommerciale_SUCCESS";
export const GET_analisiCommerciale_FAILURE = "GET_analisiCommerciale_FAILURE";

export const getAnalisiCommerciale =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_analisiCommerciale_REQUEST,
          GET_analisiCommerciale_SUCCESS,
          GET_analisiCommerciale_FAILURE,
        ],

        endpoint: "/api/analisiCommerciale",
        method: "POST",
        body: body,
        abortController: abortController,
      },
    });
  };

export const GET_budget_REQUEST = "GET_budget_REQUEST";
export const GET_budget_SUCCESS = "GET_budget_SUCCESS";
export const GET_budget_FAILURE = "GET_budget_FAILURE";

export const getBudget =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [GET_budget_REQUEST, GET_budget_SUCCESS, GET_budget_FAILURE],

        endpoint: "/api/budget/" + id + "/get",
        method: "POST",
        body: body,
        abortController: abortController,
      },
    });
  };

export const CREATE_BUDGET_REQUEST = "CREATE_BUDGET_REQUEST";
export const CREATE_BUDGET_SUCCESS = "CREATE_BUDGET_SUCCESS";
export const CREATE_BUDGET_FAILURE = "CREATE_BUDGET_FAILURE";

export const createBudget =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          CREATE_BUDGET_REQUEST,
          CREATE_BUDGET_SUCCESS,
          CREATE_BUDGET_FAILURE,
        ],

        endpoint: "/api/budget",
        method: "POST",
        body: body,

        abortController: abortController,
      },
    });
  };

export const GET_BUDGETLIST_REQUEST = "GET_BUDGETLIST_REQUEST";
export const GET_BUDGETLIST_SUCCESS = "GET_BUDGETLIST_SUCCESS";
export const GET_BUDGETLIST_FAILURE = "GET_BUDGETLIST_FAILURE";

export const getBudgetList =
  (abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_BUDGETLIST_REQUEST,
          GET_BUDGETLIST_SUCCESS,
          GET_BUDGETLIST_FAILURE,
        ],
        endpoint: "/api/budget",
        method: "GET",
        abortController: abortController,
      },
    });
  };

export const EDIT_BUDGET_ENTRY_REQUEST = "EDIT_BUDGET_ENTRY_REQUEST";
export const EDIT_BUDGET_ENTRY_SUCCESS = "EDIT_BUDGET_ENTRY_SUCCESS";
export const EDIT_BUDGET_ENTRY_FAILURE = "EDIT_BUDGET_ENTRY_FAILURE";

export const editBudgetEntry =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          EDIT_BUDGET_ENTRY_REQUEST,
          EDIT_BUDGET_ENTRY_SUCCESS,
          EDIT_BUDGET_ENTRY_FAILURE,
        ],

        endpoint: "/api/budget/" + id + "/save_entry",

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const DELETE_BUDGET_ENTRY_REQUEST = "DELETE_BUDGET_ENTRY_REQUEST";
export const DELETE_BUDGET_ENTRY_SUCCESS = "DELETE_BUDGET_ENTRY_SUCCESS";
export const DELETE_BUDGET_ENTRY_FAILURE = "DELETE_BUDGET_ENTRY_FAILURE";

export const deleteBudgetEntry =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_BUDGET_ENTRY_REQUEST,
          DELETE_BUDGET_ENTRY_SUCCESS,
          DELETE_BUDGET_ENTRY_FAILURE,
        ],

        endpoint: "/api/budget/" + id + "/delete_entry",

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const EDIT_BUDGET_REQUEST = "EDIT_BUDGET_REQUEST";
export const EDIT_BUDGET_SUCCESS = "EDIT_BUDGET_SUCCESS";
export const EDIT_BUDGET_FAILURE = "EDIT_BUDGET_FAILURE";

export const editBudget =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [EDIT_BUDGET_REQUEST, EDIT_BUDGET_SUCCESS, EDIT_BUDGET_FAILURE],

        endpoint: "/api/budget/" + id,

        method: "PUT",
        body: body,

        abortController: abortController,
      },
    });
  };

export const DELETE_BUDGET_REQUEST = "DELETE_BUDGET_REQUEST";
export const DELETE_BUDGET_SUCCESS = "DELETE_BUDGET_SUCCESS";
export const DELETE_BUDGET_FAILURE = "DELETE_BUDGET_FAILURE";

export const deleteBudget =
  (id, body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          DELETE_BUDGET_REQUEST,
          DELETE_BUDGET_SUCCESS,
          DELETE_BUDGET_FAILURE,
        ],

        endpoint: "/api/budget/" + id,

        method: "DELETE",
        body: body,

        abortController: abortController,
      },
    });
  };

export const GET_dashboardCommerciale_getCardData_REQUEST =
  "GET_dashboardCommerciale_getCardData_REQUEST";
export const GET_dashboardCommerciale_getCardData_SUCCESS =
  "GET_dashboardCommerciale_getCardData_SUCCESS";
export const GET_dashboardCommerciale_getCardData_FAILURE =
  "GET_dashboardCommerciale_getCardData_FAILURE";

export const getDashboardCommerciale_getCardData =
  (body, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_analisiCommerciale_REQUEST,
          GET_analisiCommerciale_SUCCESS,
          GET_analisiCommerciale_FAILURE,
        ],

        endpoint: "/api/dashboardCommerciale/getCardData",

        method: "POST",
        body: body,
        abortController: abortController,
      },
    });
  };

export const addRuoloPermesso_REQUEST = "addRuoloPermesso_REQUEST";
export const addRuoloPermesso_SUCCESS = "addRuoloPermesso_SUCCESS";
export const addRuoloPermesso_FAILURE = "addRuoloPermesso_FAILURE";

export const addRuoloPermesso =
  (ruolo, permesso, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          addRuoloPermesso_REQUEST,
          addRuoloPermesso_SUCCESS,
          addRuoloPermesso_FAILURE,
        ],

        endpoint: "/api/ruoli_permessi",

        method: "PUT",
        body: { ruolo, permesso },
        abortController: abortController,
      },
    });
  };

export const removeRuoloPermesso_REQUEST = "removeRuoloPermesso_REQUEST";
export const removeRuoloPermesso_SUCCESS = "removeRuoloPermesso_SUCCESS";
export const removeRuoloPermesso_FAILURE = "removeRuoloPermesso_FAILURE";

export const removeRuoloPermesso =
  (ruolo, permesso, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          removeRuoloPermesso_REQUEST,
          removeRuoloPermesso_SUCCESS,
          removeRuoloPermesso_FAILURE,
        ],

        endpoint: "/api/ruoli_permessi/delete",

        method: "POST",
        body: { ruolo, permesso },
        abortController: abortController,
      },
    });
  };

export const getRuoliPermessi_REQUEST = "getRuoliPermessi_REQUEST";
export const getRuoliPermessi_SUCCESS = "getRuoliPermessi_SUCCESS";
export const getRuoliPermessi_FAILURE = "getRuoliPermessi_FAILURE";

export const getRuoliPermessi =
  (ruolo, permesso, abortController = null) =>
  (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [
          getRuoliPermessi_REQUEST,
          getRuoliPermessi_SUCCESS,
          getRuoliPermessi_FAILURE,
        ],

        endpoint: "/api/ruoli_permessi",

        method: "GET",
        abortController: abortController,
      },
    });
  };

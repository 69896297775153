import React from "react";
import { getAgevolazioniEVariabiliDaAttivita } from "../../utils/getAgevolazioniEVariabiliDaAttivita";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Card, CardContent } from "@material-ui/core";
import numeral from "numeral";
import NumberInput from "app/components/common/NumberInput";
import { orderBy } from "lodash";
import { VariabileImportoPrevistoId } from "../../../common/hardCodedIds";

export default function MandatoLineaPrevisioneBenefici({
  readOnly,
  mandatoLineaSource,
  procedure,
  statoMandato,
}) {
  const agevolazioniEVariabiliDaAttivita = getAgevolazioniEVariabiliDaAttivita(
    mandatoLineaSource.getValue("idLinea"),
    procedure
  );
  const variabiliMandatoLineaSource = mandatoLineaSource.getChildDataSource([
    "variabili",
  ]);
  const totalRows =
    agevolazioniEVariabiliDaAttivita.length +
    variabiliMandatoLineaSource.data.length;

  const isPluriBene = mandatoLineaSource.getValue(
    ["linea", "isMultibene"],
    false
  );

  const previsioneRows = [
    ...preparePrevisioniRowDaAttivita(agevolazioniEVariabiliDaAttivita),
    ...preparePrevisioniRowDaMandatoLinea(
      variabiliMandatoLineaSource,
      mandatoLineaSource,
      statoMandato,
      readOnly
    ),
  ];

  return (
    <Card variant="outlined" raised>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Agevolazione</TableCell>
              <TableCell>Importo</TableCell>
              <TableCell>Investimento</TableCell>
              <TableCell>Importo agevolazione</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderBy(previsioneRows, ["idVariabile"], ["asc"]).map(
              (x) => x.RenderedComponent
            )}

            {totalRows === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  Nessun importo per questa linea
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

type PrevisioneRow = {
  RenderedComponent: React.ReactNode;
  idVariabile: number;
};

function preparePrevisioniRowDaAttivita(
  agevolazioniEVariabiliDaAttivita
): PrevisioneRow[] {
  return agevolazioniEVariabiliDaAttivita.map((obj) => {
    const agevolazione = obj?.variabile?.agevolazione?.nome?.nome;
    const nomeBene = obj?.attivita?.bene?.nome;
    const variabile = obj?.variabile?.nome?.nome;
    const valore = obj?.valore;
    const valoreInvestimento = obj?.valoreInvestimento;

    const valoreString =
      typeof valore === "number" ? `${numeral(valore).format()} €` : "-";
    const valoreInvestimentoString =
      typeof valoreInvestimento === "number"
        ? `${numeral(valoreInvestimento).format()} €`
        : "-";

    return {
      RenderedComponent: (
        <TableRow>
          <TableCell>
            {agevolazione}
            {nomeBene && ` (${nomeBene})`}
          </TableCell>
          <TableCell>{variabile}</TableCell>
          <TableCell>{valoreInvestimentoString}</TableCell>
          <TableCell>{valoreString}</TableCell>
        </TableRow>
      ),
      idVariabile: obj?.variabile?.id,
    };
  });
}

function preparePrevisioniRowDaMandatoLinea(
  variabiliSource,
  mandatoLineaSource,
  statoMandato,
  readOnly
): PrevisioneRow[] {
  const min = mandatoLineaSource.getValue("minInvestimentoPrevisto");
  const max = mandatoLineaSource.getValue("maxInvestimentoPrevisto");
  const hasMin = min !== null;
  const hasMax = max !== null;
  const variant = readOnly ? "standard" : "outlined";

  return variabiliSource.data.map((variabileAttivita) => {
    const variabileAttivitaSource = variabiliSource.getChildDataSource([
      { id: variabileAttivita.id },
    ]);

    const isPrevisto =
      variabileAttivita.variabile.idNomeVariabile ===
      VariabileImportoPrevistoId;
    const showMinMax = isPrevisto;
    const canUpdate = statoMandato === "offerta" || isPrevisto;

    return {
      RenderedComponent: (
        <TableRow>
          <TableCell>
            {variabileAttivita.variabile?.agevolazione?.nome?.nome}
          </TableCell>
          <TableCell>{variabileAttivita.variabile?.nome?.nome}</TableCell>
          <TableCell>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <NumberInput
                fullWidth
                variant={variant}
                currency
                source={variabileAttivitaSource}
                sourceKey="valoreInvestimento"
                disabled={readOnly || !canUpdate}
              />

              {showMinMax && (
                <div>
                  {hasMin && (
                    <span>Min: {numeral(min).format("0,0.[00]")} €</span>
                  )}
                  {hasMin && hasMax && <span> - </span>}
                  {hasMax && (
                    <span>Max: {numeral(max).format("0,0.[00]")} €</span>
                  )}
                </div>
              )}
            </div>
          </TableCell>
          <TableCell style={{ verticalAlign: "top" }}>
            <NumberInput
              fullWidth
              variant={variant}
              currency
              source={variabileAttivitaSource}
              sourceKey="valore"
              disabled={readOnly || !canUpdate}
            />
          </TableCell>
        </TableRow>
      ),
      idVariabile: variabileAttivita.variabile?.id,
    };
  });
}

import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  addRuoloPermesso as addRuoloPermessoAction,
  getRuoliPermessi as getRuoliPermessiAction,
  removeRuoloPermesso as removeRuoloPermessoAction,
} from "app/actions";
import { useApi } from "app/hooks/useApi";
import {
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { permessiByGruppo, gruppiPermessi, permessi } from "./permessi";
import HelpIcon from "@material-ui/icons/Help";
import TextFilter from "../../elements/TableFilter/TextFilter";

export default function RuoliPermessiTable(props) {
  const _ruoliPermessi = useDataSource({
    loadAction: getRuoliPermessiAction,
    initialData: [],
  });

  const [firstLoading, setFirstLoading] = useState(true);

  const [_filter, setFilter] = useState("");
  const filter = useMemo(() => _filter.toLowerCase(), [_filter]);

  const [ruoliPermessi, setRuoliPermessi] = useState({});

  useEffect(() => {
    if (_ruoliPermessi.data) {
      setRuoliPermessi(_ruoliPermessi.data);
    }
  }, [_ruoliPermessi.data]);

  const [changed, setChanged] = useState(false);

  const onError = () => {};

  const onSuccess = (data) => {
    setRuoliPermessi(data);
    setChanged(true);
  };

  const [ruoli, ruoloHasPermesso] = useMemo(() => {
    const data = ruoliPermessi;
    const ruoli = Object.keys(data);
    const ruoloHasPermesso = {};
    ruoli.forEach((ruolo) => {
      ruoloHasPermesso[ruolo] = {};
      for (let index = 0; index < data[ruolo].length; index++) {
        const permesso = data[ruolo][index];

        ruoloHasPermesso[ruolo][permesso] = true;
      }
    });

    ruoli.sort();
    if (ruoli.length > 0) {
      setFirstLoading(false);
    }
    return [ruoli, ruoloHasPermesso];
  }, [ruoliPermessi]);

  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth="xl"
    >
      {_ruoliPermessi.loading && !ruoliPermessi ? (
        <>Caricamento...</>
      ) : (
        <>
          <Card>
            <CardContent>
              <div style={{ marginBottom: 20 }}>
                <Alert color="info">
                  <AlertTitle>
                    Tutti gli utenti devono ricaricare la pagina per vedere i
                    cambiamenti
                  </AlertTitle>
                </Alert>
              </div>

              {firstLoading ? (
                "Caricamento in corso ..."
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 200 }}>Permesso</TableCell>
                      {ruoli.map((ruolo) => {
                        return (
                          <TableCell
                            key={ruolo}
                            style={{ width: 120, textAlign: "center" }}
                          >
                            {ruolo}
                          </TableCell>
                        );
                      })}
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <TextFilter
                          filterOptions={{
                            filterText: _filter,
                            type: "text",
                            hideNot: true,
                          }}
                          messages={{ textSearch: "Filtra" }}
                          setFilter={(f) => setFilter(f?.filterText ?? "")}
                        />
                      </TableCell>
                      <TableCell colSpan={ruoli.length} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gruppiPermessi.map((gruppo) => {
                      const filterMatchesGruppo =
                        gruppo.toLowerCase().indexOf(filter) >= 0;
                      const permessi = permessiByGruppo[gruppo] ?? [];
                      const filteredPermessi = filterMatchesGruppo
                        ? permessi
                        : permessi.filter((p) => {
                            const text = p.label + (p.help ?? "");
                            return text.toLowerCase().indexOf(filter) >= 0;
                          });
                      if (filteredPermessi.length === 0) {
                        return <Fragment key={gruppo}></Fragment>;
                      }

                      return (
                        <Fragment key={gruppo}>
                          <TableRow>
                            <TableCell>
                              <strong>{gruppo}</strong>
                            </TableCell>

                            <TableCell colSpan={ruoli.length} />
                          </TableRow>

                          {filteredPermessi.map((permesso) => (
                            <TableRow key={permesso.name}>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {permesso.label}
                                  {permesso.help ? (
                                    <Tooltip title={permesso.help}>
                                      <HelpIcon
                                        fontSize="small"
                                        style={{ color: "gray", marginLeft: 4 }}
                                      />
                                    </Tooltip>
                                  ) : null}
                                </div>
                              </TableCell>
                              {ruoli.map((ruolo) => {
                                return (
                                  <RuoloPermessoCell
                                    permesso={permesso.name}
                                    ruolo={ruolo}
                                    onError={onError}
                                    onSuccess={onSuccess}
                                    key={ruolo + " - " + permesso.name}
                                    checked={
                                      ruoloHasPermesso[ruolo][permesso.name] ??
                                      false
                                    }
                                  />
                                );
                              })}
                            </TableRow>
                          ))}
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </CardContent>
          </Card>
        </>
      )}
    </Container>
  );
}

function RuoloPermessoCell({ ruolo, permesso, checked, onError, onSuccess }) {
  const showToast = useToast();

  const removeRuoloPermessoApi = useApi(removeRuoloPermessoAction, {
    onError: () => {
      showToast("Errore durante il cambio di permessi", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
      onError();
    },
    onSuccess: (r) => {
      showToast("Cambio permessi salvato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
      onSuccess(r.data);
    },
  });

  const addRuoloPermessoApi = useApi(addRuoloPermessoAction, {
    onError: () => {
      showToast("Errore durante il cambio di permessi", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
      onError();
    },
    onSuccess: (r) => {
      showToast("Cambio permessi salvato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
      onSuccess(r.data);
    },
  });

  const loading = removeRuoloPermessoApi.loading || addRuoloPermessoApi.loading;

  return (
    <TableCell style={{ textAlign: "center" }}>
      <Checkbox
        checked={checked}
        disabled={
          loading || (ruolo === "Admin" && permesso === "gestioneRuoliPermessi")
        }
        onChange={(e) => {
          const checked = e.target.checked;
          if (checked) {
            addRuoloPermessoApi.callApi(ruolo, permesso);
          } else {
            removeRuoloPermessoApi.callApi(ruolo, permesso);
          }
        }}
        size="small"
      />
      {loading && <CircularProgress size="small" />}
    </TableCell>
  );
}

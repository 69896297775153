import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Typography,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditorDizionari from "app/components/ImpostazioniDizionari/EditorDizionario";
import {
  getRegimi as getRegimiAction,
  createRegime as createRegimeAction,
  editRegime as editRegimeAction,
  deleteRegime as deleteRegimeAction,
  getTipologieProcedura as getTipologieProceduraAction,
  createTipologiaProcedura as createTipologiaProceduraAction,
  editTipologiaProcedura as editTipologiaProceduraAction,
  deleteTipologiaProcedura as deleteTipologiaProceduraAction,
  getEnti as getEntiAction,
  createEnte as createEnteAction,
  editEnte as editEnteAction,
  deleteEnte as deleteEnteAction,
  getAmbitiDiApplicazione as getAmbitiDiApplicazioneAction,
  createAmbitoDiApplicazione as createAmbitoDiApplicazioneAction,
  editAmbitoDiApplicazione as editAmbitoDiApplicazioneAction,
  deleteAmbitoDiApplicazione as deleteAmbitoDiApplicazioneAction,
  getNomiAgevolazione as getNomiAgevolazioneAction,
  createNomeAgevolazione as createNomeAgevolazioneAction,
  editNomeAgevolazione as editNomeAgevolazioneAction,
  deleteNomeAgevolazione as deleteNomeAgevolazioneAction,
  getNomiVariabile as getNomiVariabileAction,
  createNomeVariabile as createNomeVariabileAction,
  editNomeVariabile as editNomeVariabileAction,
  deleteNomeVariabile as deleteNomeVariabileAction,
  getNomiMilestone as getNomiMilestoneAction,
  createNomeMilestone as createNomeMilestoneAction,
  editNomeMilestone as editNomeMilestoneAction,
  deleteNomeMilestone as deleteNomeMilestoneAction,
  getNomiDocumento as getNomiDocumentoAction,
  createNomeDocumento as createNomeDocumentoAction,
  editNomeDocumento as editNomeDocumentoAction,
  deleteNomeDocumento as deleteNomeDocumentoAction,
  getTipiPagamento as getTipiPagamentoAction,
  createTipoPagamento as createTipoPagamentoAction,
  editTipoPagamento as editTipoPagamentoAction,
  deleteTipoPagamento as deleteTipoPagamentoAction,
} from "app/actions";
import { VariabileImportoPrevistoId } from "../../../common/hardCodedIds";

export default function ImpostazioniDizionari(props) {
  const makeGroup = (label, content) => {
    return (
      <Accordion defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {label}
        </AccordionSummary>

        <AccordionDetails>
          <div
            style={{
              width: "100%",
            }}
          >
            {content}{" "}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Container>
      <Typography component="h2" gutterBottom={true} variant="h6">
        Impostazioni dizionari
      </Typography>
      {makeGroup("Leggi e linee", [
        <EditorDizionari
          title="Regime"
          loadAction={getRegimiAction}
          createAction={createRegimeAction}
          editAction={editRegimeAction}
          deleteAction={deleteRegimeAction}
        />,
        <EditorDizionari
          title="Tipologia procedura"
          loadAction={getTipologieProceduraAction}
          createAction={createTipologiaProceduraAction}
          editAction={editTipologiaProceduraAction}
          deleteAction={deleteTipologiaProceduraAction}
        />,
        <EditorDizionari
          title="Enti"
          loadAction={getEntiAction}
          createAction={createEnteAction}
          editAction={editEnteAction}
          deleteAction={deleteEnteAction}
        />,
        <EditorDizionari
          title="Ambito di applicazione"
          loadAction={getAmbitiDiApplicazioneAction}
          createAction={createAmbitoDiApplicazioneAction}
          editAction={editAmbitoDiApplicazioneAction}
          deleteAction={deleteAmbitoDiApplicazioneAction}
          canEdit={true}
          customColumns={[
            {
              path: ["codiceSottoconto"],
              header: "Codice Sottoconto",
              sortable: false,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <TextField
                    style={{ width: 100 }}
                    className={
                      rowSource.getValue("attivo") ? "" : "crea-lineThrough"
                    }
                    disabled={rowSource.loading || rowSource.editing}
                    error={
                      rowSource.getValidationErrors("codiceSottoconto").length >
                      0
                    }
                    fullWidth={true}
                    helperText={
                      rowSource.getValidationErrors("codiceSottoconto")[0]
                    }
                    onBlur={(event) => {
                      rowSource.runValidation("codiceSottoconto");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      rowSource.changeValue("codiceSottoconto", value);
                    }}
                    size="small"
                    type="text"
                    value={rowSource.getValue("codiceSottoconto", "")}
                    variant={
                      rowSource.getValue("_isActive") ? "outlined" : "standard"
                    }
                    InputProps={{
                      readOnly:
                        !rowSource.getValue("_isActive") &&
                        !rowSource.getValue("__isNew"),
                    }}
                  />
                );
              },
            },
          ]}
        />,
        <EditorDizionari
          title="Nomi agevolazioni"
          loadAction={getNomiAgevolazioneAction}
          createAction={createNomeAgevolazioneAction}
          editAction={editNomeAgevolazioneAction}
          deleteAction={deleteNomeAgevolazioneAction}
        />,
        <EditorDizionari
          title="Nomi importi"
          loadAction={getNomiVariabileAction}
          createAction={createNomeVariabileAction}
          editAction={editNomeVariabileAction}
          deleteAction={deleteNomeVariabileAction}
          canOrder={true}
          filterCanBeDeleted={(rowSource) =>
            rowSource.getValue("id") !== VariabileImportoPrevistoId
          }
        />,
        <EditorDizionari
          title="Nomi milestone"
          loadAction={getNomiMilestoneAction}
          createAction={createNomeMilestoneAction}
          editAction={editNomeMilestoneAction}
          deleteAction={deleteNomeMilestoneAction}
          canOrder={true}
          canEdit={true}
          customColumns={[
            {
              path: ["codiceSottoconto"],
              header: "Codice Sottoconto",
              sortable: false,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <TextField
                    style={{ width: 100 }}
                    className={
                      rowSource.getValue("attivo") ? "" : "crea-lineThrough"
                    }
                    disabled={rowSource.loading || rowSource.editing}
                    error={
                      rowSource.getValidationErrors("codiceSottoconto").length >
                      0
                    }
                    fullWidth={true}
                    helperText={
                      rowSource.getValidationErrors("codiceSottoconto")[0]
                    }
                    onBlur={(event) => {
                      rowSource.runValidation("codiceSottoconto");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      rowSource.changeValue("codiceSottoconto", value);
                    }}
                    size="small"
                    type="text"
                    value={rowSource.getValue("codiceSottoconto", "")}
                    variant={
                      rowSource.getValue("_isActive") ? "outlined" : "standard"
                    }
                    InputProps={{
                      readOnly:
                        !rowSource.getValue("_isActive") &&
                        !rowSource.getValue("__isNew"),
                    }}
                  />
                );
              },
            },
          ]}
        />,
      ])}{" "}
      {makeGroup("Aziende e Mandati", [
        <EditorDizionari
          title="Nomi documenti (aziende)"
          loadAction={getNomiDocumentoAction}
          createAction={createNomeDocumentoAction}
          editAction={editNomeDocumentoAction}
          deleteAction={deleteNomeDocumentoAction}
          canOrder={true}
        />,
        <EditorDizionari
          title="Tipi di pagamento"
          loadAction={getTipiPagamentoAction}
          createAction={createTipoPagamentoAction}
          editAction={editTipoPagamentoAction}
          deleteAction={deleteTipoPagamentoAction}
          canOrder={true}
        />,
      ])}{" "}
    </Container>
  );
}

import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { AsyncSelect } from "app/elements/AsyncSelect";

export default function DizionarioSelect(props) {
  const dizionario = useDataSource({
    initialData: [],
    loadAction: props.loadDizionarioAction,
    autoLoad: true,
  });

  const currentValue = dizionario.loadSuccess
    ? props.source.getValue(props.sourceKey, "") || ""
    : "";

  const options = dizionario.loadSuccess
    ? dizionario.data.filter((option) => {
        return option.attivo || option.id === currentValue;
      })
    : [];

  const sourceError =
    props.source.getValidationErrors(props.sourceKey)[0] || null;
  return (
    <FormControl
      className={
        dizionario.getValue([
          {
            id: currentValue,
          },
          "attivo",
        ])
          ? ""
          : "crea-lineThrough"
      }
      disabled={props.readOnly}
      error={!!sourceError}
      fullWidth={true}
      margin="none"
      size="small"
    >
      <InputLabel error={!!sourceError} variant="outlined">
        {props.label}
      </InputLabel>

      <AsyncSelect
        autoWidth={true}
        label={props.label}
        native={false}
        onChange={(event) => {
          const value = event.target.value;

          if (props.onChange) {
            props.onChange(value, dizionario);
          } else {
            props.source.changeValue(props.sourceKey, value || null);
          }
        }}
        value={currentValue}
        variant="outlined"
        disabled={props.readOnly}
      >
        {(
          typeof props.showNullOption !== "undefined"
            ? props.showNullOption
            : true
        ) ? (
          <MenuItem value="">
            <em></em>
          </MenuItem>
        ) : null}
        {options.map((option, index) => {
          return (
            <MenuItem
              key={option.id}
              dense={false}
              disableGutters={false}
              value={option.id}
            >
              {option.nome}
            </MenuItem>
          );
        })}
      </AsyncSelect>
    </FormControl>
  );
}

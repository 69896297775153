import { useEffect, useMemo, useState } from "react";
import { useToast } from "app/hooks/useToast";
import {
  getAttivitaForKanbanAll as getAttivitaForKanbanAllAction,
  getAttivitaForKanbanUser as getAttivitaForKanbanUserAction,
  getAttivitaForKanbanMe as getAttivitaForKanbanMeAction,
  saveAttivitaDataPrevista as saveAttivitaDataPrevistaAction,
} from "app/actions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import useSideModal from "app/components/common/useSideModal";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";
import { Kanban } from "../Kanban/Kanban";
import moment from "moment";
import AttivitaKanbanCard, {
  getDataInizioLavoroConsiderandoOrePreviste,
} from "./AttivitaKanbanCard";
import Attivita from "app/components/Attivita/Attivita";
import { useApi } from "app/hooks/useApi";
import UserFilterSelector from "../common/UserFilterSelector";
import { useUser } from "app/hooks/useUser";
import ProceduraFilterSelector from "../common/ProceduraFilterSelector";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { v4 as uuid } from "uuid";
import { useStateWithSaveCurrentState } from "app/hooks/useStateWithSaveCurrentState";

export const weeklyAggregation = {
  getColumnTitle: (columnKey) => {
    if (!columnKey) {
      return "Attività da scadenzare";
    }
    const date = moment(columnKey);
    return `Settimana ${date.isoWeek()}: ${date.format("DD/MM/YYYY")}`;
  },
  getCardAggrKey: (cardData) => {
    if (!cardData.dataPrevista) {
      return null;
    }
    return moment(cardData.dataPrevista).startOf("isoWeek").toISOString();
  },
  sortCardsBy: [["dataPrevista", "asc"]],
};

export const dailyAggregation = {
  getColumnTitle: (columnKey) => {
    if (!columnKey) {
      return "Attività da scadenzare";
    }
    const date = moment(columnKey);
    return `${date.format("dddd DD/MM/YYYY")}`;
  },
  getCardAggrKey: (cardData) => {
    if (!cardData.dataPrevista) {
      return null;
    }
    return moment(cardData.dataPrevista).startOf("day").toISOString();
  },
  sortCardsBy: [["dataPrevista", "asc"]],
};

export default function AttivitaKanban() {
  const { openSideModal, sideModal, sideModalInfo } = useSideModal();

  // save for history change!
  const [userFilter, setUserFilter] = useStateWithSaveCurrentState(
    null,
    "ak_user",
    "all_object"
  );
  const [proceduraFilter, setProceduraFilter] = useStateWithSaveCurrentState(
    null,
    "ak_procedura",
    "all_object"
  );
  const [isDaily, showDaily] = useStateWithSaveCurrentState(
    null,
    "ak_daily",
    "all_object"
  );
  const [showAttivitaCompletate, setShowAttivitaCompletate] =
    useStateWithSaveCurrentState(
      false,
      "ak_showAttivitaCompletate",
      "all_object"
    );

  const me = useUser();
  const hasPermission = useCheckPermission();

  const selectUser = (userId) => setUserFilter(userId);
  const selectProcedura = (proceduraId) => setProceduraFilter(proceduraId);

  const [forceStart, forceEnd] = useMemo(() => [
    moment().startOf("isoWeek").toISOString(),
    moment().startOf("isoWeek").add(14, "d").format("YYYY-MM-DD"),
  ]);

  const attivitaSource = useDataSource({
    initialData: [],
    loadAction: () => {
      let action;
      if (!userFilter) {
        action = getAttivitaForKanbanAllAction();
      } else if (me.id === userFilter) {
        action = getAttivitaForKanbanMeAction();
      } else {
        action = getAttivitaForKanbanUserAction(userFilter);
      }

      return async function actionWithFilteredResult(dispatch, getState) {
        const result = await action(dispatch, getState);

        if (result?.response?.data && Array.isArray(result?.response?.data)) {
          result.response.data = result.response.data.filter((attivita) => {
            if (proceduraFilter && attivita.idProcedura !== proceduraFilter) {
              return false;
            }
            if (!showAttivitaCompletate && attivita.stato === "completato") {
              return false;
            }
            if (attivita.stato === "annullato") {
              return false;
            }
            return true;
          });
        }
        return result;
      };
    },
  });

  const canEdit = hasPermission(["modifica_attivita"]);

  if (!hasPermission(["visualizza_attivita"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }

  return (
    <Container disableGutters fixed={false} maxWidth={false}>
      {sideModal}

      <div style={{ display: "flex" }}>
        <Typography
          style={{
            flex: 1,
            marginLeft: 30,
          }}
          component="h2"
          gutterBottom
          variant="h5"
        >
          Kanban Attività
        </Typography>
        <AttivitaFilters
          proceduraFilter={proceduraFilter}
          selectProcedura={selectProcedura}
          userFilter={userFilter}
          selectUser={selectUser}
          isDaily={isDaily}
          showDaily={showDaily}
          showAttivitaCompletate={showAttivitaCompletate}
          setShowAttivitaCompletate={setShowAttivitaCompletate}
        />
      </div>

      <AttivitaKanbanInternal
        disabled={!canEdit}
        attivitaSource={attivitaSource}
        userFilter={userFilter}
        proceduraFilter={proceduraFilter}
        isDaily={isDaily}
        showAttivitaCompletate={showAttivitaCompletate}
        openSideModal={openSideModal}
        sideModalInfo={sideModalInfo}
        showSenzaData={true}
        forceStart={forceStart}
        forceEnd={forceEnd}
        preventDragOnSenzaData={true}
        filterCardsWithoutKeys={(card) => {
          // fai vedere solo quelli che hanno una data di scadenza (perentoria o di progetto) impostata
          const attivita = card.data;
          if (
            !attivita.dataScadenzaPerentoria &&
            !attivita.dataScadenzaProgetto
          ) {
            return false;
          }
          return true;
        }}
        shouldSkipFirstPastColumnsWithAllAttivitaCompletato
        scrollToCurrentWeek
      />
    </Container>
  );
}

function AttivitaFilters({
  proceduraFilter,
  selectProcedura,
  userFilter,
  selectUser,
  isDaily,
  showDaily,
  showAttivitaCompletate,
  setShowAttivitaCompletate,
}) {
  const isWeekly = !isDaily;
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
      <ProceduraFilterSelector
        returnValue="id"
        defaultSelection="all"
        onChange={selectProcedura}
        label="Procedura"
        value={proceduraFilter}
      />

      <UserFilterSelector
        returnValue="id"
        defaultSelection="all"
        onChange={selectUser}
        label="Operatore"
        value={userFilter}
        userRole="Back"
      />

      <ButtonGroup color="primary" size="small" disableElevation>
        <Button
          onClick={() => showDaily(false)}
          variant={isWeekly ? "contained" : "outlined"}
        >
          Per settimana
        </Button>
        <Button
          onClick={() => showDaily(true)}
          variant={isDaily ? "contained" : "outlined"}
        >
          Per giorno
        </Button>
      </ButtonGroup>

      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: -16 }}>
          <Typography
            style={{ color: "rgba(0, 0, 0, 0.54)" }}
            variant="caption"
          >
            Attività completate
          </Typography>
        </div>
        <ButtonGroup color="primary" size="small" disableElevation>
          <Button
            variant={showAttivitaCompletate ? "contained" : "outlined"}
            onClick={() => setShowAttivitaCompletate(true)}
          >
            Mostra
          </Button>
          <Button
            variant={showAttivitaCompletate ? "outlined" : "contained"}
            onClick={() => setShowAttivitaCompletate(false)}
          >
            Nascondi
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

export function AttivitaKanbanInternal({
  disabled,
  attivitaSource,
  userFilter,
  proceduraFilter,
  isDaily,
  openSideModal,
  sideModalInfo,
  showSenzaData = true,
  preventDragOnSenzaData,
  dataFilter = null,
  forceStart = null,
  forceEnd = null,
  filterCardsWithoutKeys,
  showAttivitaCompletate,
  shouldSkipFirstPastColumnsWithAllAttivitaCompletato = false,
  scrollToCurrentWeek = false,
  forceColumns = null,
}) {
  const showToast = useToast();

  const isWeekly = !isDaily;

  const [{ firstLoad, loading, loadError }, setAttivita] = useState({
    firstLoad: true,
    // attivita: null,
    loading: true,
    loadError: null,
  });

  useEffect(() => {
    if (!firstLoad) {
      setAttivita({
        // attivita: null,
        loading: true,
        loadError: null,
      });
      attivitaSource.load();
    }
  }, [userFilter, proceduraFilter, showAttivitaCompletate]);

  const attivita = attivitaSource.data;

  const data = useMemo(() => {
    let data = attivita;
    if (dataFilter) {
      data = data.filter(dataFilter);
    }
    return data;
  }, [attivita, dataFilter]);

  useEffect(() => {
    if (!attivitaSource.loading && loading) {
      setAttivita({
        // attivita: null,
        loading: false,
        loadError: attivitaSource.loadError,
      });
    }
  }, [attivitaSource.loading, attivitaSource.loadError]);

  const [saveAttivitaLoading, setSaveAttivitaLoading] = useState(false);
  const saveAttivitaApi = useApi(saveAttivitaDataPrevistaAction, {
    onError: () => {
      showToast("Errore durante lo spostamento dell'attività", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const saveAttivita = async (idAttivita, newDataPrevista) => {
    try {
      setSaveAttivitaLoading(true);
      await saveAttivitaApi.callApi(idAttivita, {
        dataPrevista: newDataPrevista,
      });
      attivitaSource.load();
      if (sideModalInfo) {
        // change key of modal to refresh content
        openSideModal(sideModalInfo.Component, sideModalInfo.props, uuid());
      }
    } finally {
      setSaveAttivitaLoading(false);
    }
  };

  let content;

  const CardComponent = useMemo(() => {
    const onCardClick = (cardData) => {
      openSideModal(
        Attivita,
        {
          id: cardData.id,
          useIdFromProps: true,
          onEditSuccess: () => {
            attivitaSource.load();
          },
          edit: true,
        },
        uuid()
      );
    };

    return (props) => {
      return <AttivitaKanbanCard {...props} openAttivita={onCardClick} />;
    };
  }, [openSideModal]);

  const onCardDragEnd = (
    cardData,
    { fromColumnId, fromPosition, toColumnId, toPosition, columnCards }
  ) => {
    let newDate;
    if (toPosition > 0) {
      newDate = columnCards[toPosition - 1].dataPrevista;
    } else {
      newDate = moment(toColumnId).format("YYYY-MM-DD");
    }

    // attivitaSource.changeValue([{ id: cardData.id }, "dataPrevista"], newDate);

    let shouldAsk = false;
    const dataScadenzaProgetto = cardData.dataScadenzaProgetto;
    const dataScadenzaPerentoria = cardData.dataScadenzaPerentoria;
    const d = dataScadenzaProgetto || dataScadenzaPerentoria;
    if (d) {
      const shouldStartBefore = getDataInizioLavoroConsiderandoOrePreviste(
        d,
        cardData.orePreviste
      );
      if (shouldStartBefore.isBefore(newDate)) {
        shouldAsk = true;
      }
    }

    const _save = () => {
      attivitaSource.changeValue([{ id: cardData.id }], {
        ...cardData,
        dataPrevista: newDate,
        saving: true,
      });
      saveAttivita(cardData.id, newDate);
    };

    let shouldSave;
    if (shouldAsk) {
      shouldSave = window.confirm(
        "Stai inserendo una data prevista che non permetterà di completare l'attività in tempo utile.\nSei sicuro di voler continuare?"
      );
    } else {
      shouldSave = true;
    }

    console.log(
      "onCardDragEnd",
      cardData,
      {
        fromColumnId,
        fromPosition,
        toColumnId,
        toPosition,
        columnCards,
      },
      "newDate: ",
      newDate,
      "shouldSave: ",
      shouldSave
    );

    if (shouldSave) {
      _save();
    } else {
      // reset
      return false;
    }
  };

  if (loading) {
    content = "Caricamento attività...";
  } else if (loadError) {
    content = "Errore durante il caricamento delle attività";
  } else {
    const aggr = isWeekly ? weeklyAggregation : dailyAggregation;
    content = (
      <div style={{ position: "relative" }}>
        {(attivitaSource.loading || saveAttivitaLoading) && (
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
              background: "#ededed54",
              display: "flex",
              zIndex: 10000,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        )}
        <Kanban
          disabled={disabled || attivitaSource.loading || saveAttivitaLoading}
          style={{ height: "calc(100vh - 150px)" }}
          data={data}
          groupByDay={isDaily}
          getColumnTitle={aggr.getColumnTitle}
          getCardAggrKey={aggr.getCardAggrKey}
          sortCardsBy={aggr.sortCardsBy}
          CardComponent={CardComponent}
          onCardDragEnd={onCardDragEnd}
          showSenzaData={showSenzaData}
          preventDragOnSenzaData={preventDragOnSenzaData}
          forceStart={forceStart}
          forceEnd={forceEnd}
          filterCardsWithoutKeys={filterCardsWithoutKeys}
          shouldSkipFirstPastColumnsWithAllAttivitaCompletato={
            shouldSkipFirstPastColumnsWithAllAttivitaCompletato
          }
          scrollToCurrentWeek={scrollToCurrentWeek}
          forceColumns={forceColumns}
        />
      </div>
    );
  }

  return content;
}
